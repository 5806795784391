import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font } from "@react-pdf/renderer";
import useTranslation from './customHooks/translations';
import { getCharacterLimitations } from "../utils/characterUtils";

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    charRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 9,
        margin: 2,
        padding: 2,
    },
    charRowTitle: {
        display: "flex",
        flexDirection: "row",
        fontSize: 11,
        margin: 2,
        padding: 2,
    },
    charCell: {
        width: "150px",
        paddingHorizontal: 4,
    }
});

// Create Document Component
const BasicDocument = (data) => {
    var points = data.char.character_points.map(function (point, i) {
        return (
            <View key={i} style={styles.charRow}>
                <Text style={styles.charCell}>{point.remaining}/{point.total} {point.point_name} {data.translation.points_total} ({point.point_alias})</Text>
            </View>
        );
    });

    var stats = data.char.character_stats.filter(stat => stat.total > 0).map(function (stat, i) {
        return (
            <View key={i} style={styles.charRow}>
                <Text style={styles.charCell}>{stat.stat_name}: {stat.total} {stat.stat_alias}</Text>
            </View>
        );
    });

    var limits = getCharacterLimitations(data.feats);
    var displayLimits;
    if (limits.length >= 1) {
        displayLimits = <>
            <View style={styles.charRowTitle}>
                <Text>{data.translation.feat_limitations}</Text>
            </View>
            <View style={styles.charRow}>
                <Text>{limits}</Text>
            </View>
        </>
    }

    var categories = data.char.character_categories.filter(x => !x.isHidden).map(function (category, i) {
        return (
            <>
                <View style={styles.charRowTitle}>
                    <Text>{category.category_name}: {category.sub_category}</Text>
                </View>
                {data.feats.filter(x => x.category_id === category.category_id)
                    .sort((a, b) => (a.cost_amount > b.cost_amount) ? 1 : ((b.cost_amount > a.cost_amount) ? -1 : 0))
                    .map((confirmedfeat) =>
                        <>
                            <View style={styles.charRow}>
                                <Text>{confirmedfeat.name}</Text>
                            </View>
                            <View style={styles.charRow}>
                                <Text>{confirmedfeat.description}</Text>
                            </View>
                        </>
                    )}
            </>
        );
    });

    var displayDmNotes;
    if (data.isDm) {
        displayDmNotes = <>
            <View style={styles.charRowTitle}>
                <Text>{data.translation.my_character_label_gm_notes}</Text>
            </View>
            <View style={styles.charRow}>
                <Text>{data.char.character.gm_notes}</Text>
            </View>
        </>
    }


    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.header}>
                    <Text> ~ https://larponline.ca ~ </Text>
                </View>
                <View style={styles.title}>
                    <Text>{data.char.character.name}</Text>
                </View>
                <View style={styles.charRowTitle}>
                    <Text>{data.translation.points_title}</Text>
                </View>
                {points}
                <View style={styles.charRowTitle}>
                    <Text>{data.translation.stat_title}</Text>
                </View>
                {stats}
                {displayLimits}
                {categories}
                <View style={styles.charRowTitle}>
                    <Text>{data.translation.my_character_label_backstory}</Text>
                </View>
                <View style={styles.charRow}>
                    <Text>{data.char.character.backstory}</Text>
                </View>
                <View style={styles.charRowTitle}>
                    <Text>{data.translation.my_character_label_notes}</Text>
                </View>
                <View style={styles.charRow}>
                    <Text>{data.char.character.notes}</Text>
                </View>
                {displayDmNotes}
            </Page>
        </Document>
    );
}

const ExportCharacter = ({ char, feats, isDm }) => {
    const translation = useTranslation()

    return (
        <div>
            <PDFDownloadLink className="btn btn-dark"
                document={<BasicDocument char={char} feats={feats} isDm={isDm} translation={translation} />}
                fileName="export-Player.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : translation.char_table_export_button)}
            </PDFDownloadLink>
        </div>)
};

export default ExportCharacter;
