import config from "../config"
import updatedHeaders from "./headers"

export async function fetchFeats(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_feats/' + larp_id, {
        method: "POST",
        headers: updatedHeaders()
    })
    return response
}

export async function AddFeat(larp_id, name, description, requirement, limitations, maxSelect, isHidden, triggersAnAction, statMods, pointMods, costs, allowNewSelection, categorySelection) {
    let featData = {
        "name": name,
        "description": description,
        "limitations": limitations,
        "requirements": requirement,
        "max_select": maxSelect,
        "isHidden": isHidden,
        "triggersAnAction": triggersAnAction,
        "statMods": statMods,
        "pointMods": pointMods,
        "allowNewSelection": allowNewSelection,
        "categorySelection": categorySelection,
        "costs": costs,
    }
    const response = await fetch(config.apiUrl + '/add_larp_feat/' + larp_id, {
        method: "POST",
        headers: updatedHeaders(),
        body: JSON.stringify(featData)
    })
    return response
}

export async function RemoveFeat(feat_id) {
    const response = await fetch(config.apiUrl + '/remove_larp_feat/' + feat_id, {
        method: "DELETE",
        headers: updatedHeaders(),
    })
    return response
}

export async function EditFeat(feat_id, name, description, requirement, limitations, maxSelect, isHidden, triggersAnAction, statMods, pointMods, costs, allowNewSelection, categorySelection) {
    let featData = {
        "name": name,
        "description": description,
        "requirements": requirement,
        "limitations": limitations,
        "max_select": maxSelect,
        "isHidden": isHidden,
        "triggersAnAction": triggersAnAction,
        "statMods": statMods,
        "pointMods": pointMods,
        "costs": costs,
        "allowNewSelection": allowNewSelection,
        "categorySelection": categorySelection,
    }
    const response = await fetch(config.apiUrl + '/update_larp_feat/' + feat_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(featData)
    })
    return response
}

export async function ObtainFeat(character_id, feat, category_id, originalFeatId, newFeatCategory) {
    let featData = {
        "feat": feat,
        "category_id": category_id,
        "original_feat_id": originalFeatId,
        "new_feat_category_id": newFeatCategory
    }
    const response = await fetch(config.apiUrl + '/obtain_new_feat/' + character_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(featData)
    })
    return response
}
