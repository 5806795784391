import { useEffect, useState } from "react";
import useTranslation from "./customHooks/translations";
import { BsFillPlusCircleFill, BsXCircleFill } from "react-icons/bs";

export default function FeatForm({ larp, feat, confirmButtonText, onConfirm }) {
    const translation = useTranslation();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [requirements, setRequirements] = useState("");
    const [limitations, setLimitations] = useState("");
    const [maxSelect, setMaxSelected] = useState(1);
    const [maxSelectDisabled, setMaxSelectDisabled] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [trigersAnAction, setTrigersAnAction] = useState(false);
    const [allowNewSelection, setAllowNewSelection] = useState(false);
    const [categorySelection, setCategorySelection] = useState("");
    const [statMods, setStatMods] = useState([]);
    const [pointMods, setPointMods] = useState([]);
    const [costs, setCosts] = useState([]);

    useEffect(() => {
        if (feat) {
            let newIncrement = 0
            feat.stat_mods.forEach(statMod => {
                statMod.isNew = false
                if (statMod.id > newIncrement)
                    newIncrement = statMod.id
            })
            feat.point_mods.forEach(pointMod => {
                pointMod.isNew = false
                if (pointMod.id > newIncrement)
                    newIncrement = pointMod.id
            })
            feat.costs.forEach(cost => {
                cost.isNew = false
                if (cost.id > newIncrement)
                    newIncrement = cost.id
                if (cost.acquired_select)
                    setMaxSelectDisabled(true)
            })
            newIncrement += 1
            setName(feat.name)
            setDescription(feat.description)
            setRequirements(feat.requirement)
            setLimitations(feat.limitation)
            setMaxSelected(feat.max_select)
            setStatMods(feat.stat_mods)
            setPointMods(feat.point_mods)
            setCosts(feat.costs)
            setIsHidden(feat.isHidden)
            setTrigersAnAction(feat.trigger_an_action)
            setAllowNewSelection(feat.allowNewSelection)
            setCategorySelection(feat.categorySelection)
            setIncrement(newIncrement)
        }
    }, []);


    const [increment, setIncrement] = useState(0);

    function addStatMod() {
        if (larp.larp_stats.length > 0) {
            const newStatMods = [...statMods, { "id": increment, "stat_id": larp.larp_stats[0].id, "amount": 1, "isNew": true }]
            setIncrement(increment + 1)
            setStatMods(newStatMods)
        }
    }

    function updateStatMod(statModId, attribute, value) {
        let newState = []
        statMods.forEach(statMod => {
            let newStatMod = statMod
            if (statMod.id === statModId) {
                switch (attribute) {
                    case 1:
                        newStatMod.amount = value
                        break;
                    case 2:
                        newStatMod.stat_id = value
                        break;
                }
            }
            newState.push(newStatMod)
        });
        setStatMods(newState)
    }

    function removeStatMod(statModId) {
        let newState = []
        statMods.forEach(statMod => {
            let newStatMod = statMod
            if (statMod.id !== statModId) {
                newState.push(newStatMod)
            }
        });
        setStatMods(newState)
    }

    function addCost() {
        if (larp.larp_points.length > 0 && larp.larp_categories[0].sub_categories.length > 0) {
            const newCosts = [...costs, {
                "id": increment,
                "point_id": larp.larp_points[0].id,
                "category_id": larp.larp_categories[0].id,
                "sub_category_id": larp.larp_categories[0].sub_categories[0].id,
                "amount": 1,
                "acquired_select": false,
                "isNew": true
            },]
            setIncrement(increment + 1)
            setCosts(newCosts)
        }
    }

    function updatCost(costID, attribute, value) {
        let newState = []
        costs.forEach(cost => {
            let newCost = cost
            if (cost.id === costID) {
                switch (attribute) {
                    case 1:
                        newCost.amount = parseInt(value)
                        break;
                    case 2:
                        newCost.category_id = parseInt(value)
                        newCost.sub_category_id = larp.larp_categories.find(x => x.id === parseInt(value)).sub_categories[0].id
                        newCost.point_id = larp.larp_categories.find(x => x.id === parseInt(value)).point_to_buy
                        break;
                    case 3:
                        newCost.sub_category_id = parseInt(value)
                        break;
                    case 4:
                        newCost.acquired_select = value.checked
                        newCost.amount = 0
                        setMaxSelected(1)
                        setMaxSelectDisabled(value.checked)
                        break;
                }
            }
            newState.push(newCost)
        });
        setCosts(newState)
    }

    function removeCost(costId) {
        let newState = []
        costs.forEach(cost => {
            let newCost = cost
            if (cost.id !== costId) {
                newState.push(newCost)
            }
        });
        setCosts(newState)
    }

    function addPointMod() {
        if (larp.larp_points.length > 0) {
            const newPointMods = [...pointMods, { "id": increment, "point_id": larp.larp_points[0].id, "amount": 1, "isNew": true }]
            setIncrement(increment + 1)
            setPointMods(newPointMods)
        }
    }

    function updatePointMod(pointModId, attribute, value) {
        let newState = []
        pointMods.forEach(pointMod => {
            let newPointMod = pointMod
            if (newPointMod.id === pointModId) {
                switch (attribute) {
                    case 1:
                        newPointMod.amount = value
                        break;
                    case 2:
                        newPointMod.point_id = value
                        break;
                }
            }
            newState.push(newPointMod)
        });
        setPointMods(newState)
    }

    function removePointMod(pointModId) {
        let newState = []
        pointMods.forEach(pointMod => {
            let newPointMod = pointMod
            if (pointMod.id !== pointModId) {
                newState.push(newPointMod)
            }
        });
        setPointMods(newState)
    }

    return (
        <div className="feat-form-section">
            <div className="hor-flex middle-split">
                <label>{translation.feat_table_name}: </label>
                <input type="text" className="form-control space-small flex3" value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className="hor-flex middle-split">
                <label>{translation.feat_description}: </label>
                <textarea className="form-control space-small flex3" rows="3" value={description} onChange={(e) => { setDescription(e.target.value) }}></textarea>
            </div>
            <div className="hor-flex middle-split">
                <label>{translation.feat_requirement}</label>
                <input type="text" className="form-control space-small flex3" value={requirements} onChange={(e) => { setRequirements(e.target.value) }} />
            </div>
            <div className="hor-flex middle-split">
                <label>{translation.feat_limitations}</label>
                <input type="text" className="form-control space-small flex3" value={limitations} onChange={(e) => { setLimitations(e.target.value) }} />
            </div>
            <div className="hor-flex middle-split">
                <label>{translation.feat_max_select}</label>
                <div className="flex3 align-left">
                    <input type="number" className="form-control space-small input-small" disabled={maxSelectDisabled} value={maxSelect} onChange={(e) => { setMaxSelected(e.target.value) }} />
                </div>
            </div>
            <div className="hor-flex">
                <input type="checkbox" className="form-check-input space-small" checked={isHidden} onChange={(e) => { setIsHidden(e.target.checked) }} />
                <label>{translation.feat_hidden}</label>
            </div>
            <div className="hor-flex">
                <input type="checkbox" className="form-check-input space-small" checked={trigersAnAction} onChange={(e) => { setTrigersAnAction(e.target.checked) }} />
                <label>{translation.feat_triggers}</label>
            </div>
            <div className="hor-flex align-left">
                <input type="checkbox" className="form-check-input space-small" checked={allowNewSelection} onChange={(e) => {
                    setAllowNewSelection(e.target.checked)
                    setCategorySelection(larp.larp_categories[0].id)
                }} />
                <label className="flex">{translation.feat_allow_new_selection}</label>
                {allowNewSelection &&
                    <select value={categorySelection} onChange={(e) => { setCategorySelection(Number(e.target.value)) }}>
                        {larp.larp_categories.map((category) =>
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )}
                    </select>
                }
            </div>
            <div className="hor-flex">
                <label>{translation.feat_stat_change}</label>
                <BsFillPlusCircleFill className="pointer space-small" onClick={() => addStatMod()} />
                <div>
                    {statMods.map((stat_change) =>
                        <div className="hor-flex point-display" key={stat_change.id}>
                            <input type="number" className="form-control space-small input-small" value={stat_change.amount} onChange={(e) => { updateStatMod(stat_change.id, 1, e.target.value) }} />
                            <select value={stat_change.stat_id} onChange={(e) => { updateStatMod(stat_change.id, 2, e.target.value) }}>
                                {larp.larp_stats.map((larp_stat) =>
                                    <option key={larp_stat.id} value={larp_stat.id}>{larp_stat.alias}</option>
                                )}
                            </select>
                            <BsXCircleFill className="space-small cat-delete-button" color="DarkRed" onClick={async () => { removeStatMod(stat_change.id) }} />
                        </div>
                    )}
                </div>
            </div>
            <div className="hor-flex">
                <label>{translation.feat_point_change}</label>
                <BsFillPlusCircleFill className="pointer space-small" onClick={() => addPointMod()} />
                <div>
                    {pointMods.map((point_change) =>
                        <div className="hor-flex point-display" key={point_change.id}>
                            <input type="number" className="form-control space-small input-small" value={point_change.amount} onChange={(e) => { updatePointMod(point_change.id, 1, e.target.value) }} />
                            <select value={point_change.stat_id} onChange={(e) => { updatePointMod(point_change.id, 2, e.target.value) }}>
                                {larp.larp_points.map((larp_point) =>
                                    <option key={larp_point.id} value={larp_point.id}>{larp_point.alias}</option>
                                )}
                            </select>
                            <BsXCircleFill className="space-small cat-delete-button" color="DarkRed" onClick={async () => { removePointMod(point_change.id) }} />
                        </div>
                    )}
                </div>
            </div>
            <div className="hor-flex">
                <label>{translation.feat_cost}</label>
                <BsFillPlusCircleFill className="pointer space-small" onClick={() => addCost()} />
                <div>
                    {costs.map((cost) =>
                        <div key={cost.id}>
                            <div className="hor-flex point-display">
                                {!cost.acquired_select &&
                                    <>
                                        <input type="number" className="form-control space-small input-small" value={cost.amount} onChange={(e) => { updatCost(cost.id, 1, e.target.value) }} />
                                        <span className="space-small">{larp.larp_categories.find(x => x.id === cost.category_id).point_to_buy_alias}</span>
                                        <span>{translation.feat_cost_for}:</span>
                                    </>
                                }
                                <select className="space-small" value={cost.category_id} onChange={(e) => { updatCost(cost.id, 2, e.target.value) }}>
                                    {larp.larp_categories.map((larp_cat) =>
                                        <option key={larp_cat.id} value={larp_cat.id}>{larp_cat.name}</option>
                                    )}
                                </select>
                                <select className="space-small" value={cost.sub_category_id} onChange={(e) => { updatCost(cost.id, 3, e.target.value) }}>
                                    {larp.larp_categories.find(x => x.id === cost.category_id).sub_categories.map((sub_cat) =>
                                        <option key={sub_cat.id} value={sub_cat.id}>{sub_cat.name}</option>
                                    )}
                                </select>
                                <BsXCircleFill className="space-small cat-delete-button" color="DarkRed" onClick={async () => { removeCost(cost.id) }} />
                            </div>
                            <div className="hor-flex">
                                <input type="checkbox" className="space-small" checked={cost.acquired_select} onChange={(e) => { updatCost(cost.id, 4, e.target) }} />
                                <span>{translation.feat_aquire}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <button type="button" className="btn btn-dark space-medium" onClick={() => onConfirm(name, description, requirements, limitations, maxSelect, isHidden, trigersAnAction, statMods, pointMods, costs, allowNewSelection, categorySelection)}>{confirmButtonText}</button>
        </div>
    )
}
