import { getCookieConsentValue } from "react-cookie-consent";
import Cookies from 'universal-cookie';

const cookieName = "session_token";
const cookies = new Cookies();

const getSessionToken = () => {
    if (getCookieConsentValue()) {
        var token = cookies.get(cookieName);
        if (token == undefined || token == null) {
            return null;
        }
        else {
            return token;
        }
    }
    else {
        return sessionStorage.getItem(cookieName);
    }
}
const setSessionToken = (user_session) => {
    if (getCookieConsentValue()) {
        cookies.set(cookieName, user_session);
    }
    else {
        sessionStorage.setItem(cookieName, user_session);
    }
}

const removeSessionToken = () => {
    cookies.remove(cookieName);
    sessionStorage.removeItem(cookieName);
}

const setCookieIfAlreadyLoggedIn = () => {
    var token = sessionStorage.getItem(cookieName);
    if (token !== null) {
        setSessionToken(token);
        sessionStorage.removeItem(cookieName);
    }
}

export { getSessionToken, setSessionToken, removeSessionToken, setCookieIfAlreadyLoggedIn }
