import config from "../config"
import updatedHeaders from "./headers"
import updatedHeadersWithIMG from "./headers_img";

export async function fetchLarpEvents(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_events/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchAllEvents() {
    const response = await fetch(config.apiUrl + '/events/', {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchUserEvents() {
    const response = await fetch(config.apiUrl + '/user_events/', {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function AddEvent(larp_id, event, file) {
    const formData = new FormData();
    formData.append("larp_id", larp_id);
    formData.append("name", event.name);
    formData.append("start_date", event.startDate);
    formData.append("start_time", event.startTime);
    formData.append("end_date", event.endDate);
    formData.append("end_time", event.endTime);
    formData.append("description", event.description);

    if (file) {
        formData.append('files', file, file.name)
        formData.append('file', file, file.name)
        formData.append('filename', file.name)
    }
    const response = await fetch(config.apiUrl + '/events/', {
        method: "POST",
        headers: updatedHeadersWithIMG(),
        body: formData
    })
    return response
}

export async function EditEvent(event_id, event, file) {
    const formData = new FormData();
    formData.append("name", event.name);
    formData.append("start_date", event.startDate);
    formData.append("start_time", event.startTime);
    formData.append("end_date", event.endDate);
    formData.append("end_time", event.endTime);
    formData.append("description", event.description);
    formData.append("remove_img", event.removeImg);
    if (file) {
        formData.append('files', file, file.name)
        formData.append('file', file, file.name)
        formData.append('filename', file.name)
    }
    const response = await fetch(config.apiUrl + '/events/' + event_id, {
        method: "PUT",
        headers: updatedHeadersWithIMG(),
        body: formData
    })
    return response
}

export async function DeleteEvent(event_id) {
    const response = await fetch(config.apiUrl + '/events/' + event_id, {
        method: "DELETE",
        headers: updatedHeaders(),
    })
    return response
}

export async function CloseEvent(event_id) {
    const response = await fetch(config.apiUrl + '/close_event/' + event_id, {
        method: "PUT",
        headers: updatedHeaders(),
    })
    return response
}

export async function PlayerRegisterToEvent(event_id, larp_id, character_id) {
    let eventData = {
        "event_id": event_id,
        "larp_id": larp_id,
        "character_id": character_id,
    }
    const response = await fetch(config.apiUrl + '/player_subscribe_to_event', {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(eventData)
    })
    return response
}

export async function RegisterToEvent(event_id, larp_id, character_id) {
    let eventData = {
        "event_id": event_id,
        "larp_id": larp_id,
        "character_id": character_id,
    }
    const response = await fetch(config.apiUrl + '/subscribe_to_event', {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(eventData)
    })
    return response
}

export async function PlayerRemoveFromEvent(sub_id) {
    const response = await fetch(config.apiUrl + '/player_remove_subscription/' + sub_id, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function RemoveFromEvent(sub_id) {
    const response = await fetch(config.apiUrl + '/remove_subscription/' + sub_id, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchUserSubscriptions(larp_id) {
    const response = await fetch(config.apiUrl + '/user_subscriptions/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchLarpSubscriptions(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_subscriptions/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function SubscriptionPresent(larpId, CharacterId, EventId) {
    let eventData = {
        "larp_id": larpId,
        "event_id": EventId,
        "character_id": CharacterId,
    }
    const response = await fetch(config.apiUrl + '/larp_subscriptions_char_present/', {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(eventData)
    })
    return response
}

export async function SubscriptionPaid(larpId, CharacterId, EventId) {
    let eventData = {
        "larp_id": larpId,
        "event_id": EventId,
        "character_id": CharacterId,
    }
    const response = await fetch(config.apiUrl + '/larp_subscriptions_char_paid/', {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(eventData)
    })
    return response
}

export async function SubscriptionRemovePresent(larpId, CharacterId, EventId) {
    let eventData = {
        "larp_id": larpId,
        "event_id": EventId,
        "character_id": CharacterId,
    }
    const response = await fetch(config.apiUrl + '/larp_subscriptions_char_remove_present/', {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(eventData)
    })
    return response
}

export async function SubscriptionRemovePaid(larpId, CharacterId, EventId) {
    let eventData = {
        "larp_id": larpId,
        "event_id": EventId,
        "character_id": CharacterId,
    }
    const response = await fetch(config.apiUrl + '/larp_subscriptions_char_remove_paid/', {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(eventData)
    })
    return response
}
