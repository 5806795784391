import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";
import useTranslation from './customHooks/translations';

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    charRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid black",
        fontSize: 9,
        margin: 2,
        padding: 2,
    },
    charRowTitle: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid black",
        backgroundColor: "lightgrey",
        fontSize: 11,
        margin: 2,
        padding: 2,
    },
    featColumn: {
        display: "flex",
        flexDirection: "column",
    },
    charCell: {
        borderRight: "1px solid black",
        width: "150px",
        paddingHorizontal: 4,
    }
});

// Create Document Component
const BasicDocument = (data) => {
    var chars = data.data.map(function (char, i) {
        return (
            <View key={i} style={styles.charRow}>
                <Text style={styles.charCell}>{char.name}</Text>
                <Text style={styles.charCell}>{char.user_name}</Text>
                <View style={styles.charCell}>
                    {char.character_feats.filter(feat => feat.trigger_an_action).map((feat) =>
                        <Text key={feat.id}>{feat.name}</Text>
                    )}
                </View>
            </View>
        );
    });

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.header}>
                    <Text> ~ https://larponline.ca ~ </Text>
                </View>
                <View style={styles.title}>
                    <Text>{data.data.length} {data.title} {data.larp}</Text>
                </View>
                <View style={styles.charRowTitle}>
                    <Text style={styles.charCell}>{data.translation.char_table_title}</Text>
                    <Text style={styles.charCell}>{data.translation.char_table_player}</Text>
                    <Text style={styles.charCell}>{data.translation.char_table_feat_actions}</Text>
                </View>
                {chars}
            </Page>
        </Document>
    );
}

const ExportComponent = ({ data, larp }) => {
    const translation = useTranslation()

    return (
        <div>
            <PDFDownloadLink className="btn btn-dark"
                document={<BasicDocument data={data} larp={larp} title={translation.char_pdf_title} translation={translation} />}
                fileName="export-Player.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : translation.char_table_export_button)}
            </PDFDownloadLink>
        </div>)
};

export default ExportComponent;
