import React from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import Cookies from 'js-cookie';

export default function LanguageHandler() {
  const { language, changeLanguage } = useLanguageContext();

  return (
    <select value={language} onChange={(e) => {
      Cookies.set('userLanguage', e.target.value, { expires: 30 });
      changeLanguage(e.target.value)
    }
    }>
      <option value="en">En</option>
      <option value="fr">Fr</option>
    </select>
  );
}
