import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useTranslation from "../components/customHooks/translations";

const ConfirmDialogTextCopy = ({ lblTitle, lblDesc, lblYes, lblNo, lblConfirm, actionConfirm, actionShow, actionHide, actionOnChange, varConfirmText }) => {
    const translation = useTranslation();
    if (!lblTitle) {
        lblTitle = translation.dialog_confirmation;
    }
    if (!lblDesc) {
        lblDesc = translation.dashboard_confirm_user_delete;
    }
    if (!lblYes) {
        lblYes = translation.my_character_yes;
    }
    if (!lblNo) {
        lblNo = translation.my_character_no;
    }
    if (!lblConfirm) {
        lblConfirm = translation.dashboard_confirm_email;
    }
    return (
        <Modal autoFocus={false} show={actionShow} onHide={actionHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{lblTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {lblDesc}
                <p>{lblConfirm}</p>
                <input autoFocus={true} type="text" className="form-control" onChange={(e) => { actionOnChange(e.target.value) }} value={varConfirmText} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={actionHide}>
                    {lblNo}
                </Button>
                <Button variant="primary" onClick={actionConfirm}>
                    {lblYes}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDialogTextCopy;
