import config from "../config"
import updatedHeaders from "./headers"

export async function fetchLarpArticles(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_articles/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchArticle(article_id) {
    const response = await fetch(config.apiUrl + '/article/' + article_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchArticleGroup(article_group_id) {
    const response = await fetch(config.apiUrl + '/article_group/' + article_group_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function AddArticle(larp_id, article) {
    let articleData = {
        "larp_id": larp_id,
        "name": article.name,
        "article_group": article.article_group,
        "text": article.text,
        "priority": article.priority,
        "isHidden": article.isHidden,
        "time_line_date": article.time_line_date,
    }
    const response = await fetch(config.apiUrl + '/articles/', {
        method: "POST",
        headers: updatedHeaders(),
        body: JSON.stringify(articleData)
    })
    return response
}

export async function EditArticle(article_id, article) {
    let articleData = {
        "name": article.name,
        "article_group": article.article_group,
        "text": article.text,
        "priority": article.priority,
        "isHidden": article.isHidden,
        "time_line_date": article.time_line_date,
    }
    const response = await fetch(config.apiUrl + '/articles/' + article_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(articleData)
    })
    return response
}

export async function DeleteArticle(article_id) {
    const response = await fetch(config.apiUrl + '/articles/' + article_id, {
        method: "DELETE",
        headers: updatedHeaders(),
    })
    return response
}


export async function fetchLarpArticleGroups(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_article_groups/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function AddArticleGroup(larp_id, article) {
    let articleData = {
        "larp_id": larp_id,
        "name": article.name,
        "priority": article.priority,
        "isHidden": article.isHidden,
        "isTimeline": article.isTimeline,
    }
    const response = await fetch(config.apiUrl + '/article_group/', {
        method: "POST",
        headers: updatedHeaders(),
        body: JSON.stringify(articleData)
    })
    return response
}

export async function EditArticleGroup(article_id, article_group) {
    let articleData = {
        "name": article_group.name,
        "priority": article_group.priority,
        "isHidden": article_group.isHidden,
        "isTimeline": article_group.isTimeline,
    }
    const response = await fetch(config.apiUrl + '/article_groups/' + article_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(articleData)
    })
    return response
}

export async function DeleteArticleGroup(article_id) {
    const response = await fetch(config.apiUrl + '/article_groups/' + article_id, {
        method: "DELETE",
        headers: updatedHeaders(),
    })
    return response
}

