import React from 'react';
import { MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBInput }
  from 'mdb-react-ui-kit';
import { useState } from 'react';
import useTranslation from "./customHooks/translations";
import { resetPassword } from '../api/userAPI';

function PasswordRecoveryForm() {
  const translation = useTranslation();
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [message, setMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");

  async function submit_password_recovery() {
    const response = await resetPassword(emailValue, translation.language)
    if (response.status === 404) {
      setMessage(translation.pass_rec_message_user_not_found)
    } else if (response.ok) {
      setConfirmationTitle(translation.pass_rec_message_conf_title)
      setConfirmationMessage(translation.pass_rec_message_conf_description)
    } else {
      setMessage(translation.pass_rec_message_unknown)
    }
  }

  if (confirmationMessage !== "") {
    return (<>
      <h3 className="login-message">{confirmationTitle}</h3>
      <p className="login-message">{confirmationMessage}</p>
    </>)
  } else {
    return (
      <MDBContainer fluid className='login-modal'>
        <p className="login-message">{message}</p>
        <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
          <MDBCardBody className='p-5 d-flex flex-column align-items-center'>

            <h2 className="fw-bold mb-2 text-uppercase">{translation.pass_rec_title}</h2>
            <p className="text-white-50 mb-5">{translation.pass_rec_description}</p>

            <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label={translation.pass_rec_email} id='formControlLg' type='email' size="lg" onChange={(e) => { setEmailValue(e.target.value) }} />

            <MDBBtn outline className='px-5' size='lg' style={{ color: 'white' }} onClick={async () => { submit_password_recovery() }}>
              {translation.pass_rec_send}
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    );
  }
}

export default PasswordRecoveryForm;
