import React, { useState } from "react";
import useTranslation from "../components/customHooks/translations";
import { contactUs } from "../api/userAPI";

export default function Contact() {
    const translation = useTranslation();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState("");

    async function contact_us() {
        const response = await contactUs(name, email, message)
        if (response.ok) {
            setConfirmationMessage(translation.contact_us_confirmation)
        } else {
            setConfirmationMessage("")
        }
    }

    return (
        <div className="page_content">
            <div className="restrained-section">
                <h1>{translation.menu_contact}</h1>
                <h5>{translation.contact_description}</h5>
                <h5>larponline.ca@gmail.com</h5>
                <b>{confirmationMessage}</b>
                <br /><br />
                <div>{translation.contact_name}</div>
                <input type="text" autoFocus={true} className="form-control" onChange={(e) => { setName(e.target.value) }} value={name} />
                <div>{translation.contact_email}</div>
                <input type="text" className="form-control" onChange={(e) => { setEmail(e.target.value) }} value={email} />
                <div>{translation.contact_message}</div>
                <textarea className="form-control" onChange={(e) => { setMessage(e.target.value) }} rows="6" value={message}></textarea>
                <button type="button" className="btn btn-dark space-medium" onClick={async () => { contact_us() }}>{translation.contact_us_send_button}</button>
            </div>
        </div>
    );
};

