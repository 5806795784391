import React from "react";
import { ChromePicker } from "react-color";

const ColorPicker = ({ hexColor, actionOnChange }) => {
  return (
    <ChromePicker
      color={hexColor}
      onChange={e => actionOnChange(e)}
      disableAlpha
      renderers={false}
    />
  );
};

export default ColorPicker;
