import React, { useState } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

function PasswordInput({ passwordLabelClass, label, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="hor-flex fill mb-4 mx-5 w-100">
      <MDBInput
        labelClass={passwordLabelClass}
        wrapperClass="flex"
        label={label}
        type={showPassword ? 'text' : 'password'}
        size="lg"
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="input-visibility-button">
        {showPassword ? (
          <BsEyeFill size={25} color="gray" onClick={togglePasswordVisibility} />
        ) : (
          <BsEyeSlashFill size={25} color="gray" onClick={togglePasswordVisibility} />
        )}
      </div>
    </div>
  );
}

export default PasswordInput;
