import LoginForm from "../components/LoginForm";
import React from "react";

export default function Login() {
    return (
        <>
            <LoginForm />
        </>
    );
};
