import React, { useState, useEffect } from 'react';
import useTranslation from './customHooks/translations';
import Button from 'react-bootstrap/esm/Button';

const ArticleForm = ({ article, article_groups, articles, onCreate, onUpdate }) => {
  const translation = useTranslation();

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    article_group: '',
    text: '',
    priority: 1,
    isHidden: false,
    time_line_date: '',
  });

  useEffect(() => {
    if (article) {
      setFormData({
        id: article.id || '',
        name: article.name || '',
        article_group: article.article_group.id || '',
        text: article.text || '',
        priority: article.priority || 1,
        isHidden: article.isHidden || false,
        time_line_date: article.time_line_date || "",
      });
    }
  }, [article]);

  const handleChange = (e) => {
    if (e.target.name === "article_group") {
      let nextPriority = 1
      articles.filter(x => Number(x.article_group.id) === Number(e.target.value)).map((article) => {
        if (article.priority >= nextPriority) {
          nextPriority = article.priority + 1
        }
      })
      setFormData({ ...formData, [e.target.name]: e.target.value, priority: nextPriority });
    } else if (e.target.type === "checkbox")
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  return (
    <>
      <form className="event-form">
        <div className="hor-flex">
          <div className="flex p10">
            <label>{translation.article_name_label}</label>
            <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} maxLength="50" />
          </div>
          <div className="flex p10">
            <label>{translation.article_group_label}</label>
            <select className="form-select" name="article_group" value={formData.article_group} onChange={handleChange}>
              <option></option>
              {article_groups.map((article_group) => (
                <option value={article_group.id}>{article_group.name} ({article_group.priority})</option>
              ))}
            </select>
          </div>
          <div className="p10">
            <label>{translation.article_priority_label}:</label>
            <input type="number" min="1" name="priority" className="form-control input-small" value={formData.priority} onChange={handleChange} maxLength="50" />
          </div>
        </div>
        <div className="hor-flex">
          <label>{translation.article_hidden_label}: </label>
          <input type="checkbox" name="isHidden" checked={formData.isHidden} onChange={handleChange} />
        </div>
        {formData.article_group && article_groups.find(x => x.id === Number(formData.article_group)).isTimeline &&
          <div className="hor-flex">
            <label>{translation.article_timeline_date_label}: </label>
            <input type="string" name="time_line_date" className="form-control input-medium" value={formData.time_line_date} onChange={handleChange} />
          </div>
        }
        <label>{translation.article_text_label}</label>
        <textarea name="text" rows="10" className="form-control space-small" value={formData.text} onChange={handleChange} />
      </form>
      <Button variant="secondary" onClick={() => article !== null ? onUpdate(formData) : onCreate(formData)}>
        {article !== null ? translation.event_save_button : translation.event_create_button}
      </Button>
    </>
  );
};

export default ArticleForm;
