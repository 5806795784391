import React, { useState } from 'react';
import { BsFillHouseFill, BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import useTranslation from '../../components/customHooks/translations';
import { addCharacterData } from "../../api/characterAPI";
import AddNewCharacterDialog from '../../components/AddNewCharacterDialog';
import { useSelector } from 'react-redux';
import featureFlags from "../../utils/FeatureFlags";
import customCodes from "../../utils/customCodes";
import verifyCustomCodes from "../../utils/checkCustomCodes";

const ArticleDisplayList = ({ articles, displayArticles, larp, characters, isDm, larpid, handleNewCharacter }) => {
  const translation = useTranslation();
  let lastArticleGroupName = '';
  const [characterName, setCharacterName] = useState("");
  const loggedIn = useSelector(state => state.user.loggedIn)
  const [addCharacterShow, setAddCharacterShow] = useState(false);
  const handleAddCharacterClose = () => setAddCharacterShow(false);
  const handleAddCharacterShow = () => {
    setAddCharacterShow(true);
  }

  async function addCharacter() {
    if (!characterName) {
      alert(translation.my_character_invalid_name);
      return;
    }
    const response = await addCharacterData(characterName, larpid)
    if (response.ok) {
      setCharacterName("")
      handleNewCharacter();
      handleAddCharacterClose();
    }
  }

  const [groupVisibility, setGroupVisibility] = useState({});

  const toggleGroupVisibility = (groupName) => {
    setGroupVisibility(prevState => ({
      ...prevState,
      [groupName]: !prevState[groupName]
    }));
  };

  return (
    <div className="ver-flex article-display">
      {articles && articles.length > 0 &&
        <h3 className="align-left hor-flex fill">
          <Link to={"/larp/" + larpid + "/article/" + articles.filter(x => x.article_group.id === null).id} onClick={() => displayArticles(articles.filter(x => x.article_group.id === null))} className="fill">
            <BsFillHouseFill className="back-home" />{larp.larp_name}
          </Link>
        </h3>
      }
      {loggedIn && (featureFlags.characterCreationFeature.value || verifyCustomCodes(larp.customCode, customCodes.characterCreationFeature)) &&
        <>
          <h3 className="align-left hor-flex fill">
            <Link className="fill">{translation.larp_characters_title}</Link>
            {!groupVisibility["Characters"] ?
              <BsChevronUp className="pointer" onClick={() => toggleGroupVisibility("Characters")} /> :
              <BsChevronDown className="pointer" onClick={() => toggleGroupVisibility("Characters")} />}
          </h3>
          {!groupVisibility["Characters"] && characters.map((character) =>
            <Link key={character.id} to={"/character/" + character.id} className="align-left">{character.name}</Link>
          )}
          {!groupVisibility["Characters"] &&
            <>
              <Link className="btn btn-outline-secondary add-character-button" onClick={() => handleAddCharacterShow()}>{translation.my_larps_add_character_button}</Link>
              {addCharacterShow &&
                <AddNewCharacterDialog
                  lblTitle={translation.my_larps_add_character_title}
                  lblAdd={translation.my_larps_modal_add_button}
                  lblClose={translation.my_larps_modal_close_button}
                  actionConfirm={addCharacter}
                  actionShow={handleAddCharacterShow}
                  actionHide={handleAddCharacterClose}
                  actionOnChange={setCharacterName}
                  varCharName={characterName}
                />
              }
            </>
          }
        </>
      }

      {articles && articles.map((article, idx) => {
        const isNewGroup = lastArticleGroupName !== article.article_group.name;
        if (isNewGroup) {
          lastArticleGroupName = article.article_group.name;
        }
        if (article.article_group.isHidden && !isDm)
          return

        return (
          <React.Fragment key={article.id}>
            {isNewGroup && article.article_group && (
              <>
                <hr />
                <h3 className="align-left hor-flex fill">
                  <Link to={"/larp/" + larpid} onClick={() => displayArticles(articles.filter(x => x.article_group.id === article.article_group.id && !x.isHidden || isDm))} className="fill">{article.article_group.name}</Link>
                  {!groupVisibility[article.article_group.name] ?
                    <BsChevronUp className="pointer" onClick={() => toggleGroupVisibility(article.article_group.name)} /> :
                    <BsChevronDown className="pointer" onClick={() => toggleGroupVisibility(article.article_group.name)} />}
                </h3>
              </>
            )}
            {!groupVisibility[article.article_group.name] && (!article.isHidden || isDm) && (
              <Link to={"/larp/" + larpid + "/article/" + article.id} onClick={() => displayArticles([article])} className="align-left">{article.name}</Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ArticleDisplayList;
