import React from 'react';
import { MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBInput }
  from 'mdb-react-ui-kit';
import { useState } from 'react';
import useTranslation from "./customHooks/translations";
import { useLocation } from 'react-router-dom';
import { verifiedPasswordReset } from '../api/userAPI';
import { checkIfPasswordStrong } from './SignUpForm';
import PasswordInput from './PasswordInput';

function VerifiedPasswordRecoveryForm() {
  const translation = useTranslation();

  const [message, setMessage] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userToken = queryParams.get('token');
  const userEmail = queryParams.get('email');

  function formValidation() {
    let isValid = true;
    if (passwordValue.trim() === "") {
      setPasswordError(true);
      isValid = false;
    } else {
      if (!checkIfPasswordStrong(passwordValue)) {
        setPasswordError(true);
        alert(translation.signup_password_requirement)
        isValid = false;
      } else {
        setPasswordError(false);
      }
    }

    if (passwordValue !== confirmPasswordValue) {
      setConfirmPasswordError(true)
      isValid = false;
    } else
      setConfirmPasswordError(false)

    return isValid
  }

  async function submit_change_Password() {
    if (formValidation()) {
      const response = await verifiedPasswordReset(userEmail, userToken, passwordValue)
      if (response.status === 404) {
        setMessage(translation.verif_pass_message_user_not_found)
      } if (response.status === 401) {
        setMessage(translation.verif_pass_message_unauthorized)
      } else if (response.ok) {
        setConfirmation(translation.verif_pass_confirmation)
      } else {
        setMessage(translation.verif_pass_message_unknown)
      }
    }
  }
  let passwordNameLabelClass = passwordError ? 'text-red' : 'text-white'
  let confirmPasswordNameLabelClass = confirmPasswordError ? 'text-red' : 'text-white'
  if (confirmation !== "") {
    return (
      <>
        <h3 className="login-message">{confirmation}</h3>
      </>)
  } else {
    return (
      <MDBContainer fluid className='login-modal'>
        <p className="login-message">{message}</p>
        <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
          <MDBCardBody className='p-5 d-flex flex-column align-items-center'>

            <h2 className="fw-bold mb-2 text-uppercase">{translation.verif_pass_rec_title}</h2>
            <p className="text-white-50 mb-5">{translation.verif_pass_rec_description}</p>
            <label>{translation.signup_password_requirement}</label>
            <PasswordInput wrapperClass='mb-4 mx-5 w-100' passwordLabelClass={passwordNameLabelClass} label={translation.signup_password} onChange={(value) => { setPasswordValue(value) }} />
            <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass={confirmPasswordNameLabelClass} label={translation.signup_confirm_password} type='password' size="lg" onChange={(e) => { setConfirmPasswordValue(e.target.value) }} />

            <MDBBtn outline className='px-5' size='lg' style={{ color: 'white' }} onClick={async () => { submit_change_Password() }}>
              {translation.verif_pass_rec_send}
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    );
  }
}

export default VerifiedPasswordRecoveryForm;
