import React, { useState, useEffect } from 'react';
import useTranslation from './customHooks/translations';
import Button from 'react-bootstrap/esm/Button';
import config from '../config';
import { BsXCircleFill } from 'react-icons/bs';

const EventForm = ({ event, onCreate, onUpdate }) => {
  const translation = useTranslation();
  const [message, setMessage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    image: '',
    name: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    description: '',
    removeImg: false
  });

  useEffect(() => {
    if (event) {
      setFormData({
        id: event.id || '',
        image: event.image_path || '',
        name: event.name || '',
        startDate: event.start_date ? event.start_date : '',
        startTime: event.start_time ? event.start_time : '',
        endDate: event.end_date ? event.end_date : '',
        endTime: event.end_time ? event.end_time : '',
        description: event.description || '',
        removeImg: false
      });
    }
  }, [event]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file !== undefined) {
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setNewImage(e.target.files[0])
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    }
  };

  function validateForm() {
    if (formData.name === '') {
      setMessage(translation.event_noname);
      return false
    }
    if (formData.startDate === '') {
      setMessage(translation.event_nodate);
      return false;
    }
    return true
  }
  const handleCreate = (e) => {
    if (validateForm()) {
      onCreate(formData)
    }
  };

  const handleUpdate = (e) => {
    if (validateForm()) {
      onUpdate(formData, newImage)
    }
  };

  const removeImg = () => {
    setImagePreviewUrl(null)
    setNewImage(null)
    setFormData({ ...formData, ["image"]: null });
    setFormData({ ...formData, ["removeImg"]: true });
  };

  const onSubmit = (e) => {
    event !== null ? handleUpdate() : handleCreate();
    e.preventDefault();
  };


  return (
    <>
      <form className="event-form" onSubmit={(e) => { onSubmit(e) }}>
        {imagePreviewUrl !== '' ?
          <img className="event-img" src={imagePreviewUrl} />
          :
          <>
            {formData.image !== '' &&
              <img className="event-img" src={config.apiUrl + "/image-uploads/" + formData.image} />
            }
          </>
        }
        <div>
          <input className="form-control-file" type="file" name="image" onChange={handleFileChange} />
          <BsXCircleFill className="space-small delete-button" size={20} color="gray" onClick={removeImg} />
        </div>
        <label>{translation.event_label_name}</label>
        <input type="text" name="name" className="form-control space-small" value={formData.name} onChange={handleChange} maxLength="50" />
        <label>{translation.event_label_start_date}</label>
        <div className="hor-flex">
          <input type="date" name="startDate" className="form-control space-small" value={formData.startDate} onChange={handleChange} />
          <input type="time" name="startTime" className="form-control space-small" value={formData.startTime} onChange={handleChange} />
        </div>
        <label>{translation.event_label_end_date}</label>
        <div className="hor-flex">
          <input type="date" name="endDate" className="form-control space-small" value={formData.endDate} onChange={handleChange} />
          <input type="time" name="endTime" className="form-control space-small" value={formData.endTime} onChange={handleChange} />
        </div>
        <label>{translation.event_label_description}</label>
        <textarea name="description" className="form-control space-small" value={formData.description} onChange={handleChange} />

        <p className="errorMessage">{message}</p>
        <Button type="submit" variant="secondary" onSubmit={(e) => onSubmit(e)}>
          {event !== null ? translation.event_save_button : translation.event_create_button}
        </Button>
      </form>
    </>
  );
};

export default EventForm;
