import config from "../config";
import { getSessionToken } from "../utils/SessionTokenUtils";

export default function updatedHeaders() {
        return {
                'Content-Type': 'application/json',
                "Authorization": getSessionToken(),
                "apiKey": config.apiKey
        }
}
