function sortByDate(newestFirst) {
    return function (a, b) {
        var dateA = new Date(a.date).getTime();
        var dateB = new Date(b.date).getTime();
        if (newestFirst) {
            return dateA < dateB ? 1 : -1;
        }
        else {
            return dateA > dateB ? 1 : -1;
        }
    }
}

export { sortByDate };
