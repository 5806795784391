import React from "react";
import useTranslation from "./customHooks/translations";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const VerificationDialog = ({ dialogShow, dialogHide, title, description, callback }) => {
    const translation = useTranslation();

    return (
        <Modal autoFocus={false} show={dialogShow} onHide={dialogHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{description}</span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={dialogHide}>
                    {translation.my_larps_modal_close_button}
                </Button>
                <Button variant="primary" onClick={callback}>
                    {translation.my_larps_modal_continue_button}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VerificationDialog;
