import './App.css';
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import HeaderNav from './components/HeaderNav';
import Home from './pages/Home';
import Contact from './pages/Contact';
import MyLarps from './pages/MyLarps';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import PasswordRecovery from './pages/PasswordRecovery';
import VerifiedPasswordRecovery from './pages/VerifiedPasswordRecovery';
import EmailVerification from './pages/EmailVerification';
import Account from './pages/Account';
import CreateLarp from './pages/Larp/CreateLarp';
import Larp from './pages/Larp/Larp';
import UpdateLarp from './pages/Larp/UpdateLarp';
import Character from './pages/Character/Character';
import UpdateCharacter from './pages/Character/UpdateCharacter';
import { useDispatch } from 'react-redux';
import { log_in } from './redux/UserHandler';
import UpdateLarpFeat from './pages/Feat/UpdateFeat.js';
import UpdateLarpPlayer from './pages/Larp/UpdateLarpPlayer.js';
import UpdateLarpEvent from './pages/Larp/UpdateLarpEvent.js';
import UpdateLarpArticle from './pages/Larp/UpdateLarpArticle.js';
import Calendar from './pages/Calendar.js';
import useTranslation from "./components/customHooks/translations";
import CookieConsent from "react-cookie-consent";
import { getSessionToken, setCookieIfAlreadyLoggedIn } from './utils/SessionTokenUtils.js';


function App() {
  const translation = useTranslation();
  const dispatch = useDispatch();

  const Layout = () => {
    return (
      <>
        <HeaderNav />
        <CookieConsent
          buttonText={translation.cookie_yes}
          onAccept={setCookieIfAlreadyLoggedIn}>{translation.cookie_message}</CookieConsent>
        <Outlet />
      </>
    )
  }

  useEffect(() => {
    if (getSessionToken() !== null) {
      dispatch(log_in())
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="mylarps" element={<MyLarps />} />
            <Route path="contact" element={<Contact />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="login" element={<Login />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="password-recovery" element={<PasswordRecovery />} />
            <Route path="verified_password_recovery" element={<VerifiedPasswordRecovery />} />
            <Route path="email_verification" element={<EmailVerification />} />
            <Route path="account" element={<Account />} />
            <Route path="createlarp" element={<CreateLarp />} />
            <Route path="update-larp/:id" element={<UpdateLarp />} />
            <Route path="update-larp-feat/:id" element={<UpdateLarpFeat />} />
            <Route path="update-larp-event/:id" element={<UpdateLarpEvent />} />
            <Route path="update-larp-article/:id" element={<UpdateLarpArticle />} />
            <Route path="update-larp-player/:id" element={<UpdateLarpPlayer />} />
            <Route path="larp/:id" element={<Larp />} />
            <Route path="larp/:id/article/:articleid" element={<Larp />} />
            <Route path="update-character/:id" element={<UpdateCharacter />} />
            <Route path="character/:id" element={<Character />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
