import React, { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import { fetchLarpCharacters, fetchLarpData } from "../../api/larpAPI";
import { useNavigate, useParams } from "react-router";
import Table from 'react-bootstrap/Table';
import { deleteCharacter, updateCharactersPoints, updateCharactersStats } from "../../api/characterAPI";
import { SubscriptionPaid, SubscriptionPresent, SubscriptionRemovePaid, SubscriptionRemovePresent, fetchLarpEvents, fetchLarpSubscriptions } from "../../api/eventAPI";
import ConfirmDialog from "../../components/ConfirmDialog";
import ExportComponent from "../../components/ExportComponent";
import RegisterCharacterModal from "../../components/RegisterCharaterModal";
import RemoveRegisteredCharaterModal from "../../components/RemoveRegisteredCharaterModal";
import { getSessionToken } from "../../utils/SessionTokenUtils";

export default function UpdateLarpPlayer() {
    const translation = useTranslation();
    const navigate = useNavigate()
    const { id } = useParams()

    const [characters, setCharacters] = useState([])
    const [events, setEvents] = useState([])
    const [subs, setSubs] = useState([])
    const [larp, setLarp] = useState();
    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedPresentStatus, setSelectedPresentStatus] = useState("ALL");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("ALL");
    const [selectedCharId, setSelectedCharId] = useState();
    const [filterNameText, setFilterNameText] = useState("")
    const [filterCategoryText, setFilterCategoryText] = useState("")
    const [filterFeatText, setFilterFeatText] = useState("")
    const [hideNPC, setHideNPC] = useState(false)
    const [onlyNPC, setOnlyNPC] = useState(false)
    const [commentText, setCommentText] = useState("")
    const [showMods, setShowMod] = useState(false)
    const [selectedCount, setSelectedCount] = useState(0)
    const [ptAmount, setPtAmount] = useState(1)
    const [ptChange, setPtChange] = useState()
    const [statAmount, setStatAmount] = useState(1)
    const [statChange, setStatChange] = useState()
    const [confirmDialogShow, setConfirmDialogShow] = useState(false);
    const onConfirmDialogClose = () => {
        setConfirmDialogShow(false)
    }
    const onConfirmDialogShow = (event, characterId) => {
        event.stopPropagation()
        setSelectedCharId(characterId)
        setConfirmDialogShow(true);
    }
    const [charRegistrationDialogShow, setCharRegistrationDialogShow] = useState(false);
    const onCharRegistrationDialogClose = () => {
        setCharRegistrationDialogShow(false)
    }

    const [charRemoveRegistrationDialogShow, setRemoveCharRegistrationDialogShow] = useState(false);
    const onCharRemoveRegistrationDialogClose = () => {
        setRemoveCharRegistrationDialogShow(false)
    }

    async function delete_character() {
        const response = await deleteCharacter(selectedCharId)
        setSelectedCharId(null)
        if (response.ok) {
            onConfirmDialogClose()
            getLarpsPlayers()
        }
    }

    async function playerPresent(event, characterId) {
        event.stopPropagation()
        const response = await SubscriptionPresent(larp.larp.id, characterId, selectedEvent)
        if (response.ok) {
            getLarpSubs()
        }
    }

    async function playerPaid(event, characterId) {
        event.stopPropagation()
        const response = await SubscriptionPaid(larp.larp.id, characterId, selectedEvent)
        if (response.ok) {
            getLarpSubs()
        }
    }

    async function playerRemovePresent(event, characterId) {
        event.stopPropagation()
        const response = await SubscriptionRemovePresent(larp.larp.id, characterId, selectedEvent)
        if (response.ok) {
            getLarpSubs()
        }
    }

    async function playerRemovePaid(event, characterId) {
        event.stopPropagation()
        const response = await SubscriptionRemovePaid(larp.larp.id, characterId, selectedEvent)
        if (response.ok) {
            getLarpSubs()
        }
    }

    async function getLarpsPlayers(selectedCharactersId = []) {
        const response = await fetchLarpCharacters(id)
        if (response.ok) {
            let larp_response = await response.json();
            larp_response.forEach(character => {
                if (selectedCharactersId.includes(character.id))
                    character.selected = true
            });
            updateSelectionCount()
            setCharacters(larp_response)
        }
    }

    async function getLarp() {
        const response = await fetchLarpData(id)
        if (response.ok) {
            let larp_response = await response.json();
            setLarp(larp_response)
            if (larp_response.larp_points[0] !== undefined)
                setPtChange(larp_response.larp_points[0].id)
            if (larp_response.larp_stats[0] !== undefined)
                setStatChange(larp_response.larp_stats[0].id)
        }
    }

    async function getLarpEvents() {
        const response = await fetchLarpEvents(id)
        if (response.ok) {
            let larp_response = await response.json();
            setEvents(larp_response)
        }
    }

    async function getLarpSubs() {
        const response = await fetchLarpSubscriptions(id)
        if (response.ok) {
            let larp_response = await response.json();
            setSubs(larp_response)
        }
    }

    function updateSelectionCount() {
        setSelectedCount(filteredCharacters.filter(x => x.selected === true).length);
    }

    function presenceFilterOnChange(value) {
        setSelectedPresentStatus(value);
        let goodCharacters = []

        subs.filter(x => Number(x.event_id) === Number(selectedEvent)).forEach((sub => {
            let char = characters.find(y => y && y.id === Number(sub.character_id))
            if (!char)
                return;

            let isValid = true
            if ((value === "Present" && !sub.present) ||
                (value === "NotPresent" && sub.present) ||
                (selectedPaymentStatus === "Payed" && !sub.payed) ||
                (selectedPaymentStatus === "NotPayed" && sub.payed))
                isValid = false

            if (isValid) {
                goodCharacters.push(char);
            }
        }))

        filteredCharacters = goodCharacters;
        updateSelectionCount();
    }

    function paymentFilterOnChange(value) {
        setSelectedPaymentStatus(value);
        let goodCharacters = []

        subs.filter(x => Number(x.event_id) === Number(selectedEvent)).forEach((sub => {
            let char = characters.find(y => y && y.id === Number(sub.character_id))
            if (!char)
                return;
            let isValid = true
            if ((selectedPresentStatus === "Present" && !sub.present) ||
                (selectedPresentStatus === "NotPresent" && sub.present) ||
                (value === "Payed" && !sub.payed) ||
                (value === "NotPayed" && sub.payed))
                isValid = false

            if (isValid) {
                goodCharacters.push(char);
            }
        }))

        filteredCharacters = goodCharacters;
        updateSelectionCount();
    }

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        }
        getLarp()
        getLarpsPlayers()
        getLarpEvents()
        getLarpSubs()
        // eslint-disable-next-line
    }, [])

    function toggleSelection(character_id) {
        if (characters.find(x => x.id === character_id).selected)
            characters.find(x => x.id === character_id).selected = false
        else
            characters.find(x => x.id === character_id).selected = true

        let newState = []
        characters.forEach(character => {
            newState.push(character)
        });
        updateSelectionCount();
        setCharacters(newState)
    }

    async function submitPtChange() {
        let characterList = []
        filteredCharacters.forEach(character => {
            if (character.selected)
                characterList.push(character.id)
        });
        if (characterList.length === 0)
            alert(translation.char_table_pt_change_warning)
        else if (commentText === "")
            alert(translation.char_table_change_comment_empty)
        else {
            const response = await updateCharactersPoints(id, characterList, ptChange, ptAmount, commentText)
            if (response.ok)
                getLarpsPlayers(characterList)
        }
    }

    async function submitStatChange() {
        let characterList = []
        filteredCharacters.forEach(character => {
            if (character.selected)
                characterList.push(character.id)
        });
        if (characterList.length === 0)
            alert(translation.char_table_pt_change_warning)
        else {
            const response = await updateCharactersStats(id, characterList, statChange, statAmount)
            if (response.ok)
                getLarpsPlayers(characterList)
        }
    }

    async function selectByEvent(event_id) {
        filteredCharacters = [];
        setSelectedEvent(event_id)
        characters.forEach(char => {
            char.selected = false
        })
        subs.filter(x => Number(x.event_id) === Number(event_id)).forEach((sub => {
            let char = characters.find(x => x.id === Number(sub.character_id))
            if (char) {
                char.selected = true;
                filteredCharacters.push(char);
            }
        }))

        let newState = []
        characters.forEach(character => {
            newState.push(character)
        });
        updateSelectionCount()
        setCharacters(newState)
    }

    let filteredCharacters = [];
    if (selectedEvent) {
        subs.filter(x => Number(x.event_id) === Number(selectedEvent)).forEach((sub => {
            let char = characters.find(y => y && y.id === Number(sub.character_id))
            if (!char)
                return
            let isValid = true
            if ((selectedPresentStatus === "Present" && !sub.present) ||
                (selectedPresentStatus === "NotPresent" && sub.present) ||
                (selectedPaymentStatus === "Payed" && !sub.payed) ||
                (selectedPaymentStatus === "NotPayed" && sub.payed))
                isValid = false

            if (isValid) {
                char.sub_status_present = sub.present
                char.sub_status_payed = sub.payed
                char.sub_status = ""
                if (sub.present)
                    char.sub_status = "present"
                if (sub.payed)
                    char.sub_status = "paid"
                filteredCharacters.push(char)
            }
        }))
    } else
        filteredCharacters = characters

    if (hideNPC) {
        filteredCharacters = filteredCharacters.filter(x => x.is_npc !== true);
    }

    if (onlyNPC) {
        filteredCharacters = filteredCharacters.filter(x => x.is_npc === true);
    }

    if (filterNameText !== "") {
        const search = filterNameText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        filteredCharacters = filteredCharacters.filter(
            x => x.user_name && (x.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(search) || x.user_name.toLowerCase().includes(search)))
    }

    if (filterCategoryText !== "") {
        const search = filterCategoryText.toLowerCase()
        filteredCharacters = filteredCharacters.filter(
            x => x.character_categories.some(y => y.sub_category && y.sub_category.toLowerCase().includes(search)) ||
                x.character_feats.some(y => y.name && y.name.toLowerCase().includes(search)))
    }

    if (filterFeatText !== "") {
        const search = filterFeatText.toLowerCase()
        filteredCharacters = filteredCharacters.filter(x => x.character_feats.some(y => y.name && y.name.toLowerCase().includes(search)))
    }

    return (
        <>
            <div className="feat-title">
                {selectedCount > 0 ?
                    (<h1>{translation.char_table_title} ({filteredCharacters.length} / {characters.length}) {translation.char_table_selection} ({selectedCount}/{filteredCharacters.length})</h1>)
                    : (<h1>{translation.char_table_title} ({filteredCharacters.length} / {characters.length})</h1>)
                }
            </div>
            <div className="page_content">
                {!showMods &&
                    <button type="button" className="btn btn-dark space-medium" onClick={() => { setShowMod(!showMods) }}>{translation.char_table_show_mods}</button>
                }
                {showMods &&
                    <div>
                        {translation.char_table_change_comment}
                        <input type="string" className="form-control space-small input-medium" value={commentText} onChange={(e) => { setCommentText(e.target.value) }} />

                        <div className="hor-flex add-pts-field">
                            <input type="number" className="form-control space-small input-small" value={ptAmount} onChange={(e) => { setPtAmount(e.target.value) }} />
                            <select value={ptChange} onChange={(e) => { setPtChange(e.target.value) }}>
                                {larp && larp.larp_points.map((point) =>
                                    <option key={point.id} value={point.id}>{point.alias}</option>
                                )}
                            </select>
                            <button type="button" className="btn btn-dark space-medium" onClick={async () => { submitPtChange() }}>{translation.char_table_pt_change}</button>
                        </div>
                        <div className="hor-flex add-pts-field">
                            <input type="number" className="form-control space-small input-small" value={statAmount} onChange={(e) => { setStatAmount(e.target.value) }} />
                            <select value={statChange} onChange={(e) => { setStatChange(e.target.value) }}>
                                {larp && larp.larp_stats.map((stat) =>
                                    <option key={stat.id} value={stat.id}>{stat.alias}</option>
                                )}
                            </select>
                            <button type="button" className="btn btn-dark space-medium" onClick={async () => { submitStatChange() }}>{translation.char_table_stat_change}</button>
                        </div>
                    </div>
                }
                <div className="hor-flex align-left">
                    <div className="hor-flex">
                        <span className="bold">{translation.subscribe_character_select_by_event}</span>
                        <select className="form-select space-small" onChange={(e) => selectByEvent(e.target.value)} value={selectedEvent}>
                            <option></option>
                            {events.map((event) =>
                                <option key={event.id} value={event.id}>{event.name}: {event.start_date}</option>
                            )}
                        </select>
                    </div>
                    <div className="hor-flex">
                        <label className="bold">{translation.feat_filter_label}:</label>
                        <input type="text" className="form-control space-small" value={filterNameText} onChange={(e) => { setFilterNameText(e.target.value) }} />
                    </div >
                    <div className="hor-flex">
                        <label className="bold">{translation.feat_filter_category_label}:</label>
                        <input type="text" className="form-control space-small" value={filterCategoryText} onChange={(e) => { setFilterCategoryText(e.target.value) }} />
                    </div>
                    <div className="hor-flex">
                        <label className="bold">{translation.feat_filter_feat_label}:</label>
                        <input type="text" className="form-control space-small" value={filterFeatText} onChange={(e) => { setFilterFeatText(e.target.value) }} />
                    </div>
                    <div className="ver-flex">
                        <label>
                            <input type="checkbox" onChange={(e) => { setHideNPC(!hideNPC) }} checked={hideNPC}></input>
                            {" " + translation.feat_filter_show_npc_label}
                        </label>
                        <label>
                            <input type="checkbox" onChange={(e) => { setOnlyNPC(!onlyNPC) }} checked={onlyNPC}></input>
                            {" " + translation.feat_filter_only_show_npc_label}
                        </label>
                    </div>
                    <div className="hor-flex">
                        {larp &&
                            <ExportComponent data={filteredCharacters} larp={larp.larp.name} />
                        }
                    </div>
                </div >
                {selectedEvent &&
                    <div className="hor-flex align-left">
                        <div className="hor-flex">
                            <span className="bold">{translation.subscribe_character_select_present_status}: </span>
                            <select className="form-select space-small"
                                onChange={(e) => presenceFilterOnChange(e.target.value)}
                                value={selectedPresentStatus}>
                                <option value="All"> {translation.subscribe_character_present_all}</option>
                                <option value="Present"> {translation.subscribe_character_present_present}</option>
                                <option value="NotPresent"> {translation.subscribe_character_present_not_present}</option>
                            </select>
                        </div>
                        <div className="hor-flex">
                            <span className="bold">{translation.subscribe_character_select_payment_status}: </span>
                            <select className="form-select space-small"
                                onChange={(e) => paymentFilterOnChange(e.target.value)}
                                value={selectedPaymentStatus}>
                                <option value="All"> {translation.subscribe_character_payment_all}</option>
                                <option value="Payed"> {translation.subscribe_character_payment_payed}</option>
                                <option value="NotPayed"> {translation.subscribe_character_payment_not_payed}</option>
                            </select>
                        </div>
                    </div>
                }
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{translation.char_table_name}</th>
                            <th>{translation.char_table_points}</th>
                            <th>{translation.char_table_stats}</th>
                            <th>{translation.char_table_categories}</th>
                            <th>{translation.char_table_feat_actions}</th>
                            <th>{translation.char_table_actions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCharacters.map((character) =>
                            <tr key={character.id} className={"selected-" + character.selected + " sub_status-" + character.sub_status} onClick={() => { toggleSelection(character.id) }}>
                                <td>
                                    <span className="pointer" onClick={() => { navigate("/character/" + character.id) }}><b>{character.name}</b></span><br />
                                    <span><b>{character.user_name}</b></span>
                                </td>
                                <td>
                                    <div className="ver-flex">
                                        {character.character_points.map((point) =>
                                            <div key={point.id}>
                                                {point.total > 0 &&
                                                    <span >{point.remaining}/{point.total} {point.point_alias}</span>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div className="ver-flex">
                                        {character.character_stats.map((stat) =>
                                            <div key={stat.id}>
                                                {stat.total > 0 &&
                                                    <span>{stat.total} {stat.stat_alias}</span>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div className="ver-flex">
                                        {character.character_categories.map((category) =>
                                            <div key={category.id}>
                                                {category.sub_category &&
                                                    <span>{category.category_name}: {category.sub_category}</span>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    {character.character_feats.filter(feat => feat.trigger_an_action).map((feat) =>
                                        <div key={feat.id}>
                                            <span>{feat.name}</span>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {selectedEvent &&
                                        <>
                                            {(!character.sub_status_present) &&
                                                <button type="button" className="btn btn-dark space-small btn-orange" onClick={async (e) => { playerPresent(e, character.id) }}>{translation.char_table_present_button}</button>
                                            }
                                            {(!character.sub_status_payed) &&
                                                <button type="button" className="btn btn-dark space-small btn-green" onClick={async (e) => { playerPaid(e, character.id) }}>{translation.char_table_paid_button}</button>
                                            }
                                        </>
                                    }
                                    <button type="button" className="btn btn-dark space-small" onClick={async () => { navigate("/update-character/" + character.id) }}>{translation.char_table_modify_button}</button>
                                    <button type="button" className="btn btn-danger space-small" onClick={async (e) => { onConfirmDialogShow(e, character.id) }}>{translation.char_table_delete_button}</button>
                                    {selectedEvent &&
                                        <>
                                            {(character.sub_status_present) &&
                                                <button type="button" className="btn btn-dark space-small btn-blue" onClick={async (e) => { playerRemovePresent(e, character.id) }}>{translation.char_table_present_remove_button}</button>
                                            }
                                            {(character.sub_status_payed) &&
                                                <button type="button" className="btn btn-dark space-small btn-blue" onClick={async (e) => { playerRemovePaid(e, character.id) }}>{translation.char_table_paid_remove_button}</button>
                                            }
                                        </>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {
                    selectedEvent &&
                    <>
                        <button type="button" className="btn btn-dark space-medium"
                            onClick={async () => setCharRegistrationDialogShow(true)}>{translation.char_table_char_sub_button}</button>
                        <RegisterCharacterModal dialogShow={charRegistrationDialogShow} dialogHide={onCharRegistrationDialogClose}
                            characters={characters} larpId={larp.larp.id} events={events} subscriptions={subs} selectedEventId={selectedEvent} callback={getLarpSubs} />

                        <button type="button" className="btn btn-dark space-medium"
                            onClick={async () => setRemoveCharRegistrationDialogShow(true)}>{translation.char_table_char_sub_remove_button}</button>
                        <RemoveRegisteredCharaterModal dialogShow={charRemoveRegistrationDialogShow} dialogHide={onCharRemoveRegistrationDialogClose}
                            characters={characters} larpId={larp.larp.id} events={events} subscriptions={subs} selectedEventId={selectedEvent} callback={getLarpSubs} />
                    </>
                }

                {
                    confirmDialogShow &&
                    <ConfirmDialog
                        lblTitle={translation.dialog_confirmation}
                        lblDesc={translation.my_character_confirm_delete}
                        lblYes={translation.my_character_yes}
                        lblNo={translation.my_character_no}
                        actionConfirm={delete_character}
                        actionShow={onConfirmDialogShow}
                        actionHide={onConfirmDialogClose}
                    />
                }
            </div >
        </>
    );
};
