import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTranslation from "./customHooks/translations";
import LanguageHandler from "./LanguageHandler";
import { useSelector } from 'react-redux';
import { fetchIsAdmin } from "../api/userAPI";

export default function HeaderNav() {
  const translation = useTranslation();
  const loggedIn = useSelector(state => state.user.loggedIn)
  let button, loggedInMenu, adminMenu, calendarMenu;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLocal, setIsLocal] = useState(false);

  async function verifyIsAdmin() {
    const response = await fetchIsAdmin()
    if (response.ok) {
      let isAdmin_response = await response.json();
      setIsAdmin(isAdmin_response)
    }
  }

  function verifyIsLocal() {
    setIsLocal((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"));
  }

  useEffect(() => {
    verifyIsAdmin()
    verifyIsLocal()
  });

  if (loggedIn) {
    button = <Link className="btn btn-outline-warning my-2 my-sm-0" to="/account">{translation.menu_logged_in}</Link>;
    loggedInMenu =
      <li className="nav-item active">
        <Link className="nav-link" to="/mylarps">{translation.menu_larp}</Link>
      </li>
    calendarMenu =
      <li className="nav-item">
        <Link className="nav-link" to="/calendar">{translation.menu_calendar}</Link>
      </li>
  } else {
    button = <Link className="btn btn-outline-warning my-2 my-sm-0" to="/login">{translation.menu_login}</Link>;
  }
  if (isAdmin) {
    adminMenu =
      <li className="nav-item nav-item-first">
        <Link className="nav-link" to="/dashboard">{translation.menu_dashboard}</Link>
      </li>
  }
  else {
    calendarMenu =
      <li className="nav-item nav-item-first">
        <Link className="nav-link" to="/calendar">{translation.menu_calendar}</Link>
      </li>
  }

  return (
    <div>
      {isLocal &&
        <div className="sticky-local">
          <h3>LOCALHOST</h3>
        </div>
      }
      <header className="App-header">
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand" to="/">Larp Online</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-dark navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarToggle">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              {adminMenu}
              {calendarMenu}
              {loggedInMenu}
              <li className="nav-item">
                <Link className="nav-link" to="/contact">{translation.menu_contact}</Link>
              </li>
              <li className="nav-item-spacer">
              </li>
            </ul>
            <div className="menu-spacer"></div>
            <div className="btn nav-item-lang">
              <LanguageHandler />
            </div>
            <form className="form-inline my-2 my-lg-0" id="loginButton">
              {button}
            </form>
          </div>
        </nav>
      </header>
    </div>
  );
}
