import LocalizedStrings from "react-localization";
import localization from "../../localization";
import { useLanguageContext } from "../contexts/LanguageContext";

export default function useTranslation() {
  const { language } = useLanguageContext();
  let translation = new LocalizedStrings(localization);

  translation.setLanguage(language);
  return translation;
}

export function insertParams(translation, params) {
  let result = translation;
  for (const key in params) {
    result = result.replace(`{${key}}`, params[key]);
  }
  return result;
}
