import PasswordRecoveryForm from "../components/PasswordRecoveryForm";
import React from "react";

export default function PasswordRecovery() {
    return (
        <>
            <PasswordRecoveryForm />
        </>
    );
};
