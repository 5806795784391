import featureFlags from "../../utils/FeatureFlags";
import customCodes from "../../utils/customCodes";
import useTranslation from "../../components/customHooks/translations";

const FeatureFlagAdmin = () => {
    const translation = useTranslation();
    return (
        <div className="page_content">
            <h3>Custom Codes</h3>
            <div className="restrained-section">
                {Object.keys(customCodes).map((flag, index) =>
                    <div className="full-display" key={featureFlags[flag].name}>
                        <label>
                            {translation[featureFlags[flag].name] + " : " + customCodes[flag].value}
                        </label>
                    </div>
                )}
            </div>
            <h3>Feature flags</h3>
            <div className="restrained-section">
                {Object.keys(featureFlags).map((flag, index) =>
                    <div className="full-display" key={featureFlags[flag].name}>
                        <label>
                            <input type="checkbox" readOnly checked={featureFlags[flag].value} />
                            {" " + translation[featureFlags[flag].name]}
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FeatureFlagAdmin;
