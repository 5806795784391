import React from "react";
import SignUpForm from "../components/SignUpForm";

export default function SignUp() {
    return (
        <>
            <SignUpForm />
        </>
    );
};
