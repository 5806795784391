import React, { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import useTranslation, { insertParams } from "../../components/customHooks/translations";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useNavigate, useParams } from "react-router";
import { BsCalendar2Event, BsFillInfoCircleFill, BsMagic, BsNewspaper, BsPeopleFill, BsShieldShaded, BsXCircleFill } from "react-icons/bs";
import {
    addLarpCat, addLarpPoint, addLarpStat, addSubCat, fetchLarpData, larpAddGM, larpAuthorized, larpRemoveGM, larpRequestPublication, removeLarpCat, removeLarpCatVerify, removeLarpPoint, removeLarpStat, removeSubCat, removeSubCatVerify, update_larp_data, fetchIsCreator, hideLarp,
    fetchLarpTypes
} from "../../api/larpAPI";
import featureFlags from "../../utils/FeatureFlags";
import config from "../../config";
import customCodes from "../../utils/customCodes";
import verifyCustomCodes from "../../utils/checkCustomCodes";
import { fetchIsAdmin } from "../../api/userAPI";
import VerificationDialog from "../../components/VerificationDialog";
import ColorPicker from "../../components/ColorPicker";
import { getSessionToken } from "../../utils/SessionTokenUtils";

const UpdateLarp = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams()
    const [larp_id, setLarpId] = useState("");
    const [file, setFile] = useState()
    const [name, setName] = useState("");
    const [linkName, setLinkName] = useState("");
    const [imagePath, setImagePath] = useState("");
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");
    const [website1, setWebsite1] = useState("");
    const [website1display, setWebsite1display] = useState("");
    const [website2, setWebsite2] = useState("");
    const [website2display, setWebsite2display] = useState("");
    const [website3, setWebsite3] = useState("");
    const [website3display, setWebsite3display] = useState("");
    const [email, setEmail] = useState("");
    const [customCode, setCustomCode] = useState("");
    const [status, setStatus] = useState("");
    const [points, setPoints] = useState([])
    const [stats, setStats] = useState([])
    const [categories, setCategories] = useState([])
    const [dms, setDms] = useState([])
    const [newDmEmail, setNewDmEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCreator, setIsCreator] = useState(false);
    const [hexColor, setHexColor] = useState("#FA5A02");
    const [confirmDialogShow, setConfirmDialogShow] = useState(false);
    const [showCatVerification, setShowCatVerification] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [showSubCatVerification, setShowSubCatVerification] = useState(false);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState();
    const [char_count, setCount] = useState();
    const [feat_count, setFeatCount] = useState();
    const [alcoholAccepted, setAlcoholAccepted] = useState(false);
    const [averagePlayerCount, setAveragePlayerCount] = useState(0);
    const [minAge, setMinAge] = useState(0);
    const [maxAge, setMaxAge] = useState(99);
    const [frenchLarpTypes, setFrenchLarpTypes] = useState([]);
    const [englishLarpTypes, setEnglishLarpTypes] = useState([]);
    const [currentLarpTypes, setCurrentLarpTypes] = useState([]);
    const [selectedLartTypesId, setSelectedLartTypesId] = useState([]);

    async function verifyIsAdmin() {
        const response = await fetchIsAdmin()
        if (response.ok) {
            let isAdmin_response = await response.json();
            setIsAdmin(isAdmin_response)
        }
    }

    function validateLarp() {
        let validationText = "";
        if (!name)
            validationText += translation.larp_save_error_name + "\n";

        for (const category of categories) {
            if (category.sub_categories.length === 0) {
                validationText += translation.larp_save_error_sub_cat + "\n";
                break;
            }
        }

        if (validationText !== "")
            alert(validationText)

        if (minAge < 0 || maxAge < 0) {
            validationText += translation.larp_save_error_negative_age + "\n";
        } else if (minAge != null && maxAge != null && minAge > maxAge) {
            validationText += translation.larp_save_error_min_age_bigger + "\n";
        }

        return validationText === ""
    }

    const onConfirmDialogClose = () => setConfirmDialogShow(false);
    const onConfirmDialogShow = () => {
        setConfirmDialogShow(true);
    }

    const onColorChange = (e) => {
        setHexColor(e.hex);
    }

    useEffect(() => {
        verifyIsAdmin()
        verifyIsCreator()
        if (getSessionToken() === null) {
            navigate("/login");
        }

        const verifyAuthorization = async () => {
            const response = await larpAuthorized(id)
            if (response.ok) {
                let larp_response = await response.json();
                if (!larp_response) {
                    navigate("/mylarps")
                }
            }
        }

        async function verifyIsCreator() {
            const response = await fetchIsCreator(id)
            if (response.ok) {
                let iscreatore_response = await response.json();
                setIsCreator(iscreatore_response)
            }
        }

        const fetchData = async () => {
            const response = await fetchLarpData(id)
            if (response.ok) {
                let data_response = await response.json();
                let larp_response = data_response.larp;
                let point_response = data_response.larp_points;
                let stat_response = data_response.larp_stats;
                let category_response = data_response.larp_categories;
                let dm_response = data_response.larp_dm;
                let type_response = data_response.larp_types;
                setLarpId(larp_response["id"])
                setName(larp_response["name"])
                setLinkName(larp_response["link_name"])
                setAddress(larp_response["address"])
                setWebsite1(larp_response["website1"])
                setWebsite1display(larp_response["website1_display"])
                setWebsite2(larp_response["website2"])
                setWebsite2display(larp_response["website2_display"])
                setWebsite3(larp_response["website3"])
                setWebsite3display(larp_response["website3_display"])
                setEmail(larp_response["email"])
                setDescription(larp_response["description"])
                setHexColor(larp_response["color"])
                setAlcoholAccepted(larp_response["alcohol_accepted"])
                setAveragePlayerCount(larp_response["average_player_count"])
                setMinAge(larp_response["min_age"])
                setMaxAge(larp_response["max_age"])
                setStatus(larp_response["status"])
                setCustomCode(larp_response["custom_code"])
                setImagePath(larp_response["image_path"])
                setPoints(point_response)
                setStats(stat_response)
                setCategories(category_response)
                setDms(dm_response)

                var larpTypesId = [];
                type_response.forEach(larpType => {
                    larpTypesId.push(larpType.larp_type_id);
                });
                setSelectedLartTypesId(larpTypesId);
            }
        }
        verifyAuthorization()
            .catch(console.error);
        fetchData()
            .catch(console.error);
    }, []);

    const getLarpTypes = async () => {
        const response = await fetchLarpTypes();
        if (response.ok) {
            const data = await response.json();
            const frenchOptions = data.map(larpType => ({
                larpType: larpType,
                value: larpType.id,
                label: larpType.french_name
            }));
            setFrenchLarpTypes(frenchOptions);

            const englishOptions = data.map(larpType => ({
                larpType: larpType,
                value: larpType.id,
                label: larpType.english_name
            }));
            setEnglishLarpTypes(englishOptions);
            return (translation.language === "fr") ? frenchOptions : englishOptions;
        }
        return [];
    };

    function filterCurrentLarpTypes() {
        let filteredLarpTypes = [];
        if (translation.language === "fr") {
            filteredLarpTypes = frenchLarpTypes.filter(larpType => selectedLartTypesId.includes(larpType.value));
        } else {
            filteredLarpTypes = englishLarpTypes.filter(larpType => selectedLartTypesId.includes(larpType.value));
        }
        setCurrentLarpTypes(filteredLarpTypes);
    };

    useEffect(() => {
        filterCurrentLarpTypes();
    }, [selectedLartTypesId, englishLarpTypes]);

    async function setLarpType(larpTypes) {
        var larpTypesId = [];
        larpTypes.forEach(larpType => {
            larpTypesId.push(larpType.larpType.id);
        });
        setSelectedLartTypesId(larpTypesId);
    }

    async function submit_larp() {
        if (validateLarp()) {
            const response = await update_larp_data(id, name, description, address, website1, website1display, website2, website2display, website3, website3display, email, customCode, file, points, stats, categories, dms, hexColor, alcoholAccepted, averagePlayerCount, minAge, maxAge, selectedLartTypesId)

            if (response.ok) {
                navigate("/larp/" + id)
            }
        }
    }

    async function add_point() {
        const response = await addLarpPoint(id)
        if (response.ok) {
            const point_response = await response.json()
            const newpoints = [...points, point_response]
            setPoints(newpoints)
        }
    }

    async function updateDms(dm_id, value) {
        let newState = []
        dms.forEach(dm => {
            let newDM = dm
            if (dm.id === dm_id) {
                newDM.isHidden = value.checked
            }
            newState.push(newDM)
        });
        setDms(newState)
    }

    async function updatePoints(point_id, attribute, value) {
        let newState = []
        points.forEach(point => {
            let newPoint = point
            if (point.id === point_id) {
                switch (attribute) {
                    case 1:
                        newPoint.name = value
                        break;
                    case 2:
                        newPoint.alias = value
                        break;
                    case 3:
                        newPoint.start_value = value
                        break;
                }
            }
            newState.push(newPoint)
        });
        setPoints(newState)
    }

    async function remove_point(point_id) {
        for (const category of categories) {
            if (category.point_to_buy === point_id) {
                alert(translation.larp_error_remove_point)
                return;
            }
        }

        const response = await removeLarpPoint(id, point_id)
        if (response.ok) {
            let newState = []
            points.forEach(point => {
                let newPoint = point
                if (point.id !== point_id) {
                    newState.push(newPoint)
                }
            });
            setPoints(newState)
        }
    }

    async function add_stat() {
        const response = await addLarpStat(id)
        if (response.ok) {
            const stat_response = await response.json()
            const newpStats = [...stats, stat_response]
            setStats(newpStats)
        }
    }

    async function updateStats(stat_id, attribute, value) {
        let newState = []
        stats.forEach(stat => {
            let newStat = stat
            if (stat.id === stat_id) {
                switch (attribute) {
                    case 1:
                        newStat.name = value
                        break;
                    case 2:
                        newStat.alias = value
                        break;
                    case 3:
                        newStat.start_value = value
                        break;
                }
            }
            newState.push(newStat)
        });
        setStats(newState)
    }

    async function remove_stat(stat_id) {
        const response = await removeLarpStat(stat_id)
        if (response.ok) {
            let newState = []
            stats.forEach(stat => {
                let newStat = stat
                if (stat.id !== stat_id) {
                    newState.push(newStat)
                }
            });
            setStats(newState)
        }
    }

    async function add_cat() {
        if (points.length === 0) {
            alert(translation.larp_save_error_point);
            return;
        }
        const response = await addLarpCat(id)
        if (response.ok) {
            const category_response = await response.json()
            const newCategories = [...categories, category_response]
            setCategories(newCategories)
        }
    }

    async function updateCat(cat_id, attribute, value) {
        let newState = []
        categories.forEach(cat => {
            let newCat = cat
            if (cat.id === cat_id) {
                switch (attribute) {
                    case 1:
                        newCat.name = value
                        break;
                    case 2:
                        newCat.description = value
                        break;
                    case 3:
                        newCat.point_to_buy = value
                        break;
                    case 4:
                        newCat.min_select = value
                        break;
                    case 5:
                        newCat.max_select = value
                        break;
                    case 6:
                        newCat.isHidden = value.checked
                        break;
                    case 7:
                        newCat.displayHidden = value.checked
                        break;
                }
            }
            newState.push(newCat)
        });
        setCategories(newState)
    }

    async function remove_cat_verification(cat_id) {
        setSelectedCategoryId(cat_id);
        const response = await removeLarpCatVerify(cat_id)
        if (response.ok) {
            const count_response = await response.json()
            setShowCatVerification(true)
            setCount(count_response.affected_characters)
            setFeatCount(count_response.affected_feats)
        }
    }

    async function remove_cat() {
        if (selectedCategoryId) {
            const response = await removeLarpCat(selectedCategoryId)
            if (response.ok) {
                let newState = []
                categories.forEach(cat => {
                    let newCat = cat
                    if (cat.id !== selectedCategoryId) {
                        newState.push(newCat)
                    }
                });
                setCategories(newState)
            }
        }
        setShowCatVerification(false)
    }

    async function add_sub_cat(category_id) {
        const response = await addSubCat(category_id)
        if (response.ok) {
            const sub_response = await response.json()
            let newState = []
            categories.forEach(cat => {
                let newCat = cat
                if (category_id === cat.id) {
                    const newSubCategories = [...cat.sub_categories, sub_response]
                    newCat.sub_categories = newSubCategories
                }
                newState.push(newCat)
            })
            setCategories(newState)
        }
    }

    async function updateSubCat(cat_id, subCat_id, attribute, value) {
        let newState = []
        categories.forEach(cat => {
            let newCat = cat
            if (cat_id === cat.id) {
                let newSubCatList = []
                cat.sub_categories.forEach(subCat => {
                    let newSub = subCat
                    if (subCat.id === subCat_id) {
                        switch (attribute) {
                            case 1:
                                newSub.name = value
                                break;
                            case 2:
                                newSub.isHidden = value.checked
                                break;
                            case 3:
                                newSub.description = value
                                break;
                        }
                    }
                    newSubCatList.push(newSub)
                });
                newCat.sub_categories = newSubCatList
            }
            newState.push(newCat)
        })
        setCategories(newState)
    }

    async function remove_sub_cat_verification(subCat_id) {
        setSelectedSubCategoryId(subCat_id)
        const response = await removeSubCatVerify(subCat_id)
        if (response.ok) {
            const count_response = await response.json()
            setCount(count_response.affected_characters)
            setFeatCount(count_response.affected_feats)
            if (count_response.affected_characters + count_response.affected_feats === 0)
                remove_sub_cat(subCat_id)
            else
                setShowSubCatVerification(true)
        }
    }

    async function remove_sub_cat(subCat_id) {
        subCat_id = selectedSubCategoryId ? selectedSubCategoryId : subCat_id;
        if (subCat_id) {
            const response = await removeSubCat(subCat_id)
            if (response.ok) {
                let newState = []
                categories.forEach(cat => {
                    let newCat = cat
                    let newSubCatList = []
                    cat.sub_categories.forEach(subCat => {
                        let newSub = subCat
                        if (subCat.id !== subCat_id) {
                            newSubCatList.push(newSub)
                        }
                    });
                    newCat.sub_categories = newSubCatList
                    newState.push(newCat)
                })
                setCategories(newState)
            }
        }
        setShowSubCatVerification(false)
    }

    async function request_publication() {
        const response = await larpRequestPublication(id)
        if (response.ok)
            setStatus("PENDING")
        else if (response.status === 403)
            alert(translation.larp_publish_existing_error)
    }

    async function inviteGameMaster() {
        const response = await larpAddGM(id, newDmEmail)
        if (response.ok) {
            let data_response = await response.json();
            setDms(data_response)
        }
    }

    async function removeGameMaster(DmUserId) {
        const response = await larpRemoveGM(id, DmUserId)
        if (response.ok) {
            let data_response = await response.json();
            setDms(data_response)
        }
    }

    function displayLarp() {
        if (status === "PUBLISHED")
            navigate("/larp/" + linkName)
        else
            navigate("/larp/" + id)
    }

    function manageFeat() {
        navigate("/update-larp-feat/" + larp_id)
    }

    function manageCalendar() {
        navigate("/update-larp-event/" + larp_id)
    }

    function managePlayers() {
        navigate("/update-larp-player/" + larp_id)
    }

    function manageArticles() {
        navigate("/update-larp-article/" + larp_id)
    }

    async function hide_larp() {
        const response = await hideLarp(id)
        if (response.ok) {
            navigate("/mylarps")
        }
    }


    return (
        <div className="page_content hor-flex">
            <div className="left-side-bar">
                <div className="manage-section">
                    <BsShieldShaded className="pointer" size={50} onClick={displayLarp} />
                    <button type="button" className="btn btn-dark space-medium" onClick={async () => { displayLarp() }}>{translation.display_larp_button}</button>
                </div>
                {(verifyCustomCodes(customCode, customCodes.complexeLarpFeature) || featureFlags.complexeLarpFeature.value) &&
                    <div className="manage-section">
                        <BsMagic className="pointer" size={50} onClick={manageFeat} />
                        <button type="button" className="btn btn-dark space-medium" onClick={async () => { manageFeat() }}>{translation.feat_manage_button}</button>
                    </div>
                }
                <div className="manage-section">
                    <BsCalendar2Event className="pointer" size={50} onClick={manageCalendar} />
                    <button type="button" className="btn btn-dark space-medium" onClick={async () => { manageCalendar() }}>{translation.calendar_manage_button}</button>
                </div>
                {(verifyCustomCodes(customCode, customCodes.complexeLarpFeature) || featureFlags.complexeLarpFeature.value) &&
                    <div className="manage-section">
                        <BsPeopleFill className="pointer" size={50} onClick={managePlayers} />
                        <button type="button" className="btn btn-dark space-medium" onClick={async () => { managePlayers() }}>{translation.player_manage_button}</button>
                    </div>
                }
                <div className="manage-section">
                    <BsNewspaper className="pointer" size={50} onClick={manageArticles} />
                    <button type="button" className="btn btn-dark space-medium" onClick={async () => { manageArticles() }}>{translation.article_manage_button}</button>
                </div>
            </div>
            <div className="flex">
                <form >
                    <div className="form-group">
                        {imagePath &&
                            <img className="larp-img" src={config.apiUrl + "/image-uploads/" + imagePath} />
                        }
                        <input className="form-control-file" type="file" name="inputFile" onChange={(e) => { setFile(e.target.files[0]) }} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_name}</label>
                        <input type="text" className="form-control" onChange={(e) => { setName(e.target.value) }} value={name} disabled={status === "PUBLISHED"} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_link}:</label>
                        <span className="space-small">{linkName}</span>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_address}</label>
                        <input type="text" className="form-control" onChange={(e) => { setAddress(e.target.value) }} value={address} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_website}</label>
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_website_display}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite1display(e.target.value) }} value={website1display} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_website_link}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite1(e.target.value) }} value={website1} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_website2}</label>
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_website2_display}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite2display(e.target.value) }} value={website2display} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_website2_link}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite2(e.target.value) }} value={website2} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_website3}</label>
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_website3_display}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite3display(e.target.value) }} value={website3display} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_website3_link}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite3(e.target.value) }} value={website3} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_email}</label>
                        <input type="text" className="form-control" onChange={(e) => { setEmail(e.target.value) }} value={email} />
                    </div>
                    {isAdmin &&
                        <div className="form-group">
                            <label>{translation.larp_label_custom_code}</label>
                            <input type="text" className="form-control" onChange={(e) => { setCustomCode(e.target.value) }} value={customCode} placeholder={translation.larp_label_custom_code_placeholder} />
                        </div>
                    }
                    <div className="form-group">
                        <label>{translation.larp_label_description}</label>
                        <textarea className="form-control" id="larpDescription" rows="3" onChange={(e) => { setDescription(e.target.value) }} value={description}></textarea>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_display_color}</label>
                        <ColorPicker
                            hexColor={hexColor}
                            actionOnChange={e => onColorChange(e)} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_average_player_count}</label>
                        <input type="number" min={0} className="form-control" onChange={(e) => { setAveragePlayerCount(e.target.value) }} value={averagePlayerCount} />
                    </div>
                    <div className="form-group">
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_min_age}</label>
                                <input type="number" min={0} max={99} className="form-control" onChange={(e) => { setMinAge(e.target.value) }} value={minAge} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_max_age}</label>
                                <input type="number" min={0} max={99} className="form-control" onChange={(e) => { setMaxAge(e.target.value) }} value={maxAge} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group hor-flex sameLineAligned">
                        <label htmlFor="category-filter">{translation.map_filter_categories}</label>
                        {translation.language === "fr" &&
                            <AsyncSelect defaultOptions value={currentLarpTypes} loadOptions={getLarpTypes} options={frenchLarpTypes} placeholder="" name="larpTypes" menuPosition="fixed" noOptionsMessage={() => translation.map_category_no_option} onChange={(e) => { setLarpType(e) }} isMulti />
                        }
                        {translation.language === "en" &&
                            <AsyncSelect defaultOptions value={currentLarpTypes} loadOptions={getLarpTypes} options={englishLarpTypes} placeholder="" name="larpTypes" menuPosition="fixed" noOptionsMessage={() => translation.map_category_no_option} onChange={(e) => { setLarpType(e) }} isMulti />
                        }
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_status}<BsFillInfoCircleFill className="space-small" title={translation.larp_status_tooltip} />: </label>
                        {status === "LOCAL" &&
                            <div>
                                <span>{translation.larp_status_local}</span>
                                <button type="button" className="btn btn-dark space-medium" onClick={async () => { request_publication() }}>{translation.larp_publish_button}</button>
                            </div>
                        }
                        {status === "PENDING" &&
                            <div>
                                <span className="space-small">{translation.larp_status_pending}</span>
                            </div>
                        }
                        {status === "PUBLISHED" &&
                            <div>
                                <span className="space-small">{translation.larp_status_published}</span>
                            </div>
                        }
                    </div>
                    <div className="form-group ver-flex">
                        <label>{translation.larp_game_masters}: </label>
                        {dms && dms.map((dm) =>
                            <div key={dm.id}>
                                <span>{dm.first_name} {dm.last_name}: {dm.email}</span>
                                {!dm.creator &&
                                    <BsXCircleFill className="space-small pointer" color="DarkRed" onClick={async () => { removeGameMaster(dm.id) }} />
                                }
                                <span className="space-medium"><b>{translation.larp_game_masters_hidden} </b></span>
                                <input type="checkbox" className="cat-input space-small" checked={dm.isHidden} onChange={(e) => { updateDms(dm.id, e.target) }} />
                            </div>
                        )}
                        <label>{translation.larp_invite_game_masters_label}</label>
                        <input type="text" className="form-control" value={newDmEmail} onChange={(e) => { setNewDmEmail(e.target.value) }} />
                        <button type="button" className="btn btn-dark space-medium" onClick={async () => { inviteGameMaster() }}>{translation.larp_invite_game_masters}</button>
                    </div>
                </form>
                {(featureFlags.complexeLarpFeature.value.value || verifyCustomCodes(customCode, customCodes.complexeLarpFeature)) &&
                    <div>
                        <h3>{translation.points_title}</h3>
                        <p>{translation.points_description}</p>
                        {points.map((point) =>
                            <div className="hor-flex point-display" key={point.id}>
                                <span>{translation.points_name}</span>
                                <input type="text" className="input-medium space-small" value={point.name} onChange={(e) => { updatePoints(point.id, 1, e.target.value) }} />
                                <span>{translation.points_alias}</span>
                                <input type="text" className="input-small space-small" value={point.alias} onChange={(e) => { updatePoints(point.id, 2, e.target.value) }} />
                                <span>{translation.points_start_value}</span>
                                <input type="text" className="input-small space-small" value={point.start_value} onChange={(e) => { updatePoints(point.id, 3, e.target.value) }} />
                                <BsXCircleFill className="space-small" color="DarkRed" onClick={async () => { remove_point(point.id) }} />
                            </div>
                        )}
                        <button type="button" className="btn btn-dark space-medium" onClick={async () => { add_point() }}>{translation.points_add_button}</button>
                        <h3>{translation.stat_title}</h3>
                        <p>{translation.stat_description}</p>
                        {stats.map((stat) =>
                            <div className="hor-flex point-display" key={stat.id}>
                                <span>{translation.stat_name}</span>
                                <input type="text" className="input-medium space-small" value={stat.name} onChange={(e) => { updateStats(stat.id, 1, e.target.value) }} />
                                <span>{translation.stat_alias}</span>
                                <input type="text" className="input-small space-small" value={stat.alias} onChange={(e) => { updateStats(stat.id, 2, e.target.value) }} />
                                <span>{translation.stat_start_value}</span>
                                <input type="text" className="input-small space-small" value={stat.start_value} onChange={(e) => { updateStats(stat.id, 3, e.target.value) }} />
                                <BsXCircleFill className="space-small" color="DarkRed" onClick={async () => { remove_stat(stat.id) }} />
                            </div>
                        )}
                        <button type="button" className="btn btn-dark space-medium" onClick={async () => { add_stat() }}>{translation.stat_add_button}</button>
                        <h3>{translation.category_title}</h3>
                        <p>{translation.category_desc}</p>
                        {categories.map((category) =>
                            <div className="cat-display" key={category.id}>
                                <div className="hor-flex">
                                    <span className="cat-label">{translation.category_name}: </span>
                                    <input type="text" className="cat-input input-medium space-small" value={category.name} onChange={(e) => { updateCat(category.id, 1, e.target.value) }} />
                                </div>
                                <div className="hor-flex">
                                    <span className="cat-label">{translation.category_description}: </span>
                                    <input type="text" className="cat-input input-medium space-small" value={category.description} onChange={(e) => { updateCat(category.id, 2, e.target.value) }} />
                                </div>
                                <div>
                                    <span className="cat-label">{translation.category_hidden}: </span>
                                    <input type="checkbox" className="cat-input space-small" checked={category.isHidden} onChange={(e) => { updateCat(category.id, 6, e.target) }} />
                                </div>
                                <div>
                                    <span className="cat-label">{translation.category_details_hidden}: </span>
                                    <input type="checkbox" className="cat-input space-small" checked={category.displayHidden} onChange={(e) => { updateCat(category.id, 7, e.target) }} />
                                </div>
                                <div className="hor-flex">
                                    <span className="cat-label">{translation.category_point_to_buy}: </span>
                                    <span className="cat-input">
                                        <select value={category.point_to_buy} onChange={(e) => { updateCat(category.id, 3, e.target.value) }}>
                                            {points.map((point) =>
                                                <option key={point.id} value={point.id}>{point.name}</option>
                                            )}
                                        </select>
                                    </span>
                                </div>
                                {featureFlags.categoryMultipleSelectFeature.value &&
                                    <div className="hor-flex">
                                        <span className="cat-label">{translation.category_min_select}: </span>
                                        <input type="number" className="input-small space-small" value={category.min_select} onChange={(e) => { updateCat(category.id, 4, e.target.value) }} />
                                        <span className="cat-label">{translation.category_max_select}: </span>
                                        <input type="number" className="input-small space-small" value={category.max_select} onChange={(e) => { updateCat(category.id, 5, e.target.value) }} />
                                    </div>
                                }
                                <div className="hor-flex">
                                    <span className="sub-cat-label">{translation.category_sub_categories}: </span>
                                    <div className="flex">
                                        {category.sub_categories.map((subCat) =>
                                            <div className="cat-sub-cat hor-flex" key={subCat.id}>
                                                <div className="form-group flex">
                                                    <label>{translation.larp_label_description}</label>
                                                    <textarea className="form-control" id="larpDescription" rows="3" onChange={(e) => { updateSubCat(category.id, subCat.id, 3, e.target.value) }} value={subCat.description}></textarea>
                                                </div>
                                                <div>
                                                    <input type="text" className="space-small cat-input" value={subCat.name} onChange={(e) => { updateSubCat(category.id, subCat.id, 1, e.target.value) }} />
                                                    <span>{translation.category_sub_categories_hidden}: </span>
                                                    <input type="checkbox" className="space-small" checked={subCat.isHidden} onChange={(e) => { updateSubCat(category.id, subCat.id, 2, e.target) }} />
                                                    <BsXCircleFill className="space-small cat-delete-button" color="DarkRed" onClick={async () => { remove_sub_cat_verification(subCat.id) }} />
                                                </div>
                                            </div>
                                        )}
                                        <button type="button" className="btn btn-dark space-medium" onClick={async () => { add_sub_cat(category.id) }}>{translation.category_sub_categories_add}</button>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-danger remove_cat_button" onClick={async () => { remove_cat_verification(category.id) }}>{translation.category_remove}</button>
                            </div>
                        )}
                        <button type="button" className="btn btn-dark space-medium" onClick={async () => { add_cat() }}>{translation.category_add}</button>
                    </div>
                }
                <button type="button" className="btn btn-dark space-medium" onClick={async () => { submit_larp() }}>{translation.larp_save_button}</button>
                {isCreator &&
                    <div>
                        <button type="button" className="btn btn-danger space-medium" onClick={onConfirmDialogShow}>{translation.larp_delete_button}</button>
                    </div>
                }
                {confirmDialogShow &&
                    <ConfirmDialog
                        lblTitle={translation.dialog_confirmation}
                        lblDesc={translation.larp_confirm_delete}
                        lblYes={translation.my_character_yes}
                        lblNo={translation.my_character_no}
                        actionConfirm={hide_larp}
                        actionShow={onConfirmDialogShow}
                        actionHide={onConfirmDialogClose}
                    />
                }
            </div>
            <div className="right-side-bar">
            </div>
            <VerificationDialog dialogShow={showCatVerification} dialogHide={() => { setShowCatVerification(false); setSelectedCategoryId(undefined); }}
                title={translation.category_remove_title}
                description={insertParams(translation.category_remove_verification, { char_count, feat_count })}
                callback={remove_cat} />
            <VerificationDialog dialogShow={showSubCatVerification} dialogHide={() => { setShowSubCatVerification(false); setSelectedSubCategoryId(undefined); }}
                title={translation.sub_category_remove_title}
                description={insertParams(translation.category_remove_verification, { char_count, feat_count })}
                callback={remove_sub_cat} />
        </div>
    );
};

export default UpdateLarp;
