import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import useTranslation from "../components/customHooks/translations";
import { useSelector } from 'react-redux';
import frLocale from '@fullcalendar/core/locales/fr';
import { fetchAllEvents, fetchUserEvents } from "../api/eventAPI";

const CalendarMode = {
    MyLarp: "MyLarp",
    AllLarp: "AllLarp"
};

export default function Calendar() {
    const translation = useTranslation();
    const loggedIn = useSelector(state => state.user.loggedIn)
    const [mode, setMode] = useState(CalendarMode.AllLarp);
    const [events, setEvents] = useState([]);

    async function getAllEvents() {
        setMode(CalendarMode.AllLarp)
        const response = await fetchAllEvents()
        if (response.ok) {
            let larp_event_response = await response.json();
            parseEvents(larp_event_response);
        }
    }

    async function getUserEvents() {
        setMode(CalendarMode.MyLarp)
        const response = await fetchUserEvents()
        if (response.ok) {
            let larp_event_response = await response.json();
            parseEvents(larp_event_response);
        }
    }

    function parseEvents(eventsList) {
        var allEvents = new Array()
        eventsList.forEach(element => {
            var url = "/larp/" + element.id;
            if (element.link_name) {
                url = "/larp/" + element.link_name;
            }
            var newEvent = {
                title: element.title,
                start: element.start,
                end: element.end,
                color: element.color,
                url: url
            };
            var datesplit = newEvent.end.split('-');
            var oldDate = new Date(datesplit[0], datesplit[1] - 1, datesplit[2]);
            var newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate() + 1);
            var newMonth = (Number(newDate.getMonth()) + 1).toString();
            var newDay = newDate.getDate().toString();

            if (newMonth.length <= 1) {
                newMonth = "0" + newMonth;
            }
            if (newDay.length <= 1) {
                newDay = "0" + newDay;
            }

            var formattedDate = newDate.getFullYear() + "-" + newMonth + "-" + newDay;
            newEvent.end = formattedDate;
            allEvents.push(newEvent);
        });
        setEvents(allEvents);
    }

    useEffect(() => {
        getAllEvents()
    }, [])

    let modeClassName = mode === CalendarMode.MyLarp ? "MyLarp-active" : "AllLarp-active"
    return (
        <div className={modeClassName + " calendar"}>
            {loggedIn &&
                <FullCalendar
                    headerToolbar={{
                        start: "mylarps allLarps",
                        center: "title",
                        end: "prev today next"
                    }}
                    plugins={[daygridPlugin]}
                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                    locale={translation.language}
                    locales={frLocale}
                    events={events}
                    height={"auto"}
                    customButtons={{
                        mylarps: {
                            text: translation.menu_larp,
                            click: () => {
                                getUserEvents();
                            }
                        },
                        allLarps: {
                            text: translation.calendar_all_larps,
                            click: () => {
                                getAllEvents();
                            }
                        }
                    }}
                />
            }
            {!loggedIn &&
                <FullCalendar
                    headerToolbar={{
                        start: "",
                        center: "title",
                        end: "prev today next"
                    }}
                    plugins={[daygridPlugin]}
                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                    locale={translation.language}
                    locales={frLocale}
                    events={events}
                    height={"auto"}
                />
            }
        </div>
    );
};

