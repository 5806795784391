import updatedHeaders from "./headers";
import config from "../config";
import updatedHeadersWithIMG from "./headers_img";

export async function add_larp_data(name, description, address, website1, website1Display, website2, website2Display, website3, website3Display, email, file, alcoholAccepted, averagePlayerCount, minAge, maxAge) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("address", address);
    formData.append("website1", website1);
    formData.append("website2", website2);
    formData.append("website3", website3);
    formData.append("website1_display", website1Display);
    formData.append("website2_display", website2Display);
    formData.append("website3_display", website3Display);
    formData.append("email", email);
    formData.append("alcohol_accepted", alcoholAccepted);
    formData.append("average_player_count", averagePlayerCount);
    formData.append("min_age", minAge);
    formData.append("max_age", maxAge);
    if (file) {
        formData.append('files', file, file.name)
        formData.append('file', file, file.name)
        formData.append('filename', file.name)
    }
    const response = await fetch(config.apiUrl + '/larps', {
        method: "POST",
        headers: updatedHeadersWithIMG(),
        body: formData
    })
    return response
}

export async function fetchIsDungeonMaster(larp_id) {
    const response = await fetch(config.apiUrl + '/is_dm/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchIsCreator(larp_id) {
    const response = await fetch(config.apiUrl + '/is_creator/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchLarpData(larp_link_name) {
    const response = await fetch(config.apiUrl + '/larps/' + larp_link_name, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}


export async function fetchLarpFeat(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_display_feats/' + larp_id, {
        method: "POST",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchLarpFeatBySubCat(sub_cat_id) {
    const response = await fetch(config.apiUrl + '/larp_feats/' + sub_cat_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchLarpsData() {
    const response = await fetch(config.apiUrl + '/all_larps', {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function update_larp_data(larp_id, name, description, address, website1, website1display, website2, website2display, website3, website3display, email, customCode, file, points, stats, categories, dms, hexColor, alcoholAccepted, averagePlayerCount, minAge, maxAge, selectedLartTypesId) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("color", hexColor);
    formData.append("address", address);
    formData.append("website1", website1);
    formData.append("website1_display", website1display);
    formData.append("website2", website2);
    formData.append("website2_display", website2display);
    formData.append("website3", website3);
    formData.append("website3_display", website3display);
    formData.append("email", email);
    formData.append("alcohol_accepted", alcoholAccepted);
    formData.append("average_player_count", averagePlayerCount);
    formData.append("min_age", minAge);
    formData.append("max_age", maxAge);
    formData.append("larp_types", selectedLartTypesId);
    formData.append("custom_code", customCode);
    formData.append("points", JSON.stringify(points));
    formData.append("stats", JSON.stringify(stats));
    formData.append("categories", JSON.stringify(categories));
    formData.append("dms", JSON.stringify(dms));
    if (file) {
        formData.append('files', file, file.name)
        formData.append('file', file, file.name)
        formData.append('filename', file.name)
    }
    const response = await fetch(config.apiUrl + '/larps/' + larp_id, {
        method: "PUT",
        headers: updatedHeadersWithIMG(),
        body: formData
    })

    return response
}

export async function larpRequestPublication(larp_id) {
    const response = await fetch(config.apiUrl + '/request_publication/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
    })
    return response
}

export async function larpApprovePublication(larp_id) {
    const response = await fetch(config.apiUrl + '/approve_publication/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
    })
    return response
}

export async function larpUnhide(larp_id) {
    const response = await fetch(config.apiUrl + '/unhide_larp/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
    })
    return response
}

export async function larpRemovePublication(larp_id) {
    const response = await fetch(config.apiUrl + '/remove_publication/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
    })
    return response
}

export async function fetchUserRole() {
    const response = await fetch(config.apiUrl + '/user_role', {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchUserLarps() {
    const response = await fetch(config.apiUrl + '/user_larps', {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchPublicLarps() {
    const response = await fetch(config.apiUrl + '/public_larps', {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function add_larp_link(larp_id) {
    const response = await fetch(config.apiUrl + '/add_user_larp', {
        method: "POST",
        headers: updatedHeaders(),
        body: JSON.stringify({ "larp_id": larp_id })
    })
    return response
}

export async function remove_larp_link(larp_id) {
    const response = await fetch(config.apiUrl + '/remove_larp_link', {
        method: "DELETE",
        headers: updatedHeaders(),
        body: JSON.stringify({ "larp_id": larp_id })
    })
    return response
}

export async function deleteLarp(larp_id) {
    const response = await fetch(config.apiUrl + '/larps/' + larp_id, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function hideLarp(larp_id) {
    const response = await fetch(config.apiUrl + '/hide_larp/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
    })
    return response
}

export async function larpAuthorized(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_authorized/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function addLarpPoint(larp_id) {
    const response = await fetch(config.apiUrl + '/api/point/larp/' + larp_id, {
        method: "POST",
        headers: updatedHeaders()
    })
    return response
}

export async function removeLarpPoint(larp_id, point_id) {
    const response = await fetch(config.apiUrl + `/api/point/${point_id}/larp/${larp_id}`, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function addLarpStat(stat_id) {
    const response = await fetch(config.apiUrl + '/add_larp_stat/' + stat_id, {
        method: "POST",
        headers: updatedHeaders()
    })
    return response
}

export async function removeLarpStat(stat_id) {
    const response = await fetch(config.apiUrl + '/remove_larp_stat/' + stat_id, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function addLarpCat(cat_id) {
    const response = await fetch(config.apiUrl + '/add_larp_cat/' + cat_id, {
        method: "POST",
        headers: updatedHeaders()
    })
    return response
}

export async function removeLarpCatVerify(cat_id) {
    const response = await fetch(config.apiUrl + '/remove_larp_cat_verification/' + cat_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function removeLarpCat(cat_id) {
    const response = await fetch(config.apiUrl + '/remove_larp_cat/' + cat_id, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function addSubCat(cat_id) {
    const response = await fetch(config.apiUrl + '/add_sub_cat/' + cat_id, {
        method: "POST",
        headers: updatedHeaders()
    })
    return response
}

export async function removeSubCatVerify(subCat_id) {
    const response = await fetch(config.apiUrl + '/remove_sub_cat_verification/' + subCat_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function removeSubCat(subCat_id) {
    const response = await fetch(config.apiUrl + '/remove_sub_cat/' + subCat_id, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchLarpCharacters(larp_id) {
    const response = await fetch(config.apiUrl + '/larp_characters/' + larp_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function larpAddGM(larp_id, userEmail) {
    let dmData = {
        "userEmail": userEmail,
    }
    const response = await fetch(config.apiUrl + '/larp_add_gm/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(dmData)
    })
    return response
}

export async function larpRemoveGM(larp_id, user_id) {
    let dmData = {
        "user_id": user_id,
    }
    const response = await fetch(config.apiUrl + '/larp_remove_gm/' + larp_id, {
        method: "DELETE",
        headers: updatedHeaders(),
        body: JSON.stringify(dmData)
    })
    return response
}

export async function fetchLarpTypes() {
    const response = await fetch(config.apiUrl + '/larp_types', {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function addLarpType(priority, french_name, english_name) {
    let larpType = {
        "priority": priority,
        "french_name": french_name,
        "english_name": english_name,
    }
    const response = await fetch(config.apiUrl + '/add_larp_type', {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(larpType)
    })
    return response
}

export async function modifyLarpType(id, priority, french_name, english_name) {
    let larpType = {
        "id": id,
        "priority": priority,
        "french_name": french_name,
        "english_name": english_name,
    }
    const response = await fetch(config.apiUrl + '/modify_larp_type', {
        method: "POST",
        headers: updatedHeaders(),
        body: JSON.stringify(larpType)
    })
    return response
}
