import React, { useEffect, useState } from "react";
import useTranslation from "../components/customHooks/translations";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { BsXCircleFill } from "react-icons/bs";
import { BsFeather } from "react-icons/bs";
import { add_larp_link, fetchPublicLarps, fetchUserLarps, fetchUserRole, remove_larp_link } from "../api/larpAPI";
import { addCharacterData, fetCharacters } from "../api/characterAPI";
import featureFlags from "../utils/FeatureFlags";
import config from "../config";
import customCodes from "../utils/customCodes";
import verifyCustomCodes from "../utils/checkCustomCodes";
import AddNewCharacterDialog from '../components/AddNewCharacterDialog';
import { getSessionToken } from "../utils/SessionTokenUtils";

const MyLarps = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState("USER");
    const [publicLarps, setPublicLarps] = useState([]);
    const [larps, setLarp] = useState([]);
    const [characterName, setCharacterName] = useState("");
    const [selectedLarp, setSelectedLarp] = useState("");
    const [characters, setCharacters] = useState([]);

    const [registerLarpShow, setRegisterLarpShow] = useState(false);
    const handleRegisterLarpClose = () => setRegisterLarpShow(false);
    const handleRegisterLarpShow = () => setRegisterLarpShow(true);

    const [addCharacterShow, setAddCharacterShow] = useState(false);
    const handleAddCharacterClose = () => setAddCharacterShow(false);
    const handleAddCharacterShow = (larp_id) => {
        setAddCharacterShow(true);
        setSelectedLarp(larp_id);
    }

    async function getUserRole() {
        const response = await fetchUserRole()
        if (response.ok) {
            let user_response = await response.json();
            setUserRole(user_response.role)
        }
    }

    async function getUserLarps() {
        const response = await fetchUserLarps()
        if (response.ok) {
            let larp_response = await response.json();
            setLarp(larp_response)
        }
    }

    async function gePublicLarps() {
        const response = await fetchPublicLarps()
        if (response.ok) {
            let larp_response = await response.json();
            setPublicLarps(larp_response.larps)
        }
    }
    async function addLarp(id) {
        const response = await add_larp_link(id)
        if (response.ok) {
            getUserLarps()
        }
    }
    async function removeLarpLink(id) {
        const response = await remove_larp_link(id)
        if (response.ok) {
            getUserLarps()
        }
    }

    async function getCharacters() {
        const response = await fetCharacters()
        if (response.ok) {
            let user_characters = await response.json();
            setCharacters(user_characters)
        }
    }

    async function addCharacter() {
        if (!characterName) {
            alert(translation.my_character_invalid_name);
            return;
        }
        const response = await addCharacterData(characterName, selectedLarp)
        if (response.ok) {
            getCharacters()
            setCharacterName("")
            handleAddCharacterClose()
        }
    }

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        } else {
            getUserLarps()
            gePublicLarps()
            getCharacters()
            getUserRole()
        }
    }, []);

    function displayLarp(larp) {
        if (larp.status === "PUBLISHED")
            navigate("/larp/" + larp.linkName)
        else
            navigate("/larp/" + larp.larp_id)
    }

    return (
        <div className="page_content">
            <div className="restrained-section">
                {larps.map((larp) =>
                    <div className="full-display" key={larp.larp_id}>
                        <div className="larp-display">
                            <div>
                                {larp.image_path ?
                                    <img className="larp-img-small pointer" onClick={() => { navigate("/larp/" + larp.larp_id) }} src={config.apiUrl + "/image-uploads/" + larp.image_path} />
                                    :
                                    <img className="larp-img-small pointer" onClick={() => { navigate("/larp/" + larp.larp_id) }} src={config.apiUrl + config.default_larp_image} />
                                }
                                <span className="my-larp-display-name" onClick={() => { displayLarp(larp) }}>{larp.name} </span>
                            </div>
                            <div className="hor-flex">
                                {larp.status === "LOCAL" &&
                                    <div className="space-small align-center">{translation.larp_status_local_display}</div>
                                }
                                {larp.creator && (verifyCustomCodes(larp.customCode, customCodes.complexeLarpFeature) || featureFlags.complexeLarpFeature.value) &&
                                    <>
                                        <Link className="btn btn-outline-secondary space-small" to={"/update-larp-feat/" + larp.larp_id}>{translation.feat_button}</Link>
                                        <Link className="btn btn-outline-secondary space-small" to={"/update-larp-player/" + larp.larp_id}>{translation.player_button}</Link>
                                    </>
                                }
                                {larp.creator &&
                                    <>
                                        <Link className="btn btn-outline-secondary space-small" to={"/update-larp-article/" + larp.larp_id}>{translation.article_button}</Link>
                                        <Link className="btn btn-outline-secondary space-small" to={"/update-larp-event/" + larp.larp_id}>{translation.event_button}</Link>
                                        <Link className="btn btn-outline-secondary space-small" to={"/update-larp/" + larp.larp_id}>{translation.my_larps_update_button}</Link>
                                    </>
                                }
                                {!larp.creator &&
                                    <BsXCircleFill className="space-small pointer" color="DarkRed" size={25} onClick={() => { removeLarpLink(larp.larp_id) }} />
                                }
                            </div>
                        </div>
                        {(featureFlags.characterCreationFeature.value || verifyCustomCodes(larp.customCode, customCodes.characterCreationFeature)) &&
                            <>
                                <div>
                                    {characters.filter((character) => character.larp_id === larp.larp_id).map((character) =>
                                        <div className="character-display" key={character.id}>
                                            <span onClick={() => { navigate("/character/" + character.id) }}>{character.name} </span>
                                            <BsFeather className="space-small pointer" size={25} onClick={() => { navigate("/update-character/" + character.id) }} />
                                        </div>
                                    )}
                                </div>
                                <Link className="btn btn-outline-secondary add-character-button" onClick={() => handleAddCharacterShow(larp.larp_id)}>{translation.my_larps_add_character_button}</Link>
                            </>
                        }

                    </div>
                )}
                {(featureFlags.larpCreationFeature.value || userRole !== "USER") &&
                    <Link className="btn btn-dark" to="/createlarp">{translation.create_larp_button}</Link>
                }
                {featureFlags.registerLarp.value &&
                    <Link className="btn btn-dark space-small" onClick={handleRegisterLarpShow}>{translation.register_existing_larp_button}</Link>
                }

                <Modal show={registerLarpShow} onHide={handleRegisterLarpClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translation.my_larps_register_larp_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {publicLarps.map((larp) => {
                            let isAdded = larps.filter(e => e.larp_id === larp.id).length > 0;
                            return (<div className="larp-display" key={larp.id}>
                                <span onClick={() => { navigate("/larp/" + larp.id) }}>{larp.name} </span>
                                {isAdded &&
                                    <Link className="btn btn-outline-secondary" readOnly>{translation.my_larps_already_added}</Link>
                                }
                                {!isAdded &&
                                    <Link className="btn btn-outline-secondary" onClick={() => { addLarp(larp.id) }}>{translation.my_larps_add_button}</Link>
                                }
                            </div>)
                        })}
                    </Modal.Body>
                </Modal>
                {addCharacterShow &&
                    <AddNewCharacterDialog
                        lblTitle={translation.my_larps_add_character_title}
                        lblAdd={translation.my_larps_modal_add_button}
                        lblClose={translation.my_larps_modal_close_button}
                        actionConfirm={addCharacter}
                        actionShow={handleAddCharacterShow}
                        actionHide={handleAddCharacterClose}
                        actionOnChange={setCharacterName}
                        varCharName={characterName}
                    />
                }
            </div>
        </div>
    );
};

export default MyLarps;
