import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useTranslation from "../components/customHooks/translations";
import { log_out } from "../redux/UserHandler";
import { fetchUser, resetPassword, updateUser } from "../api/userAPI";
import { getSessionToken, removeSessionToken } from "../utils/SessionTokenUtils";

export default function Account() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lasttName, setLastName] = useState([]);
  const [logsOrder, setLogsOrder] = useState([]);
  const [resetConfirmation, setResetConfirmation] = useState("");

  async function getUser() {
    const response = await fetchUser()
    if (response.ok) {
      let users_response = await response.json();
      setUser(users_response)
      setFirstName(users_response.first_name)
      setLastName(users_response.last_name)
      setLogsOrder(users_response.logs_order)
    }
  }

  useEffect(() => {
    if (getSessionToken() === null) {
      navigate("/login");
    } else {
      getUser();
    }
  }, [navigate]);

  async function updateUserInfo() {
    const response = await updateUser(user.id, firstName, lasttName, logsOrder)
    if (response.ok) {
      getUser();
    }
  }

  async function reset() {
    let response = await resetPassword(user.email, translation.language)
    if (response.ok)
      setResetConfirmation(translation.account_reset_confirmation)
    else
      setResetConfirmation(translation.pass_rec_message_unknown)
  }

  function userDisconnect() {
    removeSessionToken();
    dispatch(log_out())
    navigate("/login");
  }

  const translation = useTranslation();
  return (
    <div className="page_content">
      <div className="restrained-section">
        <h1>{translation.account_welcome} {user.first_name}  {user.last_name}</h1>
        <form >
          <div className="display-flex">
            <div className="form-group flex space-small">
              <label>{translation.account_first_name}:</label>
              <input type="text" className="form-control" onChange={(e) => { setFirstName(e.target.value) }} value={firstName} />
            </div>
            <div className="form-group flex space-small">
              <label>{translation.account_last_name}:</label>
              <input type="text" className="form-control" onChange={(e) => { setLastName(e.target.value) }} value={lasttName} />
            </div>
          </div>
          <div className="form-group flex">
            <label>{translation.account_email}:</label>
            <span className="flex space-small">{user.email}</span>
          </div>
          <h1>{translation.account_preferences}</h1>
          <div>
            <label>
              <input type="checkbox" onChange={(e) => { setLogsOrder(!logsOrder) }} checked={logsOrder}></input>
              {" " + translation.account_logs_order}
            </label>
          </div>
        </form >
        <button type="button" className="btn btn-dark space-medium" onClick={async () => { updateUserInfo() }}>{translation.larp_save_button}</button>
        <button type="button" className="btn btn-dark space-medium" onClick={async () => { reset() }}>{translation.account_reset}</button>
        <span>{resetConfirmation}</span>
        <button type="button" className="btn btn-dark space-medium" onClick={async () => { userDisconnect() }}>
          {translation.my_account_disconnect}
        </button>
      </div >
    </div >
  );
};
