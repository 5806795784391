import React, { useEffect, useState } from "react";
import "./larp.css"
import { fetchLarpFeat } from "../../api/larpAPI";
import FeatComponent from "../../components/FeatComponent";
import SafeHtmlContent from "../../components/SafeHtmlContent";


const LarpCategoriesTab = (props) => {
    const [activeTab, setActiveTab] = useState();
    const [subActiveTab, setSubActiveTab] = useState();
    const [initialSetup, setInitialSetup] = useState(false);
    const [groupedBySubCategory, setGroupedBySubCategory] = useState(false);

    if (!initialSetup && props.categories && props.categories.length) {
        setActiveTab(props.categories[0].id);
        if (props.categories[0].sub_categories.length > 0)
            setSubActiveTab(props.categories[0].sub_categories[0].id);
        setInitialSetup(true);
    }

    const fetchFeats = async () => {
        const response = await fetchLarpFeat(props.larp_id)
        if (response.ok) {
            let feat_response = await response.json();
            const groupedBySubCategory = feat_response.reduce((map, feat) => {
                for (const cost of feat.costs) {
                    const cat_sub_Cat_key = cost.category_id + "-" + cost.sub_category_id;
                    if (!map.has(cat_sub_Cat_key)) {
                        map.set(cat_sub_Cat_key, [feat]);
                    } else {
                        map.get(cat_sub_Cat_key).push(feat);
                    }
                }
                return map;
            }, new Map());

            setGroupedBySubCategory(groupedBySubCategory);
        }
    }
    function SortByCost(subCategoryId) {
        return function (a, b) {
            var aCost = a.costs.find(x => x.sub_category_id === subCategoryId);
            var bCost = b.costs.find(x => x.sub_category_id === subCategoryId);
            if (aCost.amount > bCost.amount) {
                return 1;
            }
            if ((bCost.amount > aCost.amount)) {
                return -1;
            }
            return 0;
        }
    }

    function SortByAcquired(subCategoryId) {
        return function (a, b) {
            var aCost = a.costs.find(x => x.sub_category_id === subCategoryId);
            var bCost = b.costs.find(x => x.sub_category_id === subCategoryId);
            if (aCost.acquired_select < bCost.acquired_select) {
                return 1;
            }
            if ((bCost.acquired_select < aCost.acquired_select)) {
                return -1;
            }
            return 0;
        }
    }

    useEffect(() => {
        fetchFeats().catch(console.error);
    }, []);

    return (
        <div className="hor-flex fill">
            <div className="fill">
                <ul className="tab-titles">
                    {props.categories && props.categories.filter(x => !x.isHidden && !x.displayHidden && x.sub_categories.filter(y => !y.isHidden || props.isDm).length > 0).map(category => (
                        <li key={category.id}
                            className={activeTab === category.id ? 'active' : ''}
                            onClick={() => { setActiveTab(category.id); setSubActiveTab(category.sub_categories[0].id); }}>
                            <h3>{category.name}</h3>
                        </li>
                    ))}
                </ul>
                <hr />
                <div className="tab-content">
                    {props.categories && props.categories.filter(x => !x.isHidden).map(category => {
                        if (category.id === activeTab) {
                            return (
                                <div key={category.id}>
                                    <div>
                                        <ul className="tab-titles">
                                            {category.sub_categories.filter(x => !x.isHidden || props.isDm).map(sub_category => (
                                                <li key={sub_category.id}
                                                    className={subActiveTab === sub_category.id ? sub_category.isHidden ? "hidden-sub-active" : 'sub-active' : sub_category.isHidden ? 'hidden-cat' : ""}
                                                    onClick={() => setSubActiveTab(sub_category.id)}>
                                                    <h4>{sub_category.name}</h4>
                                                </li>
                                            ))}
                                        </ul>
                                        <hr />
                                        <div className="tab-content">
                                            {category.sub_categories
                                                .map(sub_category => {
                                                    if (groupedBySubCategory && sub_category.id === subActiveTab) {
                                                        return (
                                                            <div key={sub_category.id}>
                                                                <SafeHtmlContent htmlContent={sub_category.description} />
                                                                {groupedBySubCategory.get(category.id + "-" + sub_category.id) &&
                                                                    groupedBySubCategory.get(category.id + "-" + sub_category.id)
                                                                        .sort(SortByCost(sub_category.id))
                                                                        .sort(SortByAcquired(sub_category.id))
                                                                        .map(feat => {
                                                                            const status = (sub_category.isHidden || feat.isHidden) && props.isDm ? "available-hidden" : "display";
                                                                            if (!feat.isHidden || props.isDm) {
                                                                                return (<FeatComponent key={feat.id} status={status} cat_id={category.id} feat_id={feat.id} feat={feat} displayMax displayedSubCat={sub_category.id} />)
                                                                            }
                                                                        })
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default LarpCategoriesTab;
