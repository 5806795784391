import React, { useState, useEffect } from 'react';
import useTranslation from './customHooks/translations';
import Button from 'react-bootstrap/esm/Button';
import featureFlags from '../utils/FeatureFlags';

const ArticleGroupForm = ({ article_group, article_groups, onCreate, onUpdate }) => {
    const translation = useTranslation();
    let nextPriority = 1
    article_groups.map((article_group) => {
        if (article_group.priority >= nextPriority) {
            nextPriority = article_group.priority + 1
        }
    })
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        priority: nextPriority,
        isHidden: false,
        isTimeline: false,
    });

    useEffect(() => {
        if (article_group) {
            setFormData({
                id: article_group.id || '',
                name: article_group.name || '',
                priority: article_group.priority || 1,
                isHidden: article_group.isHidden || false,
                isTimeline: article_group.isTimeline || false,
            });
        }
    }, [article_group]);

    const handleChange = (e) => {
        if (e.target.type === "checkbox")
            setFormData({ ...formData, [e.target.name]: e.target.checked });
        else
            setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <form>
                <div className="hor-flex">
                    <label>{translation.article_name_label}:</label>
                    <input type="text" name="name" className="form-control space-small" value={formData.name} onChange={handleChange} maxLength="50" />
                    <label>{translation.article_priority_label}:</label>
                    <input type="number" min="1" name="priority" className="form-control space-small input-small" value={formData.priority} onChange={handleChange} maxLength="50" />
                </div>
                <div className="hor-flex">
                    <label>{translation.article_hidden_label}: </label>
                    <input type="checkbox" name="isHidden" onChange={handleChange} checked={formData.isHidden} />
                </div>
                {featureFlags.articlesWithTimeline.value &&
                    <div className="hor-flex">
                        <label>{translation.article_timeline_label}: </label>
                        <input type="checkbox" name="isTimeline" onChange={handleChange} checked={formData.isTimeline} />
                    </div>
                }
            </form>
            <Button variant="secondary" onClick={() => article_group !== null ? onUpdate(formData) : onCreate(formData)}>
                {article_group !== null ? translation.event_save_button : translation.event_create_button}
            </Button>
        </>
    );
};

export default ArticleGroupForm;
