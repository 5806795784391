import React from "react";
import EmailVerificationForm from "../components/EmailVerificationForm";

export default function EmailVerification() {
    return (
        <>
            <EmailVerificationForm />
        </>
    );
};
