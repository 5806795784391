import React from "react";
import { useSelector } from 'react-redux';
import useTranslation from "./customHooks/translations";
import { Link } from "react-router-dom";
import { BsBookmarkStarFill, BsBookmarkStar } from "react-icons/bs";
import config from "../config";

const SimpleLarp = ({ larp, isDm, isLarpFavorite, toggleLarpFavorite }) => {
    const translation = useTranslation();
    const loggedIn = useSelector(state => state.user.loggedIn);
    return (
        <div className="larp-info">
            <div className="header-restrained-section hor-flex">
                <div className="ver-flex">
                    {larp.imagePath ?
                        <img className="larp-img" src={config.apiUrl + "/image-uploads/" + larp.imagePath} />
                        :
                        <img className="larp-img" src={config.apiUrl + "/default-image/larp-default.png"} />
                    }

                    {loggedIn && !isDm &&
                        <Link
                            className="btn btn-outline-secondary"
                            to="#"
                            onClick={toggleLarpFavorite}
                        >
                            {isLarpFavorite ? (
                                <>
                                    <BsBookmarkStarFill />
                                    {' ' + translation.larp_remove_favorite}
                                </>
                            ) : (
                                <>
                                    <BsBookmarkStar />
                                    {' ' + translation.larp_add_favorite}
                                </>
                            )}
                        </Link>
                    }
                    {!loggedIn &&
                        <Link
                            className="btn btn-outline-secondary"
                            to="/login"
                        >
                            <>
                                <BsBookmarkStar />
                                {' ' + translation.larp_add_favorite}
                            </>
                        </Link>
                    }
                </div>
                <div className="larp-content">
                    <div className="display-field">
                        <label>{translation.larp_label_name}: </label>
                        <span>{larp.larp_name}</span>
                    </div>
                    <div className="display-field">
                        <label>{translation.larp_label_address}: </label>
                        <span>{larp.address}</span>
                    </div>
                    {larp.website &&
                        <div className="display-field">
                            <label>{translation.larp_label_website}: </label>
                            <Link to={larp.website}>{larp.website_display}</Link>
                        </div>
                    }
                    {larp.website2 &&
                        <div className="display-field">
                            <label>{translation.larp_label_website2}: </label>
                            <Link to={larp.website2}>{larp.website2_display}</Link>
                        </div>
                    }
                    {larp.website3 &&
                        <div className="display-field">
                            <label>{translation.larp_label_website3}: </label>
                            <Link to={larp.website3}>{larp.website3_display}</Link>
                        </div>
                    }
                    <div className="display-field">
                        <label>{translation.larp_label_email}: </label>
                        <span>{larp.email}</span>
                    </div>
                    <div className="display-field">
                        <label>{translation.larp_label_alcohol_accepted}: </label>
                        <span>{(larp.alcoholAccepted) ? translation.larp_label_alcohol_accepted_yes : translation.larp_label_alcohol_accepted_no}</span>
                    </div>
                    <div className="display-field">
                        <label>{translation.larp_label_average_player_count}: </label>
                        <span>{larp.averagePlayerCount}</span>
                    </div>
                    <div className="display-field">
                        <label>{translation.larp_label_min_age}: </label>
                        <span>{larp.minAge}</span>
                    </div>
                    <div className="display-field">
                        <label>{translation.larp_label_max_age}: </label>
                        <span>{larp.maxAge}</span>
                    </div>
                </div>
            </div>
            <div className="space-medium">
                {larp.dms && larp.dms.filter(x => !x.isHidden).length > 0 &&
                    <>
                        <h3>{translation.larp_game_masters}: </h3>
                        {larp.dms && larp.dms.filter(x => !x.isHidden).map((dm) =>
                            <span className="display-dms" key={dm.id}>{dm.first_name} {dm.last_name}: {dm.email}</span>
                        )}
                    </>
                }
            </div>
            <div className="display-field">
                <label>{translation.larp_label_description}: </label>
                <span>{larp.description}</span>
            </div>
        </div>
    );
};

export default SimpleLarp;
