import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchLarpsData, larpApprovePublication, larpRemovePublication, larpUnhide } from "../../api/larpAPI";
import useTranslation from "../../components/customHooks/translations";
import config from "../../config";
import { getSessionToken } from "../../utils/SessionTokenUtils";

const LarpAdmin = () => {
    const translation = useTranslation();
    const navigate = useNavigate();
    const [larps, setLarps] = useState([]);
    const [filterText, setFilterText] = useState("")

    async function getLarps() {
        const response = await fetchLarpsData()
        if (response.ok) {
            let larps_response = await response.json();
            setLarps(larps_response)
        }
    }

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        } else {
            getLarps()
        }
    }, []);

    async function approve_publication(id) {
        const response = await larpApprovePublication(id)
        if (response.ok)
            getLarps()
        else if (response.status === 403)
            alert(translation.larp_publish_existing_error)
    }

    async function remove_publication(id) {
        const response = await larpRemovePublication(id)
        if (response.ok)
            getLarps()
        else if (response.status === 403)
            alert(translation.larp_publish_existing_error)
    }

    async function unhide(id) {
        const response = await larpUnhide(id)
        getLarps()
    }

    let filteredLarps = larps;

    if (filterText !== "") {
        const search = filterText.toLowerCase()
        filteredLarps = filteredLarps.filter(x => x.name.toLowerCase().includes(search)
            || x.link_name.toLowerCase().includes(search)
            || x.user_email.toLowerCase().includes(search));
    }

    return (
        <div className="page_content">
            <div className="hor-flex">
                <label>{translation.feat_filter_label}:</label>
                <input type="text" className="form-control space-small input-medium" value={filterText} onChange={(e) => { setFilterText(e.target.value) }} />
            </div>
            <div className="restrained-section">
                {filteredLarps.map((larp) =>
                    <div className="full-display" key={larp.id}>
                        <div className="admin-list-display">
                            {larp.image_path ?
                                <img className="larp-img-small pointer" onClick={() => { navigate("/larp/" + larp.id) }} src={config.apiUrl + "/image-uploads/" + larp.image_path} />
                                :
                                <img className="larp-img-small pointer" onClick={() => { navigate("/larp/" + larp.id) }} src={config.apiUrl + config.default_larp_image} />
                            }
                            <span className="my-larp-display-name" onClick={() => { navigate("/larp/" + larp.id) }}>{larp.name}</span>
                            <span>{larp.user_email}</span>
                            {larp.status === "LOCAL" &&
                                <span className="space-medium">{translation.larp_status_local}</span>
                            }
                            {larp.status === "PENDING" &&
                                <div>
                                    <button type="button" className="btn btn-dark space-medium" onClick={async () => { approve_publication(larp.id) }}>{translation.larp_approve_button}</button>
                                </div>
                            }
                            {larp.status === "PUBLISHED" &&
                                <div>
                                    <button type="button" className="btn btn-danger space-medium" onClick={async () => { remove_publication(larp.id) }}>{translation.larp_status_remove_published}</button>
                                </div>
                            }
                            {larp.is_active == false &&
                                <button type="button" className="btn btn-dark space-medium" onClick={async () => { unhide(larp.id) }}>{translation.larp_unhide}</button>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LarpAdmin;
