import React from "react";
import VerifiedPasswordRecoveryForm from "../components/VerifiedPasswordRecoveryForm";

export default function VerifiedPasswordRecovery() {
    return (
        <>
            <VerifiedPasswordRecoveryForm />
        </>
    );
};
