const getCharacterLimitations = (characterFeats) => {
    var arrayLimitations = [];
    characterFeats.forEach(feat => {
        if (arrayLimitations.indexOf(feat.limitation) === -1 && feat.limitation) {
            arrayLimitations.push(feat.limitation);
            arrayLimitations.push(", ");
        }
    });
    arrayLimitations.pop();

    return arrayLimitations;
}

export { getCharacterLimitations }
