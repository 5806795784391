import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmDialog = ({ lblTitle, lblDesc, lblYes, lblNo, actionConfirm, actionShow, actionHide }) => {
    return (
        <Modal autoFocus={false} show={actionShow} onHide={actionHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{lblTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {lblDesc}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={actionHide}>
                    {lblNo}
                </Button>
                <Button variant="primary" onClick={actionConfirm}>
                    {lblYes}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDialog;
