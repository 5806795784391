import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUsers, updateUserRole } from "../../api/userAPI";
import useTranslation from "../../components/customHooks/translations";
import ConfirmDialogTextCopy from "../../components/ConfirmDialogTextCopy";
import { deleteUser } from '../../api/userAPI';
import { getSessionToken } from "../../utils/SessionTokenUtils";

const UserAdmin = () => {
    const navigate = useNavigate();
    const translation = useTranslation();
    const [users, setUser] = useState([]);
    const [filterText, setFilterText] = useState("")
    const [confirmDialogShow, setConfirmDialogShow] = useState(false);
    const [confirmEmail, setConfirmEmail] = useState("");
    const [deleteId, setDeleteId] = useState(0);
    const [confirmedEmail, setConfirmedEmail] = useState("");

    async function getUsers() {
        const response = await fetchUsers()
        if (response.ok) {
            let users_response = await response.json();
            setUser(users_response)
        }
    }

    const onConfirmDialogClose = () => setConfirmDialogShow(false);
    const onConfirmDialogShow = (user) => {
        setConfirmEmail(user.email);
        setConfirmDialogShow(true);
        setDeleteId(user.id);
    }

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        } else {
            getUsers()
        }
    }, []);

    async function delete_user() {
        if (confirmEmail == confirmedEmail) {
            const response = await deleteUser(deleteId);
            if (response.ok) {
                getUsers();
            }
        }
        else {
            alert(translation.dashboard_error_email_confirm);
        }
        onConfirmDialogClose();
    }

    async function setUserRole(user_id, value) {
        const response = await updateUserRole(user_id, value)
        getUsers()
    }

    let filteredUsers = users;

    if (filterText !== "") {
        const search = filterText.toLowerCase()
        filteredUsers = filteredUsers.filter(x => x.email.toLowerCase().includes(search));
    }

    return (
        <div className="page_content">
            <div className="hor-flex">
                <label>{translation.feat_filter_label}:</label>
                <input type="text" className="form-control space-small input-medium" value={filterText} onChange={(e) => { setFilterText(e.target.value) }} />
            </div>
            <div className="restrained-section">
                {filteredUsers.map((user) =>
                    <div className="full-display" key={user.id}>
                        <div className="user-display">
                            <span>{user.email} </span>
                            {user.verified &&
                                <span>{translation.dashboard_verified}</span>
                            }
                            {!user.verified &&
                                <span>{translation.dashboard_notverified}</span>
                            }
                            <span>{user.verified} </span>
                            <select onChange={(e) => setUserRole(user.id, e.target.value)} value={user.role}>
                                <option value="USER">{translation.dashboard_user}</option>
                                <option value="TESTER">{translation.dashboard_tester}</option>
                                <option value="ADMIN">{translation.dashboard_admin}</option>
                            </select>
                            <button type="button" className="btn btn-danger space-small" onClick={() => onConfirmDialogShow(user)}>{translation.char_table_delete_button}</button>
                        </div>
                    </div>
                )}
                {confirmDialogShow &&
                    <ConfirmDialogTextCopy
                        actionConfirm={delete_user}
                        actionShow={onConfirmDialogShow}
                        actionHide={onConfirmDialogClose}
                        actionOnChange={setConfirmedEmail}
                    />
                }
            </div>
        </div>
    );
};

export default UserAdmin;
