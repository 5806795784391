import updatedHeaders from "./headers";
import config from "../config";

export async function userLogin(email, password) {
  let loginInfo = {
    "email": email,
    "password": password
  };
  const response = await fetch(config.apiUrl + '/auth', {
    method: "POST",
    headers: updatedHeaders(),
    body: JSON.stringify(loginInfo)
  })
  return response
}

export async function fetchIsAdmin() {
  const response = await fetch(config.apiUrl + '/is_admin', {
    method: "GET",
    headers: updatedHeaders()
  })
  return response
}

export async function fetchUser() {
  const response = await fetch(config.apiUrl + '/user', {
    method: "GET",
    headers: updatedHeaders()
  })
  return response
}

export async function fetchUsers() {
  const response = await fetch(config.apiUrl + '/users', {
    method: "GET",
    headers: updatedHeaders()
  })
  return response
}

export async function updateUser(user_id, first_name, last_name, logs_order) {
  let userInfo = {
    "first_name": first_name,
    "last_name": last_name,
    "logs_order": logs_order
  };
  const response = await fetch(config.apiUrl + '/users/' + user_id, {
    method: "PUT",
    headers: updatedHeaders(),
    body: JSON.stringify(userInfo)
  })
  return response
}


export async function updateUserRole(user_id, value) {
  let userInfo = {
    "user_id": user_id,
    "role": value
  };
  const response = await fetch(config.apiUrl + '/update_user_role', {
    method: "PUT",
    headers: updatedHeaders(),
    body: JSON.stringify(userInfo)
  })
  return response
}

export async function contactUs(from, email, message) {
  let userInfo = {
    "from": from,
    "email": email,
    "message": message
  };
  const response = await fetch(config.apiUrl + '/contact_us', {
    method: "POST",
    headers: updatedHeaders(),
    body: JSON.stringify(userInfo)
  })
  return response
}

export async function resetPassword(emailValue, language) {
  let userInfo = {
    "email": emailValue,
    "language": language
  };
  const response = await fetch(config.apiUrl + '/password_recovery', {
    method: "POST",
    headers: updatedHeaders(),
    body: JSON.stringify(userInfo)
  })
  return response
}

export async function verifiedPasswordReset(userEmail, userToken, passwordValue) {
  let userInfo = {
    "email": userEmail,
    "token": userToken,
    "password": passwordValue
  };
  const response = await fetch(config.apiUrl + '/verified_password_recovery', {
    method: "POST",
    headers: updatedHeaders(),
    body: JSON.stringify(userInfo)
  })
  return response
}

export async function signUpUser(firstName, lastName, email, password, language) {
  let userInfo = {
    "first_name": firstName,
    "last_name": lastName,
    "email": email,
    "password": password,
    "language": language
  };
  const response = await fetch(config.apiUrl + '/users', {
    method: "POST",
    headers: updatedHeaders(),
    body: JSON.stringify(userInfo)
  })
  return response
}

export async function emailVerification(userEmail, userVerificationToken) {
  let userInfo = {
    "email": userEmail,
    "token": userVerificationToken,
  };
  const response = await fetch(config.apiUrl + '/email_verification', {
    method: "POST",
    headers: updatedHeaders(),
    body: JSON.stringify(userInfo)
  })
  return response
}

export async function deleteUser(id) {
  const response = await fetch(config.apiUrl + '/users/' + id, {
    method: "DELETE",
    headers: updatedHeaders()
  })
  return response
}
