import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedIn: false
  },
  reducers: {
    log_in: (state) => {
      state.loggedIn = true
    },
    log_out: (state) => {
      state.loggedIn = false
    },
  },
})

export const { log_in, log_out } = userSlice.actions

export default userSlice.reducer
