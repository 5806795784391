import React from "react";
import useTranslation from "../components/customHooks/translations";
import Map from "../components/MapSearch";

export default function Home() {
    const translation = useTranslation();
    return (
        <>
            <Map />
        </>
    );
};
