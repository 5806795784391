import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ArticleList from "../Article/UpdateArticle";
import { fetchLarpArticleGroups, fetchLarpArticles } from "../../api/articleAPI";
import { getSessionToken } from "../../utils/SessionTokenUtils";

export default function UpdateLarpArticle() {
    const { id } = useParams()
    let navigate = useNavigate()
    const [articles, setArticles] = useState([]);
    const [articleGroups, setArticleGroups] = useState([]);

    async function updateDate() {
        getLarpArticles()
        getLarpArticleGroups()
    }

    async function getLarpArticles() {
        const response = await fetchLarpArticles(id)
        if (response.ok) {
            let larp_aricles_response = await response.json();
            setArticles(larp_aricles_response)
        }
    }

    async function getLarpArticleGroups() {
        const response = await fetchLarpArticleGroups(id)
        if (response.ok) {
            let larp_aricle_groups_response = await response.json();
            setArticleGroups(larp_aricle_groups_response)
        }
    }

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        }
        getLarpArticles()
        getLarpArticleGroups()
    }, [])

    return <ArticleList articles={articles} article_groups={articleGroups} larp_id={id} onUpdateList={updateDate} />;
};
