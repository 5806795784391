import React from "react";
import useTranslation from "./customHooks/translations";

const CharacterPoints = ({ characterPoints }) => {
    const translation = useTranslation();
    return (
        <>
            {
                characterPoints.map((point) =>
                    <div className="hor-flex point-display" key={point.id}>
                        <span className="space-small bold">{point.remaining}/{point.total}</span>
                        <label>{point.point_name} {translation.points_total} ({point.point_alias})</label>
                    </div>
                )
            }
        </>
    );
};

export default CharacterPoints;
