import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const AddNewCharacterDialog = ({ lblTitle, lblAdd, lblClose, actionConfirm, actionShow, actionHide, actionOnChange, varCharName }) => {
    return (
        <Modal autoFocus={false} show={actionShow} onHide={actionHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{lblTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input autoFocus={true} type="text" className="form-control" onChange={(e) => { actionOnChange(e.target.value) }} value={varCharName} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={actionHide}>
                    {lblClose}
                </Button>
                <Button variant="primary" onClick={actionConfirm}>
                    {lblAdd}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddNewCharacterDialog;
