import SafeHtmlContent from "./SafeHtmlContent";
import useTranslation from "./customHooks/translations";

export default function FeatComponent({ cat_id, status, feat, onClick, displayMax, displayedSubCat }) {
    const translation = useTranslation();
    let statusClass = "status-";
    if ((feat.cost && feat.cost.acquired_select) || feat.cost_point_id === -1) {
        statusClass = statusClass + "acquired-select";
    }
    else if (feat.costs) {
        var cost = feat.costs.find(x => x.sub_category_id === displayedSubCat);
        statusClass = statusClass + (cost.acquired_select ? "acquired-select" : status)
    }
    else {
        statusClass = statusClass + status;
    }
    return (
        <div className="hor-flex fill ver-center">
            {!displayMax && feat.max_select > 1 &&
                <span className="max-select-display">{feat.time_select} X</span>
            }
            <div className={"feat-display space-small " + statusClass} onClick={() => onClick && onClick(cat_id, feat)}>
                <div className="hor-flex">
                    {displayedSubCat === undefined && (((feat.cost && feat.cost.acquired_select) || feat.cost_point_id === -1) ?
                        <div className="feat-field">
                            <label>{translation.char_feat_aquired_on_select}</label>
                        </div>
                        :
                        <div className="feat-field">
                            <label>{translation.char_feat_cost}:</label>
                            <span>{feat.cost_value}</span>
                        </div>
                    )}
                    {displayedSubCat && feat.costs.filter(x => x.sub_category_id === displayedSubCat).map((cost) =>
                        <div key={cost.id} className="feat-field">
                            {cost.acquired_select ?
                                <label>{translation.char_feat_aquired_on_select}</label>
                                : <>
                                    <label>{translation.char_feat_cost}:</label>
                                    <span>{cost.amount} {cost.point_alias}</span>
                                </>
                            }
                        </div>
                    )}

                    <div className="feat-field">
                        <label>{translation.char_feat_name}:</label>
                        <span>{feat.name}</span>
                    </div>
                    {displayMax && feat.max_select > 1 &&
                        <div className="feat-field">
                            <label>{translation.char_feat_max_sel}:</label>
                            <span>{feat.max_select}</span>
                        </div>
                    }
                    {feat.requirement &&
                        <div className="feat-field">
                            <label>{translation.feat_requirement}:</label>
                            <span>{feat.requirement}</span>
                        </div>
                    }
                    {feat.limitation &&
                        <div className="feat-field">
                            <label>{translation.feat_limitations}:</label>
                            <span>{feat.limitation}</span>
                        </div>
                    }
                </div>
                <div className="hor-flex">
                    <SafeHtmlContent htmlContent={feat.description} />
                </div>
            </div>
        </div>
    )
}
