import updatedHeaders from "./headers";
import config from "../config";

export async function fetCharacters() {
    const response = await fetch(config.apiUrl + '/characters', {
        method: "POST",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchCharacterData(character_id) {
    const response = await fetch(config.apiUrl + '/characters/' + character_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function addCharacterData(name, larp_id) {
    let characterInfo = {
        "name": name,
        "larp_id": larp_id,
    };
    const response = await fetch(config.apiUrl + '/add_characters', {
        method: "POST",
        headers: updatedHeaders(),
        body: JSON.stringify(characterInfo)
    })
    return response
}

export async function updateCharacter(character_id, name, backstory, notes, gmNotes, characterCategories, aquiredFeat, is_npc) {
    let characterInfo = {
        "name": name,
        "backstory": backstory,
        "notes": notes,
        "gmNotes": gmNotes,
        "character_categories": characterCategories,
        "aquiredFeat": Object.fromEntries(aquiredFeat),
        "is_npc": is_npc
    };
    const response = await fetch(config.apiUrl + '/characters/' + character_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(characterInfo)
    })
    return response
}

export async function transferCharacter(character_id, email) {
    let characterInfo = {
        "email": email
    };
    const response = await fetch(config.apiUrl + '/transfer_character/' + character_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(characterInfo)
    })
    return response
}

export async function removeCharacterFeat(character_id, category_id, feat_id) {
    let characterInfo = {
        "category_id": category_id,
        "feat_id": feat_id,
    };
    const response = await fetch(config.apiUrl + '/remove_character_feat/' + character_id, {
        method: "DELETE",
        headers: updatedHeaders(),
        body: JSON.stringify(characterInfo)
    })
    return response
}

export async function deleteCharacter(character_id) {
    const response = await fetch(config.apiUrl + '/characters/' + character_id, {
        method: "DELETE",
        headers: updatedHeaders()
    })
    return response
}

export async function characterAuthorized(character_id) {
    const response = await fetch(config.apiUrl + '/character_authorized/' + character_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function fetchCharacterFeat(character_id) {
    const response = await fetch(config.apiUrl + '/character_feats/' + character_id, {
        method: "GET",
        headers: updatedHeaders()
    })
    return response
}

export async function updateCharactersPoints(larp_id, character_ids, point_id, amount, comment) {
    let updateInfo = {
        "character_ids": character_ids,
        "point_id": point_id,
        "amount": amount,
        "comment": comment
    };
    const response = await fetch(config.apiUrl + '/update_characters_points/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(updateInfo)
    })
    return response
}

export async function updateCharactersStats(larp_id, character_ids, stat_id, amount) {
    let updateInfo = {
        "character_ids": character_ids,
        "stat_id": stat_id,
        "amount": amount
    };
    const response = await fetch(config.apiUrl + '/update_characters_stats/' + larp_id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(updateInfo)
    })
    return response
}

export async function updateLogComment(log, comment) {
    let logInfo = {
        "comment": comment
    };
    const response = await fetch(config.apiUrl + '/update_log_comment/' + log.id, {
        method: "PUT",
        headers: updatedHeaders(),
        body: JSON.stringify(logInfo)
    })
    return response
}
