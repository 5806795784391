const en_dictionary = {
    language: "en",
    menu_dashboard: "Dashboard(A)",
    menu_map_search: "Find a larp",
    menu_larp: "My Larp",
    menu_contact: "Contact Us",
    menu_login: "Login",
    menu_logged_in: "My Account",
    menu_calendar: "Calendar",
    login_title: "Login",
    login_description: "Please enter your login and password!",
    login_email: "Email address",
    login_password: "Password",
    login_forgot_password: "Forgot password?",
    login_signup_account: "Don't have an account?",
    login_signup_button: "Sign Up",
    login_message_not_found: "User Not Found",
    login_message_not_verified: "User Not Verified. Please click the link in the email to verify.",
    login_message_wrong: "Wrong email or Password",
    login_message_unknown: "Unknown error",
    signup_title: "Sign Up",
    signup_first_name: "First name",
    signup_last_name: "Last name",
    signup_email: "Email address",
    signup_password: "Password",
    signup_confirm_password: "Confirm Password",
    signup_password_requirement: "Minimum eight characters, at least one letter, one number and one special character",
    signup_message_existing_user: "User Already Existing",
    signup_message_unknown: "Unknown error",
    signup_message_conf_title: "User created",
    signup_message_conf_description: "An email will be send to your email. Please verify your account by clicking on the link provided.",
    pass_rec_title: "Password Recovery",
    pass_rec_description: "Please enter your email!",
    pass_rec_email: "Email address",
    pass_rec_send: "Send",
    pass_rec_message_user_not_found: "User Not Found",
    pass_rec_message_unknown: "Unknown error",
    pass_rec_message_conf_title: "Password Recovery Sent",
    pass_rec_message_conf_description: "An email will be send to your email. Please follow the link provided to reset your password.",
    verif_pass_rec_title: "Verified Password Recovery",
    verif_pass_rec_description: "Please enter your new Password!",
    verif_pass_rec_password: "Password",
    verif_pass_rec_send: "Update",
    verif_pass_message_user_not_found: "User Not Found",
    verif_pass_message_unauthorized: "Unauthorized or expired token",
    verif_pass_message_unknown: "Unknown error",
    verif_pass_confirmation: "Password Changed",
    email_verification_title: "Email Verification",
    email_verification_send: "Verify",
    email_verification_message_user_not_found: "User Not Found",
    email_verification_message_unknown: "Unknown error",
    email_verification_message_unauthorized: "Unauthorized token",
    email_verification_message_verified: "User already verified",
    my_account_disconnect: "Disconnect",
    create_larp_button: "Create new Larp",
    register_existing_larp_button: "Register an existing Larp",
    larp_label_logo: "Logo",
    larp_label_name: "Larp Name",
    larp_label_link: "Larp Link",
    larp_characters_title: "Characters",
    larp_label_address: "Address",
    larp_label_website: "Website",
    larp_label_website_display: "Website display",
    larp_label_website_link: "Website link",
    larp_label_website2: "Website 2",
    larp_label_website2_display: "Website 2 display",
    larp_label_website2_link: "Website 2 link",
    larp_label_website3: "Website 3",
    larp_label_website3_display: "Website 3 display",
    larp_label_website3_link: "Website 3 link",
    larp_label_email: "Email",
    larp_label_custom_code: "Customization code",
    larp_label_custom_code_placeholder: "Provided by Larp Online",
    larp_label_description: "Description",
    larp_label_status: "Status",
    larp_label_average_player_count: "Average player count by game",
    larp_label_min_age: "Minimum age to play",
    larp_label_max_age: "Maximum age to play",
    larp_label_alcohol_accepted: "Alcohol accepted",
    larp_label_alcohol_accepted_yes: "Yes",
    larp_label_alcohol_accepted_no: "No",
    larp_label_alcohol_accepted_all: "All",
    larp_status_local: "Local",
    larp_status_local_display: "No publicly available",
    larp_status_pending: "Pending Appproval",
    larp_status_published: "Published",
    larp_status_remove_published: "Remove publication",
    larp_game_masters: "Game Masters",
    larp_game_masters_hidden: "Do not display publicly",
    larp_invite_game_masters_label: "Please enter the email of a new game master",
    larp_invite_game_masters: "Invite new Game Master",
    larp_save_button: "Save",
    larp_delete_button: "Delete the larp",
    larp_publish_button: "Request for publication",
    larp_publish_existing_error: "A published larp already exist with this name",
    larp_approve_button: "Approve for publication",
    larp_remove_button: "Remove publication",
    larp_status_tooltip: "The Status represent the availability of the larp entity.  \nLocal: Only available for its creator. \nPending: Waiting for approval before being puplished. \nPublished: Available to everybody.",
    larp_save: "Save",
    larp_save_error_name: "Larp name is required",
    larp_save_error_point: "At least one point must be created",
    larp_save_error_sub_cat: "At least one sub category per category must be created",
    larp_save_error_negative_age: "Age limits must be positive",
    larp_save_error_min_age_bigger: "The minimum age cannot be bigger than the maximum age",
    larp_error_remove_point: "A category still refers to this point",
    larp_add_favorite: "Add to favorites",
    larp_remove_favorite: "Remove from favorites",
    larp_confirm_delete: "Do you really want to delete this larp? This action is irreversible",
    larp_unhide: "Unhide",
    larp_display_color: "Display color",
    larp_player_view: "Player view",
    my_larps_update_button: "Update",
    my_larps_add_button: "Add",
    my_larps_add_character_button: "Add Character",
    my_larps_already_added: "Already Added",
    my_larps_register_larp_title: "Choose a Larp",
    my_larps_add_character_title: "Choose a name",
    my_larps_modal_close_button: "Close",
    my_larps_modal_continue_button: "Continue",
    my_larps_modal_add_button: "Add Character",
    my_character_label_profile: "Profile Image",
    my_character_label_name: "Name",
    my_character_label_backstory: "Backstory",
    my_character_label_notes: "Notes",
    my_character_label_gm_notes: "Notes for Game Master",
    my_character_no_cat_chosen: "No sub category choosen",
    my_character_confirm_delete: "Do you really want to delete this character? This action is irreversible",
    my_character_transfer: "Transfer character",
    my_character_confirm_transfer: "Do you really want to transfer this character?",
    my_character_confirm_email_transfer: "Enter desired account email",
    my_character_success_transfer: "Transfer was a success",
    my_character_failure_transfer: "Email doesn't exist",
    my_character_yes: "Yes",
    my_character_no: "No",
    my_character_not_enough_points: "Your character doesn't have enough points for this feat",
    my_character_invalid_name: "Character name is invalid.",
    my_character_is_npc: "is an NPC",
    contact_description: "Don't hesitate to contact us for any information by using the following form.",
    contact_name: "Name",
    contact_email: "Email",
    contact_message: "Message",
    contact_us_send_button: "Send",
    contact_us_confirmation: "Message successfully sent to larponline.ca@gmail.com",
    account_welcome: "Welcome",
    account_first_name: "First Name",
    account_last_name: "last Name",
    account_email: "Email",
    account_preferences: "Preferences",
    account_logs_order: "Order character changes from newest to oldest",
    account_save_confirmation: "The user has been updated",
    account_reset: "Reset Password",
    account_reset_confirmation: "A message has been sent to your email.  Please follow the link provided to update your password.",
    map_search_name: "By name",
    map_no_larp_found: "No matching larps found",
    map_event_between: "Event between",
    map_more_filters: "More filters",
    map_less_filters: "Less filters",
    map_reset_filters: "Reset filters",
    map_filter_categories: "Category",
    map_filter_alcohol: "Alcohol accepted",
    map_filter_player_number: "Number of players",
    map_filter_age: "Age restriction",
    map_category_no_option: "No more options",
    points_title: "Points",
    points_description: "The points are what players use to buy feats or buy any bonus. It could be exp, class points or whatever point you will give to your players",
    points_name: "Point name",
    points_alias: "Alias",
    points_start_value: "Starting value",
    points_add_button: "Add new point",
    points_total: "total",
    points_remaining: " Remaining",
    stat_title: "Stats",
    stat_description: "The stats are the points that players will augment buy buying feats.",
    stat_name: "Stat name",
    stat_alias: "Alias",
    stat_start_value: "Starting value",
    stat_add_button: "Add new stat",
    logs_title: "Changes to character",
    logs_update: "Update Log Description",
    logs_update_button: "Update",
    category_title: "Categories",
    category_desc: "Each feat category is something the player pick that will give him access  to a list of feats.\nExamples of feat categories are races, classes, prestige class, etc. \nSubcategories are groups of feats. Examples of subcategories are mage, warrior, etc.",
    category_add: "Create new category",
    category_remove: "Remove category",
    category_remove_title: "Remove category",
    sub_category_remove_title: "Remove sub category",
    point_remove_title: "Remove Point",
    stat_remove_title: "Remove Stat",
    article_remove_message: "You can't remove this group since an article still refers to it.",
    category_remove_verification: "A total of {char_count} characters and {feat_count} feats will be impacted,\n Do you still want to continue",
    category_name: "Category Name",
    category_description: "Description",
    category_hidden: "Hidden Category",
    category_details_hidden: "Hidden Category in larp's details",
    category_point_to_buy: "Points to buy feats",
    category_min_select: "Min. times selected",
    category_max_select: "Max. times selected",
    category_sub_categories: "Subcategory name",
    category_sub_categories_hidden: "Hidden",
    category_sub_categories_add: "Add subcategory",
    category_sub_display: "Sub Categories",
    display_larp_button: "Display Larp",
    player_manage_button: "Manage Players",
    article_manage_button: "Manage Articles",
    calendar_manage_button: "Manage Events",
    calendar_all_larps: "All public larps",
    feat_manage_button: "Manage Feats",
    feat_button: "Feats",
    player_button: "Players",
    event_button: "Events",
    feat_title: "Feats",
    feat_desc: "Feats are abilities that can be bought by player with points to do something other players can’t. You can put 0 cost if the feat is free or put a negative cost if the feat is a disadvantage for the player to get more points.",
    feat_name: "Feat Name",
    feat_description: "Description",
    feat_requirement: "Requirement",
    feat_limitations: "Limitations",
    feat_max_select: "Max. times selected",
    feat_stat_change: "Stat change",
    feat_point_change: "Pt change",
    feat_cost: "Cost to buy",
    feat_cost_for: "for",
    feat_hidden: "Is hidden for players",
    feat_triggers: "Triggers an action at registration",
    feat_filter_label: "Search by name",
    feat_filter_category_label: "Search by name",
    feat_filter_feat_label: "Feat",
    feat_filter_show_npc_label: "Hide NPC",
    feat_filter_only_show_npc_label: "Only show NPC",
    feat_allow_new_selection: "Allow the player to select a new feat",
    feat_can_select: "Can Select new feat from:",
    feat_table_name: "Name",
    feat_table_hidden: "Hidden",
    feat_table_selec_max: "Sel. Max",
    feat_table_acquire_selec: "Acquire when Select",
    feat_table_cost: "Costs",
    feat_table_stat_mods: "Stat Modifications",
    feat_table_point_mods: "Point Modifications",
    feat_table_actions: "Actions",
    feat_aquire: "Acquire when sub category is selected",
    feat_add_button: "Add feat",
    feat_remove_button: "Remove",
    feat_modify_button: "Modify",
    feat_template_button: "Template",
    feat_create_button: "Create new Feats",
    feat_close_creation_button: "Close Creation",
    feat_modal_title: "Edit Feat",
    feat_modal_save: "Save",
    char_feat_acquired: "Acquired Feats",
    char_feat_available: "Available Feats",
    char_feat_cost: "Cost",
    char_feat_aquired_on_select: "Acquired on select",
    char_feat_name: "Name",
    char_feat_max_sel: "Max. select",
    char_feat_sel: "Select",
    char_feat_time_sel: "Times",
    char_feat_confirm: "Confirm Selection",
    char_table_title: "Characters",
    char_table_selection: "Selection",
    char_table_name: "Name",
    char_table_points: "Points",
    char_table_stats: "Stats",
    char_table_categories: "Categories",
    char_table_feat_actions: "Feat with Actions",
    char_table_player: "Players",
    char_table_actions: "Actions",
    char_table_change_comment: "Comment",
    char_table_change_comment_empty: "Enter a comment to describe the modfication",
    char_table_pt_change: "Add to Pts",
    char_table_pt_change_warning: "Please select at least one character to update.",
    char_table_stat_change: "Add to Stats",
    char_table_show_mods: "Modify selection",
    char_table_hide_mods: "Close modification",
    char_table_present_button: "Present",
    char_table_present_remove_button: "Remove Presence",
    char_table_paid_button: "Paid",
    char_table_paid_remove_button: "Remove Payment",
    char_table_modify_button: "Modify",
    char_table_delete_button: "Delete",
    char_table_export_button: "Export",
    char_table_char_sub_button: "Register a player",
    char_table_char_sub_remove_button: "Remove Registered player",
    char_table_char_sub_title: "Subscribe a character to the event",
    char_table_char_sub_remove_title: "Remove subscribed character to the event",
    char_table_char_sub_description: "Choose a Character",
    char_tabcontent: "Tab content for Character",
    char_choose_new_feat: "Obtain a new Feat",
    char_choose_new_feat_select_sub_cat: "Select a new sub category",
    char_delete: "Delete the character",
    char_update_error: "An error has occured while saving. Please reload the page.",
    event_label_name: "Name",
    event_label_img: "Image",
    event_label_start_date: "Start date",
    event_label_end_date: "End Date",
    event_label_description: "Details",
    event_save_button: "Save",
    event_create_button: "Create",
    event_create_new_button: "Create new Event",
    event_edit_button: "Edit",
    event_end_button: "End Event",
    event_reopen_button: "Reopen Event",
    event_edit_title: "Event Details",
    event_title: "Event List",
    event_coming_up: "Events to come",
    event_noname: "You need to enter a name for the event",
    event_nodate: "You need to enter a start date for the event",
    dashboard_verified: "Verified",
    dashboard_notverified: "Not Verified",
    dashboard_user: "User",
    dashboard_tester: "Tester",
    dashboard_admin: "Admin",
    dashboard_userlist: "Users",
    dashboard_larp: "Larps",
    dashboard_character: "Characters",
    dashboard_multi_select: "Multi-select tools",
    dashboard_ms_larp_type: "Larp types",
    dashboard_ms_ID: "ID",
    dashboard_ms_modify: "Modify",
    dashboard_ms_priority: "Priority",
    dashboard_ms_fr_label: "French label",
    dashboard_ms_en_label: "English label",
    dashboard_confirm_user_delete: "Do you really want to delete this account? This action is irreversible",
    dashboard_confirm_email: "Confirm email account:",
    dashboard_error_email_confirm: "Error while validating email",
    article_button: "Articles",
    article_title: "Articles",
    atricle_create_new_button: "Create new Article",
    atricle_create_new_article_group_button: "Create new Article Group",
    article_edit_title: "Article Details",
    article_group_edit_title: "Article Group Details",
    article_name_label: "Name",
    article_group_label: "Group",
    article_text_label: "Details",
    article_priority_label: "Priority",
    article_hidden_title: "Hidden",
    article_hidden_label: "Display only to Dms",
    article_timeline_title: "Time line",
    article_timeline_label: "Display as a Time line",
    article_timeline_date_label: "Time line Date",
    article_edit_button: "Edit",
    subscribe_character_title: "Subscribe a Character to an Event",
    subscribe_character_not_logged_in: "To subscribe a character to the event, you will need to log in first",
    subscribe_character_no_char: "No character has been found for this Larp",
    subscribe_character_register_button: "Subscribe",
    subscribe_character_remove_button: "Remove",
    subscribe_character_already_registered: "A character is already subscribed to this event",
    subscribe_character_select_by_event: "Select by event",
    subscribe_character_select_payment_status: "Payment Status",
    subscribe_character_select_present_status: "Presence Status",
    subscribe_character_present_all: "All",
    subscribe_character_present_present: "Present",
    subscribe_character_present_not_present: "Not Present",
    subscribe_character_payment_all: "All",
    subscribe_character_payment_payed: "Payed",
    subscribe_character_payment_not_payed: "Not Payed",
    featureflags_larpcreation: "Larp creation",
    featureflags_characterCreationFeature: "Character creation",
    featureflags_categoryMultipleSelectFeature: "Multiple Category Selection",
    featureflags_complexeLarpFeature: "Complex larp creation",
    featureflags_registerLarp: "Larp registration",
    featureflags_time_line_articles: "Articles with Timeline",
    dialog_confirmation: "Confirmation",
    char_pdf_title: "Characters for Larp: ",
    cookie_message: "This website uses cookies to enhance your experience.",
    cookie_yes: "I accept the use of cookies"
};

export default en_dictionary;
