import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import useTranslation from "../../components/customHooks/translations";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { fetchIsDungeonMaster, fetchLarpData, add_larp_link, remove_larp_link, fetchUserLarps } from "../../api/larpAPI";
import EventCard from "../../components/EventCard";
import ArticleDisplayList from "./ArticleDisplayList";
import SimpleLarp from "../../components/SimpleLarp";
import Modal from 'react-bootstrap/Modal';
import { fetCharacters } from "../../api/characterAPI";
import Button from "react-bootstrap/esm/Button";
import { PlayerRegisterToEvent, PlayerRemoveFromEvent, fetchUserSubscriptions } from "../../api/eventAPI";
import SafeHtmlContent from "../../components/SafeHtmlContent";
import { BsFeather } from "react-icons/bs";
import LarpCategoriesTab from "./LarpCategoriesTab";
import { fetchIsAdmin } from "../../api/userAPI";

const Larp = () => {
    const navigate = useNavigate()
    const translation = useTranslation();
    let { id, articleid } = useParams()
    id = id.toLowerCase()
    const [larp, setLarp] = useState({ id: "", larp_name: "", address: "", website: "", website2: "", website3: "", website_display: "", website2_display: "", website3_display: "", email: "", description: "", status: "", imagePath: "", customCode: "", alcoholAccepted: false, averagePlayerCount: 0, minAge: 0, maxAge: 0 });
    const [categories, setCategories] = useState([])

    const [events, setEvents] = useState([])
    const [articles, setArticles] = useState([])

    const [selectedEvent, setSelectedEvent] = useState({})
    const [selectedCharacter, setSelectedCharacter] = useState("")
    const [selectedSub, setSelectedSubscription] = useState("")

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubscriptionRemovable, setIsSubscriptionRemovable] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [characters, setCharacters] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const loggedIn = useSelector(state => state.user.loggedIn)
    const [displayedArticle, setDisplayedArticle] = useState([])
    const [isLarpFavorite, setIsLarpFavorite] = useState(false);
    const [isDm, setIsDm] = useState(false);
    const [isPlayerView, setIsPlayerView] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAdminVerified, setIsAdminVerified] = useState(false);

    async function verifyIsAdmin() {
        const response = await fetchIsAdmin()
        if (response.ok) {
            let isAdmin_response = await response.json();
            setIsAdmin(isAdmin_response)
        }
        setIsAdminVerified(true)
    }

    async function verifyIsDm() {
        const response = await fetchIsDungeonMaster(id)
        if (response.ok) {
            let isdm_response = await response.json();
            setIsDm(isdm_response)
            setIsPlayerView(!isdm_response)
        }
        setIsVerified(true)
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedCharacter("")
    }

    const handleArticleDisplay = (article) => {
        setDisplayedArticle(article);
    }

    function open_subscribe_character(event) {
        setIsModalOpen(true)
        setSelectedEvent(event)
        setIsSubscriptionRemovable(false)
        setSelectedSubscription("")
        if (!loggedIn)
            setErrorMessage(translation.subscribe_character_not_logged_in)
        else {
            if (characters.length === 0)
                setErrorMessage(translation.subscribe_character_no_char)
            else {
                let already_subscribed = false
                let character_subscribed = null
                subscriptions.map(sub => {
                    if (Number(sub.event_id) === Number(event.id)) {
                        already_subscribed = true
                        character_subscribed = sub.character_id
                        setSelectedSubscription(sub.id)
                    }
                })
                let character = characters.find((x) => x.id === character_subscribed)
                if (already_subscribed && character) {
                    setErrorMessage(translation.subscribe_character_already_registered + ": " + character.name)
                    setIsSubscriptionRemovable(true)
                }
                else {
                    setErrorMessage("")
                }
            }
        }
    }

    async function subscribe_character() {
        if (selectedCharacter !== "") {
            await PlayerRegisterToEvent(selectedEvent.id, larp.id, selectedCharacter)
            handleModalClose()
            fetchData()
                .catch(console.error);
            fetchCharactersData()
                .catch(console.error);
            fetchCharactersSubs()
                .catch(console.error);
        }
    }

    async function remove_subscribed_character() {
        await PlayerRemoveFromEvent(selectedSub)
        handleModalClose()
        fetchData()
            .catch(console.error);
        fetchCharactersData()
            .catch(console.error);
        fetchCharactersSubs()
            .catch(console.error);
    }

    async function addLarpToFavorite() {
        await add_larp_link(larp.id);
    }

    async function removeLarpFromFavorite() {
        await remove_larp_link(larp.id);
    }

    const toggleLarpFavorite = async () => {
        if (!isDm) {
            try {
                if (isLarpFavorite) {
                    await removeLarpFromFavorite();
                } else {
                    await addLarpToFavorite();
                }

                setIsLarpFavorite(!isLarpFavorite);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const fetchData = async () => {
        const response = await fetchLarpData(id)
        if (response.ok) {
            let data_response = await response.json();
            let larp_response = data_response.larp;
            let cat_response = data_response.larp_categories;
            let event_response = data_response.larp_events;
            let article_response = data_response.larp_articles;
            setLarp({
                id: larp_response.id,
                larp_name: larp_response["name"],
                address: larp_response["address"],
                website: larp_response["website1"],
                website2: larp_response["website2"],
                website3: larp_response["website3"],
                website_display: larp_response["website1_display"],
                website2_display: larp_response["website2_display"],
                website3_display: larp_response["website3_display"],
                email: larp_response["email"],
                description: larp_response["description"],
                status: larp_response["status"],
                imagePath: larp_response["image_path"],
                customCode: larp_response["custom_code"],
                alcoholAccepted: larp_response["alcohol_accepted"],
                averagePlayerCount: larp_response["average_player_count"],
                minAge: larp_response["min_age"],
                maxAge: larp_response["max_age"],
            })
            setCategories(cat_response)
            setEvents(event_response)
            setArticles(article_response)
            if (articleid != null) {
                article_response.forEach(article => {
                    if (article.id == articleid) {
                        setDisplayedArticle([article]);
                    }
                });
            }
        } else if (response.status === 404)
            navigate("/")
    }

    const fetchCharactersData = async () => {
        const response = await fetCharacters()
        if (response.ok) {
            let character_response = await response.json();
            setCharacters(character_response.filter(x => Number(x.larp_id) === Number(id) || x.larp_link_name === id))
        }
    }

    const fetchCharactersSubs = async () => {
        const response = await fetchUserSubscriptions(id)
        if (response.ok) {
            let subs_response = await response.json();
            setSubscriptions(subs_response)
        }
    }

    const fetchFavorites = async () => {
        const response = await fetchUserLarps()
        if (response.ok) {
            let favorite_response = await response.json();
            setIsLarpFavorite(favorite_response.some((favorite_larp) => id === favorite_larp.larp_id || id === favorite_larp.linkName));
        }
    }

    useEffect(() => {
        verifyIsAdmin()
        verifyIsDm()
        fetchData()
            .catch(console.error);
        fetchCharactersData()
            .catch(console.error);
        fetchCharactersSubs()
            .catch(console.error);
        fetchFavorites()
            .catch(console.error);
    }, []);

    if (isVerified && isAdminVerified && larp.id != "") {
        if (!isDm && larp.status != "PUBLISHED" && !isAdmin)
            navigate("/")
    }

    return (
        <div className="hor-flex overflowOverlay">
            <div className="left-side-bar">
                <ArticleDisplayList articles={articles} larp={larp} displayArticles={handleArticleDisplay} characters={characters} isDm={!isPlayerView} larpid={id} handleNewCharacter={fetchCharactersData} />
            </div>
            <div className="page_content flex">
                {(isDm || isAdmin) &&
                    <div className="display-modification">
                        <BsFeather className="space-small pointer" size={25} onClick={() => { navigate("/update-larp/" + larp.id) }} />
                        <label><input type="checkbox" onChange={(e) => { setIsPlayerView(!isPlayerView) }} checked={isPlayerView}></input>
                            {" " + translation.larp_player_view}</label>
                    </div>
                }
                {displayedArticle.length === 0 &&
                    <>
                        <SimpleLarp
                            larp={larp}
                            isDm={!isPlayerView}
                            isLarpFavorite={isLarpFavorite}
                            toggleLarpFavorite={toggleLarpFavorite}
                        />
                        <LarpCategoriesTab categories={categories} larp_id={id} isDm={!isPlayerView} />
                    </>
                }
                {displayedArticle && displayedArticle.length >= 1 &&
                    <h1>{displayedArticle[0].article_group && displayedArticle[0].article_group.name}</h1>
                }
                {displayedArticle && displayedArticle.map((article) =>
                    <div key={article.id}>
                        <h2>{article.name}</h2>
                        <SafeHtmlContent htmlContent={article.text} />
                    </div>
                )}
            </div>

            <div className="right-side-bar">
                <h3>{translation.event_coming_up}</h3>
                {events.map((event) =>
                    <EventCard key={event.id} larp_id={id} event={event} displaySmall onCardClick={() => open_subscribe_character(event)} />
                )}
                <Modal className="edit-event-modal" show={isModalOpen} onHide={handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translation.subscribe_character_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="ver-flex">
                            <span><b>{selectedEvent.name}: {selectedEvent.start_date}</b></span>
                            <SafeHtmlContent className="event-description" htmlContent={selectedEvent.description} /> <br />
                            {errorMessage}
                            {errorMessage === "" &&
                                <div>
                                    <select className="form-select" onChange={(e) => setSelectedCharacter(e.target.value)} value={selectedCharacter}>
                                        <option></option>
                                        {characters.map((character) =>
                                            <option key={character.id} value={character.id}>{character.name}</option>
                                        )}
                                    </select>
                                </div>
                            }
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        {errorMessage === "" &&
                            <Button variant="secondary" onClick={subscribe_character}>
                                {translation.subscribe_character_register_button}
                            </Button>
                        }
                        {isSubscriptionRemovable &&
                            <Button variant="secondary" onClick={remove_subscribed_character}>
                                {translation.subscribe_character_remove_button}
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Larp;
