import React from 'react';
import { MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBInput }
  from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useTranslation from "../components/customHooks/translations";
import { log_in } from '../redux/UserHandler';
import { userLogin } from '../api/userAPI';
import PasswordInput from './PasswordInput';
import { getSessionToken, setSessionToken } from '../utils/SessionTokenUtils';

function LoginForm() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getSessionToken() !== null) {
      dispatch(log_in())
      navigate("/account");
    }
  });

  const [message, setMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  function formValidation() {
    let isValid = true;
    if (emailValue.trim() === "") {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    if (passwordValue.trim() === "") {
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false);
    }
    return isValid;
  }

  async function submit_login(e) {
    if (formValidation()) {
      const response = await userLogin(emailValue, passwordValue)
      if (response.status === 404) {
        setMessage(translation.login_message_not_found)
      } else if (response.status === 402) {
        setMessage(translation.login_message_not_verified)
      } else if (response.status === 401) {
        setMessage(translation.login_message_wrong)
      } else if (response.ok) {
        let user_session = await response.text();
        setSessionToken(user_session);
        dispatch(log_in())
        navigate("/account");
      } else {
        setMessage(translation.login_message_unknown)
      }
    }
  }

  let emailNameLabelClass = emailError ? 'text-red' : 'text-white'
  let passwordNameLabelClass = passwordError ? 'text-red' : 'text-white'
  return (
    <MDBContainer fluid className='login-modal'>
      <form onSubmit={(e) => { submit_login(); e.preventDefault(); }}>
        <p className="login-message errorMessage">{message}</p>
        <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
          <MDBCardBody className='p-5 d-flex flex-column align-items-center'>

            <h1 className="fw-bold mb-2 text-uppercase fredoka medium-size">{translation.login_title}</h1>
            <h4 className="text-white-50 mb-5 fredoka small-size">{translation.login_description}</h4>

            <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass={emailNameLabelClass} label={translation.login_email} type='email' size="lg" onChange={(e) => { setEmailValue(e.target.value) }} />
            <PasswordInput wrapperClass='mb-4 mx-5 w-100' passwordLabelClass={passwordNameLabelClass} label={translation.signup_password} onChange={(value) => { setPasswordValue(value) }} />
            <p className="small mb-3 pb-lg-2"><Link to="/password-recovery" className="text-white-50">{translation.login_forgot_password}</Link></p>
            <MDBBtn type='submit' outline className='px-5' size='lg' style={{ color: 'white' }} onClick={(e) => { submit_login(e); e.preventDefault(); }}>
              {translation.login_title}
            </MDBBtn>
            <br />
            <div>
              <p className="mb-0">{translation.login_signup_account} <Link to="/sign-up" className="text-white-50 fw-bold">{translation.login_signup_button}</Link></p>
            </div>
          </MDBCardBody>
        </MDBCard>
      </form>
    </MDBContainer>
  );
}

export default LoginForm;
