import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useTranslation from '../../components/customHooks/translations';
import ArticleForm from '../../components/ArticleForm';
import { AddArticle, AddArticleGroup, DeleteArticle, DeleteArticleGroup, EditArticle, EditArticleGroup } from '../../api/articleAPI';
import Table from 'react-bootstrap/Table';
import { BsEyeFill, BsEyeSlashFill, BsXCircleFill } from 'react-icons/bs';
import ArticleGroupForm from '../../components/ArticleGroupForm';

const ArticleList = ({ articles, article_groups, larp_id, onUpdateList }) => {
    const translation = useTranslation();
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);
    const [selectedArticleGroup, setSelectedArticleGroup] = useState(null);
    const [isArticleGroupModalOpen, setIsArticleGroupModalOpen] = useState(false);

    const handleModalClose = () => { setIsArticleModalOpen(false); setIsArticleGroupModalOpen(false); }

    const handleEdit = (article) => {
        setSelectedArticle(article);
        setIsArticleModalOpen(true);
    };

    const handleGroupEdit = (article_group) => {
        setSelectedArticleGroup(article_group);
        setIsArticleGroupModalOpen(true);
    };

    async function createArticle(article) {
        let response = await AddArticle(larp_id, article)
        if (response.ok) {
            setIsArticleModalOpen(false);
            onUpdateList()
        }
    };

    async function updateArticle(article) {
        let response = await EditArticle(article.id, article)
        if (response.ok) {
            setIsArticleModalOpen(false);
            onUpdateList()
        }
    };

    async function removeArticle(article_id) {
        let response = await DeleteArticle(article_id)
        if (response.ok) {
            onUpdateList()
        }
    };

    async function createArticleGroup(article_group) {
        let response = await AddArticleGroup(larp_id, article_group)
        if (response.ok) {
            setIsArticleGroupModalOpen(false);
            onUpdateList()
        }
    };

    async function updateArticleGroup(article_group) {
        let response = await EditArticleGroup(article_group.id, article_group)
        if (response.ok) {
            setIsArticleGroupModalOpen(false);
            onUpdateList()
        }
    };

    async function removeArticleGroup(event, article_group_id) {
        event.stopPropagation();
        const containsArticleGroupId = (article) => article.article_group.id === article_group_id;
        if (articles.some(containsArticleGroupId)) {
            alert(translation.article_remove_message);
            return;
        }
        let response = await DeleteArticleGroup(article_group_id)
        if (response.ok) {
            onUpdateList()
        }
    };

    function filterArticles(article_group) {
        if (selectedArticleGroup === article_group)
            setSelectedArticleGroup(null)
        else
            setSelectedArticleGroup(article_group)
    }

    const filteredArticles = selectedArticleGroup ? articles.filter(x => x.article_group.id === selectedArticleGroup.id) : articles;

    return (
        <div>
            <div className="feat-title">
                <h1>{translation.article_title} ({articles.length})</h1>
            </div>
            <div className="display-flex space-small">
                <div className="flex space-small">
                    <div className="btn btn-dark" onClick={() => { setSelectedArticleGroup(null); setIsArticleGroupModalOpen(true); }}>
                        {translation.atricle_create_new_article_group_button}
                    </div>
                    <Table striped bordered hover className="space-small">
                        <thead>
                            <tr>
                                <th>{translation.article_name_label}</th>
                                <th>{translation.article_priority_label}</th>
                                <th>{translation.article_hidden_title}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {article_groups && article_groups.map((article_group) =>
                                <tr className={selectedArticleGroup !== null ? selectedArticleGroup.id === article_group.id ? "selected-true" : "" : ""}
                                    key={article_group.id}
                                    onClick={() => { filterArticles(article_group) }}>
                                    <td>{article_group.name}</td>
                                    <td>{article_group.priority}</td>
                                    <td>{article_group.isHidden ?
                                        <BsEyeSlashFill color="DarkRed" size={25} />
                                        : <BsEyeFill size={25} />
                                    }
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-dark" onClick={() => { handleGroupEdit(article_group) }}>{translation.article_edit_button}</button>
                                        <BsXCircleFill className="space-small delete-button pointer" size={30} color="DarkRed" onClick={async (e) => { removeArticleGroup(e, article_group.id) }} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <div className="flex space-small">
                    <div className="btn btn-dark " onClick={() => { setSelectedArticle(null); setIsArticleModalOpen(true); }}>
                        {translation.atricle_create_new_button}
                    </div>
                    <Table striped bordered hover className="space-small">
                        <thead>
                            <tr>
                                <th>{translation.article_name_label}</th>
                                <th>{translation.article_group_label}</th>
                                <th>{translation.article_priority_label}</th>
                                <th>{translation.article_hidden_title}</th>
                                <th>{translation.feat_table_actions}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredArticles.map((article) => (
                                <tr key={article.id}>
                                    <td>{article.name}</td>
                                    <td>{article.article_group.name}</td>
                                    <td>{article.priority}</td>
                                    <td>{article.isHidden ?
                                        <BsEyeSlashFill color="DarkRed" size={25} />
                                        : <BsEyeFill size={25} />
                                    }
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-dark" onClick={() => { handleEdit(article) }}>{translation.article_edit_button}</button>
                                        <BsXCircleFill className="space-small delete-button pointer" size={30} color="DarkRed" onClick={async () => { removeArticle(article.id) }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <Modal className="edit-article-modal" show={isArticleGroupModalOpen} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{translation.article_group_edit_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ArticleGroupForm article_group={selectedArticleGroup} article_groups={article_groups} onCreate={createArticleGroup} onUpdate={updateArticleGroup} />
                </Modal.Body>
            </Modal>
            <Modal className="edit-article-modal" show={isArticleModalOpen} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{translation.article_edit_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ArticleForm article={selectedArticle} article_groups={article_groups} articles={articles} onCreate={createArticle} onUpdate={updateArticle} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ArticleList;
