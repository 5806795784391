import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useTranslation from '../../components/customHooks/translations';
import EventForm from '../../components/EventForm';
import { AddEvent, CloseEvent, DeleteEvent, EditEvent } from '../../api/eventAPI';
import EventCard from '../../components/EventCard';

const EventList = ({ events, larp_id, onUpdateList }) => {
    const translation = useTranslation();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalClose = () => setIsModalOpen(false);

    const handleEdit = (event) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
    };

    async function createEvent(event) {
        let response = await AddEvent(larp_id, event, event.image)
        if (response.ok) {
            setIsModalOpen(false);
            onUpdateList()
        }
    };

    async function updateEvent(event, imageUpdateUrl) {
        let response = await EditEvent(event.id, event, imageUpdateUrl)
        if (response.ok) {
            setIsModalOpen(false);
            onUpdateList()
        }
    };

    async function endEvent(event) {
        let response = await CloseEvent(event.id)
        if (response.ok) {
            onUpdateList()
        }
    };

    async function removeEvent(event_id) {
        let response = await DeleteEvent(event_id)
        if (response.ok) {
            onUpdateList()
        }
    };

    return (
        <div>
            <div className="feat-title">
                <h1>{translation.event_title} ({events.length})</h1>
            </div>
            {events.map((event) => (
                <EventCard key={event.id} event={event} removeEvent={removeEvent} editEvent={handleEdit} closeEvent={endEvent} displayActions />
            ))}
            <div className="btn btn-dark space-small " onClick={() => { setSelectedEvent(null); setIsModalOpen(true); }}>
                {translation.event_create_new_button}
            </div>
            <Modal className="edit-event-modal" show={isModalOpen} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{translation.event_edit_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EventForm event={selectedEvent} onCreate={createEvent} onUpdate={updateEvent} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EventList;
