import React, { useEffect } from "react";
import useTranslation from "../components/customHooks/translations";
import { useNavigate } from "react-router";
import { fetchIsAdmin } from "../api/userAPI";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserAdmin from "./admin/UserAdmin";
import LarpAdmin from "./admin/LarpAdmin";
import MultiSelectAdmin from "./admin/MultiSelectAdmin";
import FeatureFlagAdmin from "./admin/FeatureFlagAdmin";
import { getSessionToken } from "../utils/SessionTokenUtils";

export default function Dashboard() {
  const navigate = useNavigate()
  const translation = useTranslation();

  async function isAdmin() {
    const response = await fetchIsAdmin()
    if (response.ok) {
      let isAdmin_response = await response.json();
      if (!isAdmin_response) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    if (getSessionToken() === null) {
      navigate("/login");
    }
    isAdmin()
  });


  return (
    <>
      <h1>{translation.menu_dashboard}</h1>
      <Tabs defaultActiveKey="user" className="mb-3" fill>
        <Tab eventKey="user" title={translation.dashboard_userlist}>
          <UserAdmin />
        </Tab>
        <Tab eventKey="profile" title={translation.dashboard_larp}>
          <LarpAdmin />
        </Tab>
        <Tab eventKey="longer-tab" title={translation.dashboard_character}>
          {translation.char_tabcontent}
        </Tab>
        <Tab eventKey="multi-select" title={translation.dashboard_multi_select}>
          <MultiSelectAdmin />
        </Tab>
        <Tab eventKey="featureflags" title="Feature Flags">
          <FeatureFlagAdmin />
        </Tab>
      </Tabs>
    </>

  );
};
