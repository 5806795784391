import React, { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import { useNavigate, useParams } from "react-router";
import { fetchCharacterData, fetchCharacterFeat, characterAuthorized, updateLogComment } from "../../api/characterAPI";
import FeatComponent from "../../components/FeatComponent";
import CharacterPoints from "../../components/CharacterPoints";
import { BsFeather } from "react-icons/bs";
import { fetchIsDungeonMaster } from "../../api/larpAPI";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SafeHtmlContent from "../../components/SafeHtmlContent";
import ExportCharacter from "../../components/ExportCharacter";
import { getCharacterLimitations } from "../../utils/characterUtils";
import { fetchUser } from "../../api/userAPI";
import { sortByDate } from "../../utils/sortByDate"

const Character = () => {
    const translation = useTranslation();
    const navigate = useNavigate()
    const { id } = useParams()
    const [character, setCharacter] = useState({ name: "", backstory: "" });
    const [characterPoints, setCharacterPoints] = useState([]);
    const [characterData, setCharacterData] = useState([]);
    const [characterStats, setCharacterStats] = useState([]);
    const [characterCategories, setCharacterCategories] = useState([]);
    const [characterFeats, setCharacterFeats] = useState([]);
    const [characterLogs, setCharacterLogs] = useState([]);
    const [isDm, setIsDm] = useState(false);
    const [selectedLog, setSelectedLog] = useState();
    const [updateLogShow, setUpdateLogShow] = useState(false);
    const [comment, setComment] = useState("");
    const [logsOrder, setLogsOrder] = useState([]);

    const handleUpdateLogClose = () => setUpdateLogShow(false);
    const handleAddCharacterShow = (log) => {
        setUpdateLogShow(true);
        setComment(log.comment)
        setSelectedLog(log)
    }

    function updateSelectedLogDesc(value) {
        setComment(value)
    }

    async function getUser() {
        const response = await fetchUser()
        if (response.ok) {
            let users_response = await response.json();
            setLogsOrder(users_response.logs_order)
        }
    }

    async function verifyIsDm(larp_id) {
        const response = await fetchIsDungeonMaster(larp_id)
        if (response.ok) {
            let isdm_response = await response.json();
            setIsDm(isdm_response)
        }
    }

    async function updateLog() {
        const response = await updateLogComment(selectedLog, comment)
        if (response.ok) {
            handleUpdateLogClose()
            fetchData()
        }
    }

    const getCharacterFeats = async () => {
        const response = await fetchCharacterFeat(id)
        if (response.ok) {
            let char_feats_response = await response.json();
            setCharacterFeats(char_feats_response)
        }
    }

    function getLimitations() {
        var arrayLimitations = getCharacterLimitations(characterFeats);

        if (arrayLimitations.length >= 1) {
            return <>
                <h3 className="left-title">{translation.feat_limitations}</h3>
                <div className="hor-flex point-display align-center">{arrayLimitations}</div>
            </>
        }
    }

    const fetchData = async () => {
        const response = await fetchCharacterData(id)
        if (response.ok) {
            let character_data_response = await response.json();
            let character_response = character_data_response.character
            let character_points_response = character_data_response.character_points
            let character_stats_response = character_data_response.character_stats
            let character_cat_response = character_data_response.character_categories
            let character_logs_response = character_data_response.character_logs
            setCharacter({
                name: character_response["name"],
                backstory: character_response["backstory"],
                notes: character_response["notes"],
                gm_notes: character_response["gm_notes"],
                is_npc: character_response["is_npc"],
            })
            setCharacterData(character_data_response)
            setCharacterPoints(character_points_response)
            setCharacterStats(character_stats_response)
            setCharacterCategories(character_cat_response)
            setCharacterLogs(character_logs_response);

            verifyIsDm(character_response["larp_id"])
        }
    }

    useEffect(() => {
        const verifyAuthorization = async () => {
            const response = await characterAuthorized(id)
            if (response.ok) {
                let character_response = await response.json();
                if (!character_response) {
                    navigate("/mylarps")
                }
            }
        }
        getUser();
        verifyAuthorization()
            .catch(console.error);
        fetchData()
            .catch(console.error);
        getCharacterFeats()
            .catch(console.error);
        // eslint-disable-next-line
    }, [navigate]);

    return (
        <div className="display-flex">
            <div className="page_content flex">
                <div className="display-field align-center">
                    <div className="display-modification">
                        <BsFeather className="space-small pointer" size={25} onClick={() => { navigate("/update-character/" + id) }} />
                    </div>
                    <span><b>{character.name}</b></span>
                    {isDm && character.is_npc &&
                        <span>{": " + translation.my_character_is_npc}</span>
                    }
                </div>
                <>
                    <h3 className="left-title">{translation.points_title}</h3>
                    {
                        <CharacterPoints
                            characterPoints={characterPoints} />
                    }
                    <h3 className="left-title">{translation.stat_title}</h3>
                    {characterStats.map((stat) =>
                        <div className="hor-flex point-display align-center" key={stat.id}>
                            {stat.total > 0 &&
                                <>
                                    <label>{stat.stat_name}:</label>
                                    <span className="space-small">{stat.total} {stat.stat_alias}</span>
                                </>
                            }
                        </div>
                    )}
                    {getLimitations()}
                    {characterCategories.filter(x => !x.isHidden).map((category) =>
                        <div className="ver-flex fill" key={category.id}>
                            <h3 className="left-title">{category.category_name}: {category.sub_category}</h3>
                            {characterFeats && characterFeats.filter(x => x.category_id === category.category_id)
                                .sort((a, b) => (a.cost_amount > b.cost_amount) ? 1 : ((b.cost_amount > a.cost_amount) ? -1 : 0))
                                .map((confirmedfeat) =>
                                    <FeatComponent key={confirmedfeat.id} status="confirmed" cat_id={category.category_id} feat_id={confirmedfeat.id} feat={confirmedfeat} onClick={() => { }} />
                                )}
                        </div>
                    )}
                </>
                <div className="background-section">
                    <div className="space-small">
                        <label>{translation.my_character_label_backstory}: </label>
                        <SafeHtmlContent htmlContent={character.backstory} />
                    </div>
                </div>
                <div className="background-section">
                    <div className="space-small">
                        <label>{translation.my_character_label_notes}: </label>
                        <SafeHtmlContent htmlContent={character.notes} />
                    </div>
                    {isDm &&
                        <div className="space-small">
                            <label>{translation.my_character_label_gm_notes}: </label>
                            <SafeHtmlContent htmlContent={character.gm_notes} />
                        </div>
                    }
                </div>
                {characterData.character &&
                    <ExportCharacter char={characterData} feats={characterFeats} isDm={isDm} />
                }
            </div>
            <div className="right-side-bar right-side-bar-big">
                <h3>{translation.logs_title}</h3>
                {
                    characterLogs.sort(sortByDate(logsOrder)).map((log) =>
                        <div key={log.id} className="align-left">
                            {isDm &&
                                <BsFeather className="space-small pointer" size={25} onClick={() => handleAddCharacterShow(log)} />
                            }
                            <span>{log.date.substr(0, 10)}: {log.amount >= 1 && "+"}{log.amount} {log.point_name}: {log.comment}</span>
                        </div>
                    )
                }
                <Modal autoFocus={false} show={updateLogShow} onHide={handleUpdateLogClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translation.logs_update}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedLog &&
                            <input autoFocus={true} type="text" className="form-control" onChange={(e) => { updateSelectedLogDesc(e.target.value) }} value={comment} />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleUpdateLogClose}>
                            {translation.my_larps_modal_close_button}
                        </Button>
                        <Button variant="primary" onClick={updateLog}>
                            {translation.logs_update_button}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    );
};

export default Character;
