import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../components/customHooks/translations";
import Button from 'react-bootstrap/esm/Button';
import { getSessionToken } from "../../utils/SessionTokenUtils";

import { fetchLarpTypes, addLarpType, modifyLarpType } from "../../api/larpAPI";;



const MultiSelectAdmin = () => {
    const navigate = useNavigate();
    const translation = useTranslation();
    const [larpTypes, setlarpTypes] = useState([]);
    const [formData, setFormData] = useState({
        priority: '',
        french_name: '',
        english_name: '',
    });

    async function getLarpTypes() {
        const response = await fetchLarpTypes()
        if (response.ok) {
            setlarpTypes(await response.json());
        }
    }


    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        } else {
            getLarpTypes();
        }
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    async function submit_multi_select() {
        await addLarpType(formData.priority, formData.french_name, formData.english_name);
    }

    const handleModify = (larpType) => {
        modifyLarpType(larpType.id, larpType.priority, larpType.french_name, larpType.english_name);
    };

    return (
        <div className="page_content">
            <div className="restrained-section">
                <div>
                    <h2>{translation.dashboard_ms_larp_type}</h2>
                    <form>
                        <label>{translation.dashboard_ms_priority}</label>
                        <input type="text" name="priority" className="form-control" onChange={handleChange} maxLength="50" />
                        <label>{translation.dashboard_ms_fr_label}</label>
                        <input type="text" name="french_name" className="form-control" onChange={handleChange} maxLength="50" />
                        <label>{translation.dashboard_ms_en_label}</label>
                        <input type="text" name="english_name" className="form-control" onChange={handleChange} maxLength="50" />
                        <Button variant="secondary" onClick={(e) => { submit_multi_select(); }}>
                            {translation.event_create_button}
                        </Button>
                    </form>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>{translation.dashboard_ms_ID}</th>
                            <th>{translation.dashboard_ms_priority}</th>
                            <th>{translation.dashboard_ms_fr_label}</th>
                            <th>{translation.dashboard_ms_en_label}</th>
                            <th>{translation.dashboard_ms_modify}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {larpTypes.map((larpType) => (
                            <tr key={larpType.id}>
                                <td>{larpType.id}</td>
                                <td>
                                    <input
                                        type="text"
                                        defaultValue={larpType.priority}
                                        onChange={(e) => larpType.priority = e.target.value}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        defaultValue={larpType.french_name}
                                        onChange={(e) => larpType.french_name = e.target.value}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        defaultValue={larpType.english_name}
                                        onChange={(e) => larpType.english_name = e.target.value}
                                    />
                                </td>
                                <td>
                                    <Button variant="secondary" onClick={() => handleModify(larpType)}>
                                        {translation.dashboard_ms_modify}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MultiSelectAdmin;
