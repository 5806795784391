import React, { useState } from "react";
import useTranslation from "./customHooks/translations";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { RemoveFromEvent } from "../api/eventAPI";

const RemoveRegisteredCharaterModal = ({ dialogShow, dialogHide, larpId, characters, subscriptions, events, selectedEventId, callback }) => {
    const translation = useTranslation();
    const selectedEvent = events.find(x => Number(x.id) === Number(selectedEventId))
    const [selectedCharId, setSelectedCharId] = useState();

    characters = characters.filter(char =>
        subscriptions.find(sub => Number(sub.character_id) === Number(char.id) && Number(sub.event_id) === Number(selectedEventId)))
        .sort((a, b) => {
            var textA = a.user_name.toUpperCase();
            var textB = b.user_name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

    async function removeRegisteredCharacter() {
        let sub = subscriptions.find(x => Number(x.character_id) === Number(selectedCharId) &&
            Number(x.event_id) === Number(selectedEventId) &&
            Number(x.larp_id) === Number(larpId))
        if (sub)
            await RemoveFromEvent(sub.id)
        setSelectedCharId("")
        dialogHide()
        callback()
    }

    return (
        <Modal autoFocus={false} show={dialogShow} onHide={dialogHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{translation.char_table_char_sub_remove_title}: <br />{selectedEvent.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{translation.char_table_char_sub_description}:</span><br />
                <select className="form-select" value={selectedCharId} onChange={(e) => { setSelectedCharId(Number(e.target.value)) }} >
                    <option value=""></option>

                    {characters.map((char) =>
                        <option key={char.id} value={char.id} >{char.user_name} : {char.name}</option>
                    )}
                </select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={dialogHide}>
                    {translation.my_larps_modal_close_button}
                </Button>
                <Button variant="primary" onClick={removeRegisteredCharacter}>
                    {translation.my_larps_modal_continue_button}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RemoveRegisteredCharaterModal;
