import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../css/mapSearch.css';
import defaultPinImage from '../img/larp-default.png';
import { fetchPublicLarps, fetchLarpTypes } from "../api/larpAPI";
import useTranslation from "../components/customHooks/translations";
import config from "../config";

function Map() {
    const startingFilter = {
        name: '',
        startDate: '',
        endDate: '',
        alcoholAccepted: 0,
        minAveragePlayerCount: 0,
        maxAveragePlayerCount: 100000,
        minAge: 0,
        maxAge: 99,
        larpTypes: []
    }

    const translation = useTranslation();
    const mapContainerRef = useRef(null);
    const [allLarps, setAllLarps] = useState([]);
    const [filteredLarps, setFilteredLarps] = useState([]);
    const [mapInstance, setMapInstance] = useState(null);
    const [isExtraFiltersOpen, setIsExtraFiltersOpen] = useState(false);
    const extraFiltersRef = useRef(null);
    const [filter, setFilter] = useState(startingFilter);
    const [frenchLarpTypes, setFrenchLarpTypes] = useState([]);
    const [englishLarpTypes, setEnglishLarpTypes] = useState([]);

    const alcoholOptions = [
        { value: 0, label: translation.larp_label_alcohol_accepted_all },
        { value: 1, label: translation.larp_label_alcohol_accepted_yes },
        { value: 2, label: translation.larp_label_alcohol_accepted_no }
    ];

    const getLarpTypes = async () => {
        const response = await fetchLarpTypes();
        if (response.ok) {
            const data = await response.json();
            const frenchOptions = data.map(larpType => ({
                larpType: larpType,
                value: larpType.id,
                label: larpType.french_name
            }));
            setFrenchLarpTypes(frenchOptions);

            const englishOptions = data.map(larpType => ({
                larpType: larpType,
                value: larpType.id,
                label: larpType.english_name
            }));
            setEnglishLarpTypes(englishOptions);
            return (translation.language === "fr") ? frenchOptions : englishOptions;
        }
        return [];
    };

    const addMarkersToMap = async (larps) => {
        if (!mapInstance) {
            //console.error('Map instance is not available');
            return;
        }

        // Clear all existing markers from the map
        mapInstance.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
                mapInstance.removeLayer(layer);
            }
        });

        await Promise.all(
            //In case of lag, stop reversing the larps. It's only here to display older larps on top
            [...larps].reverse().map(async larp => {
                const { id, name, image_path, description, address, latitude, longitude } = larp;
                if (latitude === 0 && longitude === 0) {
                    return null;
                }

                var imgHtml = `<img src="${defaultPinImage}" class="pin" alt="Pin" />`;
                if (image_path !== null) {
                    imgHtml = `<img src="${config.apiUrl}/image-uploads/${image_path}" class="pin" alt="Pin" />`;
                }

                const customPin = L.divIcon({
                    className: 'pin',
                    html: imgHtml
                });

                /* Pin popup HTML */
                L.marker([latitude, longitude], { icon: customPin }).addTo(mapInstance).bindPopup(`
                  <a href='/larp/${id}'>${name}</a>
                  <br />
                  <a href='${"https://www.google.com/maps/dir//" + address}' target="_blank">${address}</a>
                  <p>${description}</p>
              `);
            })
        );
    };

    /* Initiate map */
    useEffect(() => {
        const map = L.map(mapContainerRef.current).setView([45.1304, -106.3468], 3);
        setMapInstance(map);
        // Map layer is the images used to display the map.
        //Other cool maps available at https://leaflet-extras.github.io/leaflet-providers/preview/index.html
        const openStreetMapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
        openStreetMapLayer.addTo(map);

        const fetchData = async () => {
            try {
                const response = await fetchPublicLarps();
                if (response.ok) {
                    const data = await response.json();
                    setAllLarps(data.larps);
                    addMarkersToMap(data.larps);
                } else {
                    console.error('Failed to fetch public larps');
                }
            } catch (error) {
                console.error('Error fetching public larps', error);
            }
        };

        if (mapContainerRef.current) {
            fetchData();
        }

        return () => {
            // Clean up the map when the component is unmounted
            map.remove();
        };
    }, [mapContainerRef]);

    function parseDateString(dateString) {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    }

    function larpMatchFilterType(types) {
        return filter.larpTypes.some((filterType) => {
            return types.some((type) => {
                return type.larp_type_id === filterType;
            });
        });
    }

    /* Update filtered larps based on the search query, startDate, endDate, etc*/
    useEffect(() => {
        /* Filter by name and others*/
        var filtered = allLarps.filter((larp) =>
            larp.name.toLowerCase().includes(filter.name.toLowerCase()) &&
            (larp.max_age >= filter.minAge && larp.min_age <= filter.maxAge) &&
            (larp.average_player_count >= filter.minAveragePlayerCount && larp.average_player_count <= filter.maxAveragePlayerCount) &&
            (filter.alcoholAccepted === 0 || (filter.alcoholAccepted === 1 && larp.alcohol_accepted === true) || (filter.alcoholAccepted === 2 && larp.alcohol_accepted === false)) &&
            (filter.larpTypes.length === 0 || larpMatchFilterType(larp.types))
        );

        /* Filter by event date */
        filtered = filtered.filter((larp) => {
            if (filter.startDate === '' ||
                filter.endDate === '') {
                return true;
            } else if (parseDateString(filter.startDate) > parseDateString(filter.endDate)) {
                return false;
            }

            return larp.events && larp.events.some((event) => {
                const startDateMatch = event.start_date &&
                    parseDateString(event.start_date) >= parseDateString(filter.startDate);

                const endDateMatch = event.end_date &&
                    parseDateString(event.end_date) <= parseDateString(filter.endDate);

                return startDateMatch && endDateMatch;
            });
        });

        const coordinateOccurrences = {};
        const latitudeChange = -0.00012;

        // Adjust latitude for larps with the same coordinates
        filtered.forEach((larp) => {
            const { latitude, longitude } = larp;
            const coordinatePair = `${latitude},${longitude}`;

            if (coordinateOccurrences[coordinatePair] !== undefined) {
                larp.latitude += coordinateOccurrences[coordinatePair] * latitudeChange;
                coordinateOccurrences[coordinatePair] += 1;
            } else {
                coordinateOccurrences[coordinatePair] = 1;
            }
        });

        setFilteredLarps(filtered);
    }, [filter, allLarps]);

    const toggleExtaFilters = () => {
        const newFilterisOpen = !isExtraFiltersOpen;
        setIsExtraFiltersOpen(newFilterisOpen);
        const button = document.getElementById('extra-filters-button');
        if (button) {
            button.innerText = newFilterisOpen ? translation.map_less_filters : translation.map_more_filters;
        }

        const extraFilters = document.querySelector('.extra-filters');
        if (extraFilters) {
            if (newFilterisOpen) {
                // Fade in animation needed to change height by javascript because css doesn't do reverse animation
                extraFilters.style.height = `${extraFilters.scrollHeight}px`;
                setTimeout(() => {
                    extraFilters.style.height = "auto";
                }, 1000);
            } else {
                // Fade out animation
                extraFilters.style.height = `${extraFilters.scrollHeight}px`;
                setTimeout(() => {
                    extraFilters.style.height = '0px';
                }, 100);
            }
        }
    };

    const resetFilters = () => {
        setFilter(startingFilter);
    };

    /* Add markers to the map when filteredLarps changes */
    useEffect(() => {
        addMarkersToMap(filteredLarps);
    }, [filteredLarps, mapInstance]);

    const handleChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    const handleAlcoholChange = (e) => {
        setFilter({ ...filter, alcoholAccepted: e.value });
    };

    async function setLarpType(larpTypes) {
        var larpTypesId = [];
        larpTypes.forEach(larpType => {
            larpTypesId.push(larpType.larpType.id);
        });
        setFilter({ ...filter, larpTypes: larpTypesId });
    }

    const renderLarpLinks = filteredLarps.map((larp, index) => (
        <div>
            <div key={index} className="larpResult">
                <img
                    src={config.apiUrl + `/image-uploads/${larp.image_path}`}
                    onError={(e) => {
                        e.target.src = defaultPinImage;
                    }}
                    alt="Logo"
                />
                <div>
                    <a href={`/larp/${larp.link_name}`}>{larp.name}</a>
                    <br />
                    <a href={`https://www.google.com/maps/dir//` + larp.address} target="_blank">{larp.address}</a>
                    <p>{larp.description}</p>
                </div>
            </div>
            <hr />
        </div >
    ));

    return (
        <div className="mapSearch">
            <div>
                <div className="larpSearch">
                    <h1>{translation.menu_map_search}</h1>
                    <div className="form-group">
                        <label htmlFor="name-filter">{translation.map_search_name}</label>
                        <input type="text" className="form-control" value={filter.name} name="name" onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="startDate">{translation.map_event_between}</label>
                        <div className="hor-flex">
                            <input type="date" name="startDate" className="form-control space-small" value={filter.startDate} onChange={handleChange} />
                            <input type="date" name="endDate" className="form-control space-small" value={filter.endDate} onChange={handleChange} />
                        </div>
                    </div>
                    <div ref={extraFiltersRef} className={`extra-filters ${isExtraFiltersOpen ? 'open' : ''}`}>
                        <div className="form-group hor-flex sameLineAligned">
                            <label htmlFor="category-filter">{translation.map_filter_categories}</label>
                            {translation.language === "fr" &&
                                <AsyncSelect defaultOptions loadOptions={getLarpTypes} options={frenchLarpTypes} placeholder="" name="larpTypes" menuPosition="fixed" noOptionsMessage={() => translation.map_category_no_option} onChange={(e) => { setLarpType(e) }} isMulti />
                            }
                            {translation.language === "en" &&
                                <AsyncSelect defaultOptions loadOptions={getLarpTypes} options={englishLarpTypes} placeholder="" name="larpTypes" menuPosition="fixed" noOptionsMessage={() => translation.map_category_no_option} onChange={(e) => { setLarpType(e) }} isMulti />
                            }
                        </div>
                        <div className="form-group hor-flex sameLineAligned">
                            <label htmlFor="alchohol-filter">{translation.map_filter_alcohol}</label>
                            <Select options={alcoholOptions} value={alcoholOptions[filter.alcoholAccepted]} name="alcoholAccepted" onChange={handleAlcoholChange} menuPosition="fixed" />
                        </div>
                        <div className="form-group hor-flex sameLineAligned">
                            <label htmlFor="player-number-filter">{translation.map_filter_player_number}</label>
                            <input type="number" min="0" className="form-control space-small sixDigitNumber" name="minAveragePlayerCount" value={filter.minAveragePlayerCount} onChange={handleChange} />
                            <input type="number" min="0" className="form-control space-small sixDigitNumber" name="maxAveragePlayerCount" value={filter.maxAveragePlayerCount} onChange={handleChange} />
                        </div>
                        <div className="form-group hor-flex sameLineAligned">
                            <label htmlFor="age-filter">{translation.map_filter_age}</label>
                            <input type="number" placeholder="0" min="0" max="99" className="form-control space-small twoDigitNumber" name="minAge" value={filter.minAge} onChange={handleChange} />
                            <input type="number" placeholder="99" min="0" max="99" className="form-control space-small twoDigitNumber" name="maxAge" value={filter.maxAge} onChange={handleChange} />
                        </div>
                    </div>
                    <button onClick={resetFilters}>{translation.map_reset_filters}</button>
                    <button id="extra-filters-button" onClick={toggleExtaFilters}>{translation.map_more_filters}</button>
                    <hr />

                    {/* Display Filtered Results */}
                    {renderLarpLinks.length > 0 ? (
                        renderLarpLinks
                    ) : (
                        <p>{translation.map_no_larp_found}</p>
                    )}
                </div>
                <div ref={mapContainerRef} className="map"></div>
            </div>
        </div>
    );
}

export default Map;
