import React, { useEffect, useState } from "react";
import EventList from "../Event/UpdateEvent";
import { useNavigate, useParams } from "react-router";
import { fetchLarpEvents } from "../../api/eventAPI";
import { getSessionToken } from "../../utils/SessionTokenUtils";

export default function UpdateLarpEvent() {
    const { id } = useParams()
    let navigate = useNavigate()
    const [events, setEvents] = useState([]);

    async function getLarpEvents() {
        const response = await fetchLarpEvents(id)
        if (response.ok) {
            let larp_event_response = await response.json();
            const { compare } = Intl.Collator('en-US');
            larp_event_response.sort((a, b) => compare(a.closed, b.closed));
            setEvents(larp_event_response)
        }
    }

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        }
        getLarpEvents()
    }, [])

    return <EventList events={events} larp_id={id} onUpdateList={getLarpEvents} />;
};
