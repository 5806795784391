import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { StrictMode } from "react";
import store from './redux/store';
import LanguageContextProvider from "./components/contexts/LanguageContext";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js'

import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <Provider store={store}>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </Provider>
  </StrictMode>
);
