const fr_dictionary = {
    language: "fr",
    menu_dashboard: "Tableau de bord",
    menu_map_search: "Recherche de grandeur nature",
    menu_larp: "Mes GN",
    menu_contact: "Contact",
    menu_login: "Connexion",
    menu_logged_in: "Mon Compte",
    menu_calendar: "Calendrier",
    login_title: "Connexion",
    login_description: "Entrez votre courriel et mot de passe!",
    login_email: "Adresse courriel",
    login_password: "Mot de passe",
    login_forgot_password: "Vous avez oublié votre mot de passe?",
    login_signup_account: "Vous n'avez pas de compte?",
    login_signup_button: "S'inscrire",
    login_message_not_found: "Utilisateur inexistant",
    login_message_not_verified: "Utilisateur non vérifié. Veuillez vérifier votre compte en cliquant sur le lien du courriel.",
    login_message_wrong: "Mauvais utilisateur ou mot de passe",
    login_message_unknown: "Erreur inconue",
    signup_title: "S'inscrire",
    signup_first_name: "Prénom",
    signup_last_name: "Nom",
    signup_email: "Adresse courriel",
    signup_password: "Mot de passe",
    signup_confirm_password: "Confirmer Le Mot de Passe",
    signup_password_requirement: "Doit contenir au minimum 8 charactères, avec au moins une lettre, un chiffre et un charactère spécial.",
    signup_message_existing_user: "Utilisateur déjà existant",
    signup_message_unknown: "Erreur inconue",
    signup_message_conf_title: "Utilisateur Créé",
    signup_message_conf_description: "Un message sera envoyé à votre courriel. Veuillez vérifier votre compte en cliquant sur le lien.",
    pass_rec_title: "Retrouver un mot de passe",
    pass_rec_description: "Veuillez entrer votre courriel!",
    pass_rec_email: "Adresse courriel",
    pass_rec_send: "Envoyer",
    pass_rec_message_user_not_found: "Utilisateur inexistant",
    pass_rec_message_unknown: "Erreur inconue",
    pass_rec_message_conf_title: "Changement de mot de passe envoyé",
    pass_rec_message_conf_description: "Un message sera envoyé à votre courriel. Veuillez suivre le lien envoyé pour changer votre mot de passe.",
    verif_pass_rec_title: "Retrouver un mot de passe Vérifié",
    verif_pass_rec_description: "Veuillez entrer votre nouveau mot de passe!",
    verif_pass_rec_password: "Mot de passe",
    verif_pass_rec_send: "Modifier",
    verif_pass_message_user_not_found: "Utilisateur inexistant",
    verif_pass_message_unauthorized: "token expiré ou non-authorisé",
    verif_pass_message_unknown: "Erreur inconue",
    verif_pass_confirmation: "Password Changed",
    email_verification_title: "Verification du Courriel",
    email_verification_send: "Vérifier",
    email_verification_message_user_not_found: "Utilisateur inexistant",
    email_verification_message_unknown: "Erreur inconue",
    email_verification_message_unauthorized: "Token non-authorisé",
    email_verification_message_verified: "Utilisateur déjà vérifié",
    my_account_disconnect: "Déconnexion",
    create_larp_button: "Créer un nouveau GN",
    register_existing_larp_button: "Enregistrer un GN existant",
    larp_label_logo: "Logo",
    larp_label_name: "Nom du GN",
    larp_label_link: "Lien du GN",
    larp_characters_title: "Personnages",
    larp_label_address: "Adresse",
    larp_label_website: "Site web",
    larp_label_website_display: "Texte affiché",
    larp_label_website_link: "Lien du site web",
    larp_label_website2: "Site web 2",
    larp_label_website2_display: "Texte affiché 2",
    larp_label_website2_link: "Lien du site web 2",
    larp_label_website3: "Site web 3",
    larp_label_website3_display: "Texte affiché 3",
    larp_label_website3_link: "Lien du site web 3",
    larp_label_email: "Courriel",
    larp_label_custom_code: "Code de personalisation",
    larp_label_custom_code_placeholder: "Fournis par Larp Online",
    larp_label_description: "Description",
    larp_label_status: "Statut",
    larp_label_average_player_count: "Nombre de joueurs moyens par partie",
    larp_label_min_age: "Âge minimum pour jouer",
    larp_label_max_age: "Âge maximum pour jouer",
    larp_label_alcohol_accepted: "Alcool permise",
    larp_label_alcohol_accepted_yes: "Oui",
    larp_label_alcohol_accepted_no: "Non",
    larp_label_alcohol_accepted_all: "Tous",
    larp_status_local: "Local",
    larp_status_local_display: "Non disponible publiquement",
    larp_status_pending: "En Attente d'approbation",
    larp_status_published: "Publié",
    larp_status_remove_published: "Retirer la publication",
    larp_invite_game_masters_label: "Veuillez entrer le courriel d'un nouveau maître de jeux",
    larp_invite_game_masters: "Inviter un nouveau Maître de jeux",
    larp_game_masters: "Maîtres de Jeux",
    larp_game_masters_hidden: "Ne pas afficher publiquement",
    larp_save_button: "Enregistrer",
    larp_delete_button: "Supprimer le GN",
    larp_publish_button: "Demande de publication",
    larp_publish_existing_error: "Un GN publié existe déjà avec ce nom",
    larp_approve_button: "Approuver pour publication",
    larp_remove_button: "Retirer la publication",
    larp_status_tooltip: "Le statut représente la diponibilité de l'entitée.  \nLocal: Disponible seulement pour le créateur. \nEn Attente d'approbation: En attente avant d'être publié. \nPublié: Disponible à tous.",
    larp_save: "Sauvegarder",
    larp_save_error_name: "Un nom de GN est requis",
    larp_save_error_point: "Un type de point doit être préalablement créé",
    larp_save_error_sub_cat: "Au moins une sous-catérogie par catégorie doit être créé",
    larp_save_error_negative_age: "Les limites d'âges doivent être positifs",
    larp_save_error_min_age_bigger: "L'âge minimum ne peut pas être plus grand que l'âge maximum.",
    larp_error_remove_point: "Une catégorie fait référence à ce point",
    larp_add_favorite: "Ajouter aux favoris",
    larp_remove_favorite: "Enlever des favoris",
    larp_confirm_delete: "Voulez vous vraiment supprimer ce gn? Cette action est irreversible",
    larp_unhide: "Décaché",
    larp_display_color: "Couleur d'affichage",
    larp_player_view: "Vue d'un joueur",
    my_larps_update_button: "Éditer",
    my_larps_add_button: "Ajouter",
    my_larps_add_character_button: "Ajouter un Personnage",
    my_larps_already_added: "Déjà Ajouté",
    my_larps_register_larp_title: "Choisir un GN",
    my_larps_add_character_title: "Choisir un nom",
    my_larps_modal_close_button: "Fermer",
    my_larps_modal_continue_button: "Continuer",
    my_larps_modal_add_button: "Ajouter Personnage",
    my_character_label_profile: "Image de Profile",
    my_character_label_name: "Nom",
    my_character_label_backstory: "Background",
    my_character_label_notes: "Notes",
    my_character_label_gm_notes: "Notes pour Maîtres de jeux",
    my_character_no_cat_chosen: "Pas de sous-catégorie de choisie",
    my_character_confirm_delete: "Voulez vous vraiment supprimer ce personnage? Cette action est irreversible",
    my_character_transfer: "Transférer le personnage",
    my_character_confirm_transfer: "Voulez-vous vraiment transférer ce personnage ?",
    my_character_confirm_email_transfer: "Entrez le courriel du compte désiré",
    my_character_success_transfer: "Transfert réalisé avec succès",
    my_character_failure_transfer: "Courriel non existant",
    my_character_yes: "Oui",
    my_character_no: "Non",
    my_character_not_enough_points: "Votre personnage n'a pas assez de points pour cette compétence",
    my_character_invalid_name: "Le nom de personnage est invalide.",
    my_character_is_npc: "est un PNJ",
    contact_description: "N’hésitez pas à nous rejoindre pour toute information en utilisant le formulaire suivant.",
    contact_name: "Nom",
    contact_email: "Courriel",
    contact_message: "Message",
    contact_us_send_button: "Envoyer",
    contact_us_confirmation: "Message envoyé correctement à larponline.ca@gmail.com",
    account_welcome: "Bienvenue",
    account_first_name: "Prénom",
    account_last_name: "Nom",
    account_email: "Courriel",
    account_preferences: "Préférences",
    account_logs_order: "Ordonner les changements de personnage du plus récent au plus vieux",
    account_save_confirmation: "Sauvegarde des modification effectuée",
    account_reset: "Modifier le mot de passe",
    account_reset_confirmation: "Un message a été envoyé à votre courriel. Veuillez suivre le liens proposé.",
    map_search_name: "Par nom",
    map_no_larp_found: "Aucun Grandeur Nature trouvé",
    map_event_between: "Événement entre",
    map_more_filters: "Plus de filtres",
    map_less_filters: "Moins de filtres",
    map_reset_filters: "Réinitialiser",
    map_filter_categories: "Catégorie",
    map_filter_alcohol: "Alcool permise",
    map_filter_player_number: "Nombre de joueurs",
    map_filter_age: "Restriction d'âge",
    map_category_no_option: "Aucune autre option",
    points_title: "Points",
    points_description: "Les points sont utiliser par les personnages pour acheter des compétences ou tout autres bonus. Ceux-ci peuvent être comparé à des points d'expérience.",
    points_name: "Nom du point",
    points_alias: "Alias",
    points_start_value: "Valeur de départ",
    points_add_button: "Ajouter un nouveau point",
    points_total: "total",
    points_remaining: " Restant",
    stat_title: "Statistiques",
    stat_description: " Les Statistiques sont les points que le personnage augmentera avec compétences.",
    stat_name: "Nom de stat",
    stat_alias: "Alias",
    stat_start_value: "Valeur de départ",
    stat_add_button: "Ajouter une nouvelle statistique",
    logs_title: "Changements au personnage",
    logs_update: "Modifier la description du log",
    logs_update_button: "Modifier",
    category_title: "Catégories",
    category_desc: "Chaque catégorie permettra aux personnages d'accéder à une liste de compétences. \nUn exemple de catégorie serait Race, Classe, Classe de prestige, etc.\n Des exemple de sous-catégorie serait mage, Guerrier, etc.",
    category_add: "Créer une nouvelle catégorie",
    category_remove: "Retirer la catégorie",
    category_remove_title: "Retirer la catégorie",
    sub_category_remove_title: "Retirer la sous-catégorie",
    point_remove_title: "Retirer le Point",
    stat_remove_title: "Retirer la Statistique",
    article_remove_message: "Vous ne pouvez pas retirer ce groupe, car un article fait encore référence à celui-ci.",
    category_remove_verification: "Un total de {char_count} personnages et {feat_count} compétences vont être impactés,\n Désirez vous continuer?",
    category_name: "Nom de Categorie",
    category_description: "Description",
    category_hidden: "Catégorie Cachée",
    category_details_hidden: "Catégorie cachée dans les détails du GN",
    category_point_to_buy: "Points pour acheter des compétences",
    category_min_select: "Sélection minimum",
    category_max_select: "Sélection maximum",
    category_sub_categories: "Nom de sous-catégorie",
    category_sub_categories_hidden: "Cachée",
    category_sub_categories_add: "Ajouter une sous catégorie",
    category_sub_display: "Sous Catégories",
    display_larp_button: "Afficher le GN",
    player_manage_button: "Gérer les Joueurs",
    article_manage_button: "Gérer les Articles",
    calendar_manage_button: "Gérer les Événements",
    calendar_all_larps: "Tout les gns publiques",
    feat_manage_button: "Gérer les Compétences",
    feat_button: "Compétences",
    player_button: "Joueurs",
    event_button: "Événements",
    feat_title: "Compétences",
    feat_desc: "Les compétences sont des habiletés achetables pour spécialiser votre personnage.  Vous pouvez mettre 0 dans le coût si l'habileté est gratuite ou mettre un négatif si la compétence est un désavantage pour obtenir plus de points.",
    feat_name: "Nom de la compétence",
    feat_description: "Description",
    feat_requirement: "Requis",
    feat_limitations: "Limitations",
    feat_max_select: "Sélection maximum",
    feat_stat_change: "Modifcation de Statistique",
    feat_point_change: "Modification de Point",
    feat_cost: "Coût pour achat",
    feat_cost_for: "pour",
    feat_hidden: "Caché aux joueurs",
    feat_triggers: "Déclenche une action à l'inscription",
    feat_filter_label: "Recherche par nom",
    feat_filter_category_label: "Recherche par catégorie",
    feat_filter_feat_label: "Recherche Compétence",
    feat_filter_show_npc_label: "Cacher les PNJ",
    feat_filter_only_show_npc_label: "Afficher seulement les PNJ",
    feat_allow_new_selection: "Permet au joueur d'acheter une nouvelle compétence",
    feat_can_select: "Permet de sélection une compétence de:",
    feat_table_name: "Nom",
    feat_table_hidden: "Caché",
    feat_table_selec_max: "Sél. Max",
    feat_table_acquire_selec: "Acquis à la sélection",
    feat_table_cost: "Coûts",
    feat_table_stat_mods: "Modification de statistique",
    feat_table_point_mods: "Modification de points",
    feat_table_actions: "Actions",
    feat_aquire: "Obtenir lorsque la sous catégorie est sélectionnée",
    feat_add_button: "Ajouter la compétence",
    feat_remove_button: "Retirer",
    feat_modify_button: "Editer",
    feat_template_button: "Gabarit",
    feat_create_button: "Créer de nouvelles compétences",
    feat_close_creation_button: "Fermer la creation",
    feat_modal_title: "Modifier la compétence",
    feat_modal_save: "Sauvegarder",
    char_feat_acquired: "Compétences acquises",
    char_feat_available: "Compétences disponibles",
    char_feat_cost: "Coût",
    char_feat_aquired_on_select: "Acquis à la sélection",
    char_feat_name: "Nom ",
    char_feat_max_sel: "Selection Max",
    char_feat_sel: "Choisi",
    char_feat_time_sel: "Fois",
    char_feat_confirm: "Confirmer la sélection",
    char_table_title: "Personnages",
    char_table_selection: "Sélection",
    char_table_name: "Nom",
    char_table_points: "Points",
    char_table_stats: "Stats",
    char_table_categories: "Catégories",
    char_table_feat_actions: "Compétence avec actions",
    char_table_player: "Joueurs",
    char_table_actions: "Actions",
    char_table_change_comment: "Commentaire",
    char_table_change_comment_empty: "Entrez un commentaire pour décrire la modification",
    char_table_pt_change: "Ajouter aux Pts",
    char_table_pt_change_warning: "Veuillez sélectionner au moins un personnage à modifier.",
    char_table_stat_change: "Ajouter aux Stats",
    char_table_show_mods: "Modifier la selection",
    char_table_hide_mods: "Fermer la modification",
    char_table_present_button: "Présent",
    char_table_present_remove_button: "Retirer la Présence",
    char_table_paid_button: "Payé",
    char_table_paid_remove_button: "Retirer le paiement",
    char_table_modify_button: "Modifier",
    char_table_delete_button: "Supprimer",
    char_table_export_button: "Exporter",
    char_table_char_sub_button: "Enregistrer un joueur",
    char_table_char_sub_remove_title: "Retirer un personnage inscrit à l'événement",
    char_table_char_sub_remove_button: "Retirer un personnage inscrit",
    char_table_char_sub_title: "Inscrire un personnage à l'événement",
    char_table_char_sub_description: "Choisir un personnage",
    char_choose_new_feat: "Obtenir une nouvelle compétence",
    char_choose_new_feat_select_sub_cat: "Veuillez sélectionner une sous catégorie",
    char_tabcontent: "Contenu pour personnages",
    char_delete: "Supprimer le personnage",
    char_update_error: "Une erreur est survenue lors de la sauvegarde. Veuillez rafraichir la page.",
    event_label_name: "Nom",
    event_label_img: "Image",
    event_label_start_date: "Date de début",
    event_label_end_date: "Date de fin",
    event_label_description: "Détails",
    event_save_button: "Sauvegarder",
    event_create_button: "Créer",
    event_create_new_button: "Créer un nouvel évènevent",
    event_edit_button: "Éditer",
    event_end_button: "Fermer L'évènement",
    event_reopen_button: "Réouvrir L'évènement",
    event_edit_title: "Détails de l'événement",
    event_title: "Liste des évènements",
    event_coming_up: "Évènements à venir",
    event_noname: "Vous devez entrer un nom à l'évènement",
    event_nodate: "Vous devez entrer une date de début à l'évènement",
    dashboard_verified: "Vérifié",
    dashboard_notverified: "Pas Verifié",
    dashboard_user: "Usager",
    dashboard_tester: "Testeur",
    dashboard_admin: "Administrateur",
    dashboard_userlist: "Liste d'usagers",
    dashboard_larp: "Liste des gns",
    dashboard_character: "Liste des personnages",
    dashboard_multi_select: "Outils selection multiple",
    dashboard_ms_larp_type: "Types de Grandeur Nature",
    dashboard_ms_ID: "Identifiant",
    dashboard_ms_modify: "Modifier",
    dashboard_ms_priority: "Priorité",
    dashboard_ms_fr_label: "Étiquette française",
    dashboard_ms_en_label: "Étiquette anglaise",
    dashboard_confirm_user_delete: "Voulez vous vraiment supprimer ce compte? Cette action est irreversible",
    dashboard_confirm_email: "Confirmez le courriel du compte:",
    dashboard_error_email_confirm: "Erreur dans la validation du courriel",
    article_button: "Articles",
    article_title: "Articles",
    atricle_create_new_button: "Créer un nouvel Article",
    atricle_create_new_article_group_button: "Créer un nouveau groupe d'articles",
    article_edit_title: "Détails de l'article",
    article_group_edit_title: "Détails du groupe d'articles",
    article_name_label: "Nom",
    article_group_label: "Groupe",
    article_text_label: "Détails",
    article_priority_label: "Prioritée",
    article_hidden_title: "Caché",
    article_hidden_label: "Afficher seulement aux maîtres de jeux",
    article_timeline_title: "Ligne de temps",
    article_timeline_label: "Afficher en tant que ligne du temps",
    article_timeline_date_label: "Date de ligne du temps",
    article_edit_button: "Modifier",
    subscribe_character_title: "Enregistrer un personnage à un évènement",
    subscribe_character_not_logged_in: "Pour enregistrer un personnage à l'évènement, il faudra vous connecter",
    subscribe_character_no_char: "Aucun personnage n'as été trouvé pour ce GN",
    subscribe_character_register_button: "Enregistrer",
    subscribe_character_remove_button: "Retirer",
    subscribe_character_already_registered: "Un personnage est déjà enregistré à l'évènement",
    subscribe_character_select_by_event: "Sélectionner par évènement",
    subscribe_character_select_payment_status: "Status de Paiement",
    subscribe_character_select_present_status: "Status de Présence",
    subscribe_character_present_all: "Tous",
    subscribe_character_present_present: "Présent",
    subscribe_character_present_not_present: "Non-Présent",
    subscribe_character_payment_all: "Tous",
    subscribe_character_payment_payed: "Payé",
    subscribe_character_payment_not_payed: "Non-Payé",
    featureflags_larpcreation: "Création de GN",
    featureflags_characterCreationFeature: "Création de personnage",
    featureflags_categoryMultipleSelectFeature: "Sélection de catégorie multiple",
    featureflags_complexeLarpFeature: "Création de GN complexe",
    featureflags_registerLarp: "Enregistrement de gn",
    featureflags_time_line_articles: "Articles avec Ligne du temps",
    dialog_confirmation: "Confirmation",
    char_pdf_title: "Personnages pour le GN: ",
    cookie_message: "Ce site utilises des cookies pour améliorer votre expérience.",
    cookie_yes: "J'acceptes d'utiliser des cookies"
};

export default fr_dictionary;
