import React, { useState } from "react";
import useTranslation from "./customHooks/translations";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { RegisterToEvent } from "../api/eventAPI";

const RegisterCharacterModal = ({ dialogShow, dialogHide, larpId, characters, subscriptions, events, selectedEventId, callback }) => {
    const translation = useTranslation();
    const selectedEvent = events.find(x => Number(x.id) === Number(selectedEventId))
    const [selectedCharId, setSelectedCharId] = useState();

    characters = characters.filter(char =>
        subscriptions.find(sub => sub.character_id === char.id && Number(sub.event_id) === Number(selectedEventId)) == undefined)
        .sort((a, b) => {
            var textA = a.user_name.toUpperCase();
            var textB = b.user_name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

    async function registerCharacter() {
        await RegisterToEvent(selectedEventId, larpId, selectedCharId)
        setSelectedCharId("")
        dialogHide()
        callback()
    }

    return (
        <Modal autoFocus={false} show={dialogShow} onHide={dialogHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{translation.char_table_char_sub_title}: <br />{selectedEvent.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{translation.char_table_char_sub_description}:</span><br />
                <select className="form-select" value={selectedCharId} onChange={(e) => { setSelectedCharId(Number(e.target.value)) }} >
                    <option value=""></option>

                    {characters.map((char) =>
                        <option key={char.id} value={char.id} >{char.user_name} : {char.name}</option>
                    )}
                </select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={dialogHide}>
                    {translation.my_larps_modal_close_button}
                </Button>
                <Button variant="primary" onClick={registerCharacter}>
                    {translation.my_larps_modal_continue_button}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RegisterCharacterModal;
