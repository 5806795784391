import React, { useState } from 'react';
import { MDBContainer, MDBCard, MDBCardBody, MDBBtn }
  from 'mdb-react-ui-kit';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from "../components/customHooks/translations";
import { emailVerification } from '../api/userAPI';

function EmailVerificationForm() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userVerificationToken = queryParams.get('token');
  const userEmail = queryParams.get('email');

  const [message, setMessage] = useState("");

  async function submit_verification() {
    const response = await emailVerification(userEmail, userVerificationToken)
    if (response.status === 404) {
      setMessage(translation.email_verification_message_user_not_found)
    } if (response.status === 402) {
      setMessage(translation.email_verification_message_verified)
    } else if (response.status === 401) {
      setMessage(translation.email_verification_message_unauthorized)
    } else if (response.ok) {
      navigate("/login")
    } else {
      setMessage(translation.email_verification_message_unknown)
    }
  }

  return (
    <MDBContainer fluid className='login-modal'>
      <p className="login-message">{message}</p>
      <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
        <MDBCardBody className='p-5 d-flex flex-column align-items-center'>
          <h2 className="fw-bold mb-4">{translation.email_verification_title}</h2>
          <MDBBtn outline className='px-5' size='lg' style={{ color: 'white' }} onClick={async () => { submit_verification() }}>
            {translation.email_verification_send}
          </MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default EmailVerificationForm;
