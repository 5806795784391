import React, { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import { useNavigate } from "react-router";
import { add_larp_data } from "../../api/larpAPI";
import { getSessionToken } from "../../utils/SessionTokenUtils";

const CreateLarp = () => {
    const translation = useTranslation();
    const navigate = useNavigate();

    const [file, setFile] = useState()
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");
    const [website1, setWebsite1] = useState("");
    const [website2, setWebsite2] = useState("");
    const [website3, setWebsite3] = useState("");
    const [website1Display, setWebsite1Display] = useState("");
    const [website2Display, setWebsite2Display] = useState("");
    const [website3Display, setWebsite3Display] = useState("");
    const [email, setEmail] = useState("");
    const [alcoholAccepted, setAlcoholAccepted] = useState(false);
    const [averagePlayerCount, setAveragePlayerCount] = useState(0);
    const [minAge, setMinAge] = useState(0);
    const [maxAge, setMaxAge] = useState(99);

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        }
    });

    function validate() {
        if (!name) {
            alert(translation.larp_save_error_name);
            return false
        } else if (minAge < 0 || maxAge < 0) {
            alert(translation.larp_save_error_negative_age);
            return false
        } else if (minAge != null && maxAge != null && minAge > maxAge) {
            alert(translation.larp_save_error_min_age_bigger);
            return false
        }
        return true
    }

    async function submit_larp() {
        if (validate()) {
            let response = await add_larp_data(name, description, address, website1, website1Display, website2, website2Display, website3, website3Display, email, file, alcoholAccepted, averagePlayerCount, minAge, maxAge)
            if (response.ok) {
                let larp_response = await response.json();
                navigate("/larp/" + larp_response["id"])
            }
        }
    }

    return (
        <div className="page_content">
            <div className="restrained-section">
                <form>
                    <div className="form-group">
                        <label>{translation.larp_label_logo}</label>
                        <input className="form-control-file" type="file" name="inputFile" onChange={(e) => { setFile(e.target.files[0]) }} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_name}</label>
                        <input type="text" className="form-control" onChange={(e) => { setName(e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_address}</label>
                        <input type="text" className="form-control" onChange={(e) => { setAddress(e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_website}</label>
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_website_display}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite1Display(e.target.value) }} value={website1Display} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_website_link}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite1(e.target.value) }} value={website1} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_website2}</label>
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_website2_display}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite2Display(e.target.value) }} value={website2Display} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_website2_link}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite2(e.target.value) }} value={website2} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_website3}</label>
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_website3_display}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite3Display(e.target.value) }} value={website3Display} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_website3_link}</label>
                                <input type="text" className="form-control" onChange={(e) => { setWebsite3(e.target.value) }} value={website3} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_email}</label>
                        <input type="text" className="form-control" onChange={(e) => { setEmail(e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_description}</label>
                        <textarea className="form-control" id="larpDescription" rows="3" onChange={(e) => { setDescription(e.target.value) }}></textarea>
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_alcohol_accepted}</label>
                        <input type="checkbox" className="cat-input space-small" checked={alcoholAccepted} onChange={(e) => { setAlcoholAccepted(!alcoholAccepted) }} />
                    </div>
                    <div className="form-group">
                        <label>{translation.larp_label_average_player_count}</label>
                        <input type="number" min={0} placeholder="0" className="form-control" onChange={(e) => { setAveragePlayerCount(e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <div className="hor-flex fill">
                            <div className="flex">
                                <label>{translation.larp_label_min_age}</label>
                                <input type="number" min={0} max={99} placeholder="0" className="form-control" onChange={(e) => { setMinAge(e.target.value) }} />
                            </div>
                            <div className="flex">
                                <label>{translation.larp_label_max_age}</label>
                                <input type="number" min={0} max={99} placeholder="99" className="form-control" onChange={(e) => { setMaxAge(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-dark space-medium" onClick={async () => { submit_larp() }}>{translation.larp_save}</button>
                </form>
            </div>
        </div>
    );
};

export default CreateLarp;
