import React from 'react';
import '../css/event.css';
import { BsXCircleFill } from 'react-icons/bs';
import useTranslation from './customHooks/translations';
import config from '../config';

const EventCard = ({ event, removeEvent, editEvent, closeEvent, displayActions, displaySmall, onCardClick }) => {
    let translation = useTranslation()
    let closedClass = event.closed ? "event-closed" : "";
    let displayClass = displaySmall ? "event-small" : "";
    return (
        <div className={"event-container space-medium pointer " + closedClass + displayClass} onClick={onCardClick}>
            <div className="event-image">
                {event.image_path &&
                    <img src={config.apiUrl + "/image-uploads/" + event.image_path} alt={event.name} />
                }
            </div>
            <div className="event-details">
                <h3 className="event-title">{event.name}</h3>
                {!displaySmall &&
                    <p className="event-description">{event.description}</p>
                }
                <div className="event-datetime">
                    <p><strong>{translation.event_label_start_date}:</strong> {event.start_date}  {event.start_time} </p>
                    {event.end_date &&
                        <p><strong>{translation.event_label_end_date}:</strong> {event.end_date}  {event.end_time}</p>
                    }
                </div>
            </div>
            {displayActions &&
                <div className="event-action">
                    <div>
                        <div className="btn btn-dark space-small " onClick={() => editEvent(event)}>
                            {translation.event_edit_button}
                        </div>
                        <BsXCircleFill className="space-small delete-button pointer" size={30} color="DarkRed" onClick={async () => { removeEvent(event.id) }} />
                    </div>
                    <div className="btn btn-dark space-small " onClick={() => closeEvent(event)}>
                        {event.closed ? translation.event_reopen_button : translation.event_end_button}
                    </div>
                </div>
            }
        </div>
    );
};

export default EventCard;
