import { useEffect, useState } from "react";
import useTranslation from "../../components/customHooks/translations";
import { useNavigate, useParams } from "react-router";
import { AddFeat, EditFeat, RemoveFeat, fetchFeats } from "../../api/featAPI";
import { fetchLarpData } from "../../api/larpAPI";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import FeatForm from "../../components/FeatForm";
import { BsEyeFill, BsEyeSlashFill, BsFillCartPlusFill, BsFlagFill, BsJournalPlus } from "react-icons/bs";
import { getSessionToken } from "../../utils/SessionTokenUtils";

export default function UpdateLarpFeat() {
    const translation = useTranslation();
    const navigate = useNavigate();
    const [larp, setLarp] = useState({})
    const [feats, setFeats] = useState([])
    const [selectedFeat, setselectedFeat] = useState(null)
    const { id } = useParams()
    const [filterText, setFilterText] = useState("")

    const [editFeatShow, setEditFeatShow] = useState(false);
    const handleEditFeatClose = () => setEditFeatShow(false);
    const handleEditFeatShow = () => setEditFeatShow(true);
    const [displayCreation, setDisplayCreation] = useState(false);

    async function getFeats() {
        const response = await fetchFeats(id)
        if (response.ok) {
            let feats_response = await response.json();
            setFeats(feats_response)
        }
    }

    async function getLarp() {
        const response = await fetchLarpData(id)
        if (response.ok) {
            let larp_response = await response.json();
            setLarp(larp_response)
        }
    }

    const addFeatButtonclick = async (name, description, requirements, limitations, maxSelect, isHidden, triggersAnAction, statMods, pointMods, costs, allowNewSelection, categorySelection) => {
        const response = await AddFeat(id, name, description, requirements, limitations, maxSelect, isHidden, triggersAnAction, statMods, pointMods, costs, allowNewSelection, categorySelection)
        if (response.ok) {
            getFeats()
        }
    }

    const saveFeatButtonclick = async (name, description, requirements, limitations, maxSelect, isHidden, triggersAnAction, statMods, pointMods, costs, allowNewSelection, categorySelection) => {
        handleEditFeatClose()
        const response = await EditFeat(selectedFeat.id, name, description, requirements, limitations, maxSelect, isHidden, triggersAnAction, statMods, pointMods, costs, allowNewSelection, categorySelection)
        if (response.ok) {
            getFeats()
        }
    }

    async function removeFeat(feat_id) {
        const response = await RemoveFeat(feat_id)
        if (response.ok) {
            getFeats()
        }
    }

    useEffect(() => {
        if (getSessionToken() === null) {
            navigate("/login");
        }
        getFeats()
        getLarp()
    }, []);

    let filteredFeats = feats;
    if (filterText !== "") {
        const search = filterText.toLowerCase()
        filteredFeats = feats.filter(
            x => x.name.toLowerCase().includes(search) ||
                x.costs.some(y => y.sub_cat_name.toLowerCase().includes(search)))
    }

    return (
        <>
            <div className="feat-title">
                <h1>{translation.feat_title}</h1>
                <span>{translation.feat_desc}</span>
            </div>
            <div className="page_content">
                <div className="restrained-section">
                    {displayCreation &&
                        <>
                            <FeatForm
                                larp={larp}
                                feat={selectedFeat}
                                confirmButtonText={translation.feat_add_button}
                                onConfirm={addFeatButtonclick} />
                            <button type="button" className="btn btn-dark space-medium" onClick={() => { setDisplayCreation(false); setselectedFeat(null) }}>
                                {translation.feat_close_creation_button}
                            </button>
                        </>
                    }
                    {!displayCreation &&
                        <button type="button" className="btn btn-dark space-medium" onClick={() => { setselectedFeat(null); setDisplayCreation(true) }}>
                            {translation.feat_create_button}
                        </button>
                    }
                </div>
                <div className="hor-flex align-left">
                    <label>{translation.feat_filter_label}:</label>
                    <input type="text" className="form-control space-small input-medium" value={filterText} onChange={(e) => { setFilterText(e.target.value) }} />
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{translation.feat_table_name}</th>
                            <th>{translation.feat_table_hidden}</th>
                            <th>{translation.feat_table_selec_max}</th>
                            <th>{translation.feat_table_cost}</th>
                            <th>{translation.feat_table_stat_mods}</th>
                            <th>{translation.feat_table_point_mods}</th>
                            <th>{translation.feat_table_actions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredFeats.map((feat) =>
                            <tr key={feat.id}>
                                <td>{feat.trigger_an_action && <BsFlagFill size={25} />} {feat.name} {feat.allowNewSelection && <BsFillCartPlusFill size={25} />}</td>
                                <td>{feat.isHidden &&
                                    <BsEyeSlashFill color="DarkRed" size={25} />
                                }
                                    {!feat.isHidden &&
                                        <BsEyeFill size={25} />
                                    }
                                </td>
                                <td>{feat.max_select > 1 && feat.max_select}</td>
                                <td>
                                    {feat.costs.map((cost) =>
                                        <div key={cost.id}>
                                            <span>
                                                {cost.acquired_select &&
                                                    <>
                                                        <BsJournalPlus title={translation.feat_table_acquire_selec} size={20} /> -
                                                    </>
                                                }
                                                {cost.category_name}-{cost.sub_cat_name}

                                                {!cost.acquired_select &&
                                                    <>
                                                        : {cost.amount} {cost.point_alias}
                                                    </>
                                                }
                                            </span>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {feat.stat_mods.map((stat_mod) =>
                                        <div key={stat_mod.id}>
                                            <span>{stat_mod.amount} {stat_mod.stat_name}</span>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {feat.point_mods.map((point_mod) =>
                                        <div key={point_mod.id}>
                                            <span>{point_mod.amount} {point_mod.point_name}</span>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    <div className="display-flex-spaced">
                                        <button type="button" className="btn table-btn btn-secondary" onClick={() => { setselectedFeat(feat); handleEditFeatShow() }}>
                                            {translation.feat_modify_button}
                                        </button>
                                        <button type="button" className="btn table-btn btn-secondary" onClick={() => { setselectedFeat(feat); setDisplayCreation(true) }}>
                                            {translation.feat_template_button}
                                        </button>
                                        <button type="button" className="btn table-btn btn-danger" onClick={async () => { removeFeat(feat.id) }}>{translation.feat_remove_button}</button>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <Modal className="edit-feat-modal" show={editFeatShow} onHide={handleEditFeatClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translation.feat_modal_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FeatForm
                            larp={larp}
                            feat={selectedFeat}
                            confirmButtonText={translation.feat_modal_save}
                            onConfirm={saveFeatButtonclick} />
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};
