
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput } from 'mdb-react-ui-kit';
import React from 'react';
import { useState } from 'react';
import useTranslation from "../components/customHooks/translations";
import ReCAPTCHA from "react-google-recaptcha";
import { signUpUser } from '../api/userAPI';
import config from "../config"
import PasswordInput from './PasswordInput';

function checkIfEmailInString(text) {
  var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(text);
}

export function checkIfPasswordStrong(text) {
  // Minimum eight characters, at least one letter, one number and one special character
  var re = /(^(?=.*[A-Za-z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|\:;"'<,>.?\/])[A-Za-z\d~`!@#$%^&*()_\-+={[}\]|\:;"'<,>.?\/]{8,}$)/;
  return re.test(text);
}

function SignUpForm() {

  const [message, setMessage] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [recaptcha, setRecaptcha] = useState();

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  function formValidation() {
    let isValid = true;
    if (firstNameValue.trim() === "") {
      setFirstNameError(true);
      isValid = false;
    } else {
      setFirstNameError(false);
    }
    if (lastNameValue.trim() === "") {
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameError(false);
    }
    if (emailValue.trim() === "" || !checkIfEmailInString(emailValue)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    if (passwordValue.trim() === "") {
      setPasswordError(true);
      isValid = false;
    } else {
      if (!checkIfPasswordStrong(passwordValue)) {
        setPasswordError(true);
        alert(translation.signup_password_requirement)
        isValid = false;
      } else {
        setPasswordError(false);
      }
    }
    if (!recaptcha)
      isValid = false;

    if (passwordValue !== confirmPasswordValue) {
      setConfirmPasswordError(true)
      isValid = false;
    } else
      setConfirmPasswordError(false)

    return isValid;
  }

  async function submit_subscription() {
    if (formValidation()) {
      const response = await signUpUser(firstNameValue, lastNameValue, emailValue, passwordValue, translation.language)
      if (response.status === 403) {
        setMessage(translation.signup_message_existing_user)
      } else if (response.ok) {
        setConfirmationTitle(translation.signup_message_conf_title)
        setConfirmationMessage(translation.signup_message_conf_description)
      } else {
        setMessage(translation.signup_message_unknown)
      }
    }
  }

  const translation = useTranslation();
  let firstNameLabelClass = firstNameError ? 'text-red' : 'text-white'
  let lastNameLabelClass = lastNameError ? 'text-red' : 'text-white'
  let emailNameLabelClass = emailError ? 'text-red' : 'text-white'
  let passwordNameLabelClass = passwordError ? 'text-red' : 'text-white'
  let confirmPasswordNameLabelClass = confirmPasswordError ? 'text-red' : 'text-white'
  if (confirmationMessage !== "") {
    return (<>
      <h3 className="login-message">{confirmationTitle}</h3>
      <p className="login-message">{confirmationMessage}</p>
    </>)
  } else {
    return (
      <MDBContainer fluid className='signup-modal'>
        <p className="login-message">{message}</p>
        <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
          <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
            <h1 className="fw-bold mb-4 text-uppercase fredoka">{translation.signup_title}</h1>
            <MDBRow>
              <MDBCol>
                <MDBInput wrapperClass='mb-4' labelClass={firstNameLabelClass} label={translation.signup_first_name} type='text' onChange={(e) => { setFirstNameValue(e.target.value) }} />
              </MDBCol>
              <MDBCol>
                <MDBInput wrapperClass='mb-4' labelClass={lastNameLabelClass} label={translation.signup_last_name} type='text' onChange={(e) => { setLastNameValue(e.target.value) }} />
              </MDBCol>
            </MDBRow>
            <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass={emailNameLabelClass} label={translation.signup_email} type='email' size="lg" onChange={(e) => { setEmailValue(e.target.value) }} />
            <label>{translation.signup_password_requirement}</label>
            <PasswordInput wrapperClass='mb-4 mx-5 w-100' passwordLabelClass={passwordNameLabelClass} label={translation.signup_password} onChange={(value) => { setPasswordValue(value) }} />
            <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass={confirmPasswordNameLabelClass} label={translation.signup_confirm_password} type='password' size="lg" onChange={(e) => { setConfirmPasswordValue(e.target.value) }} />
            <ReCAPTCHA className="recaptcha" sitekey={config.recaptChaSiteKey} onChange={setRecaptcha} />
            <MDBBtn outline className='px-5' size='lg' style={{ color: 'white' }} onClick={async () => { submit_subscription() }}>
              {translation.signup_title}
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>

    );
  }
}
export default SignUpForm;
