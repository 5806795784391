const featureFlags = {
    larpCreationFeature: { name: "featureflags_larpcreation", value: true },
    characterCreationFeature: { name: "featureflags_characterCreationFeature", value: false },
    categoryMultipleSelectFeature: { name: "featureflags_categoryMultipleSelectFeature", value: false },
    complexeLarpFeature: { name: "featureflags_complexeLarpFeature", value: false },
    registerLarp: { name: "featureflags_registerLarp", value: true },
    articlesWithTimeline: { name: "featureflags_time_line_articles", value: false }
    // Add more feature flags as needed
};

export default featureFlags;
